import * as React from 'react';
import Select, { Option } from 'react-select';

import { actions } from '../../ducks/MessageForm';
import GlyphPopover from '../../lib/components/GlyphPopover';
import { getDispatch } from '../../lib/dispatchExporter';
import I18n from '../../lib/i18n';
import notify from '../../lib/notify';
import Message from '../../models/Message';
import TopicList from '../../models/TopicList';
import TopicDragContainer from './DragComponents/TopicDragContainer';

interface IProps {
  $$relatedTopics: TopicList;
  maximumRelatedTopicsLength: number;
  hideRow?: boolean;
  $$message: Message;
}
export default class RelatedTopicsForm extends React.PureComponent<IProps> {
  private dispatch = getDispatch();

  public updateSelectedTopics = (selectItem: Option<string>) => {
    const { $$relatedTopics, maximumRelatedTopicsLength } = this.props;

    if ($$relatedTopics.filterSelected().count() >= maximumRelatedTopicsLength) {
      notify({
        title: `${I18n.t('activerecord.attributes.message.related_topics')}:`,
        message: I18n.t('activerecord.errors.message.related_topics.too_many_topics', { maximum_size: maximumRelatedTopicsLength }),
        type: 'danger',
      });
      return;
    }
    if (selectItem.value) {
      this.dispatch(actions.selectTopic(selectItem.value));
    }
  }

  public renderError() {
    const { $$message } = this.props;

    if ($$message.errors.related_topics) {
      return(
        <div className="col-md-10 has-error">
          <span className="help-block">{$$message.errors.related_topics.join('\n')}</span>
        </div>
      );
    }

    return null;
  }

  public render() {
    const { $$relatedTopics, hideRow, $$message } = this.props;
    const countries = $$message.countries.map((e: any) => e.iso2).toArray();

    // Selectを並べるとmenuがSelectの下に回りこむのでzIndexを指定します。
    // refs: https://github.com/JedWatson/react-select/issues/1085
    return (
      <div>
        <label htmlFor="related-channels" className="control-label">
          {I18n.t('activerecord.attributes.message.related_topics')}
        </label>
        <GlyphPopover
          title={I18n.t('activerecord.attributes.message.related_topics')}
          body={I18n.t('messages.tooltips.related_topics')}
        />
        <br />
        <div className="row">
          <div className="col-md-10" id="select-related-topics">
            <Select
              name={'message[related_topics][]'}
              options={$$relatedTopics.getSelectOptions(countries, $$message.platforms)}
              onChange={this.updateSelectedTopics}
              menuContainerStyle={{ position: 'relative' }}
            />
          </div>
          { this.renderError() }
          <div style={{ marginTop: '10px' }} className="col-md-12">
            {hideRow ? null :
              <TopicDragContainer
                $$topics={$$relatedTopics}
                parentFormName="message[related_topics][]"
              />
            }
          </div>
        </div>
      </div>
    );
  }
}
