import * as React from 'react';
import { connect } from 'react-redux';
import Message from '../../../models/Message';
import Product from '../../../models/Product';
import TopicList from '../../../models/TopicList';
import { IRootState } from '../../../store/MessageForm';
import RelatedTopicsForm from '../RelatedTopicsForm';

function mapStateToProps(state: IRootState) {
  return {
    maximumRelatedTopicsLength: state.$$formStore.message.validators.related_topics.maximum,
    relatedTopics: state.$$formStore.relatedTopics.selectedIds,
    $$message: state.$$formStore.message,
  };
}
interface IProps {
  product: Product;
}
type Props = IProps & ReturnType<typeof mapStateToProps>;
class RelatedChannelsSupport extends React.PureComponent<Props> {
  public render () {
    const { $$message, maximumRelatedTopicsLength, relatedTopics, product } = this.props;
    if (product.related_channels === undefined || product.related_channels.length === 0) { return null; }
    if (!($$message.hasNxNewsLevel() && Message.LANUNCH_NEWS_VERSION < $$message.semantics_version)) { return null; }

    const relatedChannels = product.related_channels.filter(channel => !relatedTopics.includes(channel.npns_topic_id));
    const $$relatedTopics = new TopicList(relatedChannels, relatedTopics.toArray());
    const countries = $$message.countries.map((e: any) => e.iso2).toArray();
    if ($$relatedTopics.getSelectOptions(countries, $$message.platforms).length === 0) { return null; }

    return (
      <div style={{ marginLeft: '15px' }}>
        <RelatedTopicsForm
          {...{ $$message, maximumRelatedTopicsLength, $$relatedTopics, hideRow: true }}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(RelatedChannelsSupport);
