import { List, OrderedMap, Record } from 'immutable';
import { intersection } from 'lodash';

import applySortAction from '../lib/applySortActionList';
import Topic from './Topic';

const defaultValue: {
  items: OrderedMap<string, Topic>, // index はnpns_topic_id
  selectedIds: List<string>,
} = {
  items: OrderedMap(),
  selectedIds: List(),
};

const TopicListRecord = Record(defaultValue);

export default class TopicList extends TopicListRecord {
  constructor(topics: any[] = [], ids: string[] = []) {
    const items = OrderedMap(topics.map((topic: any): [string, Topic] => [topic.npns_topic_id, new Topic(topic)]));
    const selectedIds = List(ids);
    super({
      items,
      selectedIds,
    });
  }

  public sortByUser(oldIndex: number, newIndex: number): TopicList {
    return this.update('selectedIds', list => applySortAction(list, oldIndex, newIndex));
  }

  public select(id: string): TopicList {
    return this.update('selectedIds', list => list.push(id));
  }

  public unselect(id: string): TopicList {
    const index = this.selectedIds.keyOf(id);
    if (index === undefined) { throw Error('TopicList: invalid unselect'); }
    return this.update('selectedIds', list => list.delete(index));
  }
  public getSelectedMaxLength() {
    return this.filterSelected().map(topic => topic.name.length).max();
  }

  public filterSelected() {
    if (this.items.size === 0) { return List(); }
    return this.selectedIds.map(id => this.items.get(id));
  }

  public filterNotSelected() {
    return this.items.filter(topic => !this.selectedIds.includes(topic.npns_topic_id));
  }

  public filterSelectedWithOrder() {
    if (this.items.size === 0) { return List(); }
    return this.selectedIds.map(id => Object({ index: this.selectedIds.keyOf(id), topic: this.items.get(id) }));
  }

  public getSelectOptions(countries: string[], platforms: string[]) {
    return this.filterNotSelected()
      .filter(topic => intersection(topic.countries, countries).length !== 0).valueSeq()
      .filter(topic => this.hasValidRelatedPlatform(topic.platforms, platforms)).valueSeq()
      .map(topic => topic.toSelectOption()).toJS();
  }

  hasValidRelatedPlatform(topic_platforms: string[], message_platforms: string[]): boolean {
    // ニュースの配信先の指定が無い場合、全ての関連チャンネルが使用不可
    if (message_platforms.length === 0) return false;

    if (message_platforms.length === 2) {
      // 全ての宛先に配信するニュースの場合、全ての宛先に配信出来る関連チャンネルのみ使用可
      return topic_platforms.length === 2;
    } else if (message_platforms[0] === 'nx') {
      // ニュースがNX配信の場合、全ての宛先に配信出来る関連チャンネル、もしくは、NXに配信できる関連チャンネルが使用可
      return topic_platforms.length === 2 || topic_platforms[0] === 'nx';
    } else if (message_platforms[0] !== 'nx') {
      // ニュースがNX配信でない場合、全ての宛先に配信出来る関連チャンネル、もしくは、NX以外に配信できる関連チャンネルが使用可
      return topic_platforms.length === 2 || topic_platforms[0] !== 'nx';
    }
    return false;
  }
}
