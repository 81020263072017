import { List, Map, OrderedMap, Record } from 'immutable';
import { intersection } from 'lodash';

import applySortAction from '../lib/applySortActionList';
import lengthValidator from '../lib/lengthValidator';
import notify from '../lib/notify';
import { IMessageFormAppProps } from '../startup/MessageFormApp';
import FeaturedProductDetail, { IFeaturedProductDetailRecord } from './FeaturedProductDetail';
import MainContent from './MainContent';
import Message, { ICountry } from './Message';
import MessageDetail from './MessageDetail';
import MoreShopQuery from './MoreShopQuery';
import Product from './Product';
import ProductList from './ProductList';
import RatingList from './RatingList';
import SuggestVideoList from './SuggestVideoList';
import TopicList from './TopicList';

export type Descriptor = Array<{ value: number; label: string; }>;

const defaultValue: {
  message: Message,
  messageDetails: List<MessageDetail>,
  relatedProducts: ProductList,
  relatedTopics: TopicList,
  featuredProductIds: List<string>,
  featuredProductDetails: Map<string, FeaturedProductDetail>,
  defaultActiveLanguage: string,
  selectedMoreType: string,
  selectableCountries: List<ICountry>,
  // availableLanguagesはこのチャンネルで編集可能な言語の一覧
  // editableLanguagesはそのニュースの編集可能な言語の一覧
  // availableLanguagesはformをrender時点で不変な値ですが、
  // editableLanguagesは宛先の国に応じて動的に変化する値です。
  availableLanguages: List<string>,
  editableLanguages: List<string>,
  byCountryGroup: boolean,
  countriesFetched: boolean,
  canSubmit: boolean,
  isFetching: boolean,
  fetchError?: string,
  subscribeTitles: List<{ label: string, value: string }>,
  ratingList: RatingList,
  contentsDescriptors: Map<string, Descriptor>,
  interactiveElements: Map<string, Descriptor>,
  channelPath: string,
  suggestVideoList: SuggestVideoList,
  timezone: string,
  isChina: boolean,
  copyingDetails: boolean,
  selectablePlatforms: string[],
  canReadPlatforms: boolean,
  i18nPlatforms: { [key: string]: string },
  isDiscovery: boolean,
  nemoEnv: string,
} = {
  message: new Message(),
  messageDetails: List(),
  relatedProducts: new ProductList(),
  relatedTopics: new TopicList([], []),
  featuredProductIds: List(),
  featuredProductDetails: Map(),
  defaultActiveLanguage: '',
  selectedMoreType: '',
  selectableCountries: List(),
  availableLanguages: List(),
  editableLanguages: List(),
  byCountryGroup: false,
  countriesFetched: false,
  canSubmit: false,
  isFetching: false,
  fetchError: undefined,
  subscribeTitles: List(),
  ratingList: new RatingList(),
  contentsDescriptors: Map(),
  interactiveElements: Map(),
  channelPath: '',
  suggestVideoList: new SuggestVideoList(),
  timezone: '',
  isChina: false,
  copyingDetails: false,
  selectablePlatforms: undefined,
  canReadPlatforms: undefined,
  i18nPlatforms: {},
  isDiscovery: false,
  nemoEnv: '',
};

const StateRecord = Record(defaultValue);

export default class MessageFormState extends StateRecord {
  constructor(props?: IMessageFormAppProps) {
    if (props === undefined) {
      super();
      return;
    }
    const {
      message,
      messageDetails,
      defaultActiveLanguage,
      byCountryGroup,
      featuredProducts,
      topics,
      channelPath,
      timezone,
      isChina,
      copyingDetails,
      selectablePlatforms,
      canReadPlatforms,
      i18nPlatforms,
      isDiscovery,
      nemoEnv,
    } = props;
    // storeにはConstants::LANGUAGES::NXの順番で格納するようにする
    const newMessageDetails = (window as any).Constants.languages.nx.map(
      (language: any): MessageDetail => new MessageDetail(messageDetails.filter((md: any) => md.language === language)[0])
    );
    const featuredProductDetails = Map<string, FeaturedProductDetail>(
      featuredProducts.details.map((detail: any): [string, FeaturedProductDetail] => [detail.ns_uid, new FeaturedProductDetail(detail)])
    );
    const relatedTopics = new TopicList(topics, message.related_topics);
    const ratingList = new RatingList(props.ratingSystems, props.ratings, props.channelRatings);
    const contentsDescriptors = Map(props.contentsDescriptors);
    const interactiveElements = Map(props.interactiveElements);
    const subscribeTitles = List(props.subscribeTitles);
    const selectableCountries = List(props.selectableCountries);
    const availableLanguages = List(props.availableLanguages);
    super({
      message: new Message(message),
      messageDetails: List(newMessageDetails),
      featuredProductIds: List(featuredProducts.selectedIds),
      canSubmit: true,
      featuredProductDetails,
      relatedTopics,
      byCountryGroup,
      availableLanguages,
      selectableCountries,
      defaultActiveLanguage,
      ratingList,
      contentsDescriptors,
      interactiveElements,
      subscribeTitles,
      channelPath,
      timezone,
      isChina,
      copyingDetails,
      selectablePlatforms,
      canReadPlatforms,
      i18nPlatforms,
      isDiscovery,
      nemoEnv,
    });
  }
  // mutations
  public setCountries(countries: ICountry[]): MessageFormState {
    return (
      this.set(
        'isFetching', false
      ).set(
        'selectableCountries', List(countries)
      ).setIn(
        ['message', 'countries'], List(countries)
      )
    );
  }
  public setError(error: string): MessageFormState {
    return this.set('isFetching', false).set('fetchError', error);
  }
  // FIXME: どこで何が突っ込まれているかわからないためとりあえずany
  public updateLazy({ key, value } : { key: any, value: any }): MessageFormState {
    return this.setIn(key, value);
  }
  public validate({ key }: { key: any }): MessageFormState {
    const model = [...key];
    const target = model.pop();
    return this.setIn(model, this.getIn(model).valid(target));
  }
  // FIXME: 複雑すぎるのでサーバに寄せたい。国グループ変えたときはサーバから新しい状態が降ってくるのでどうか。
  public updateEditableLanguages(): MessageFormState {
    // uiの挙動が不自然になるのでeditableLanguageはConstants.languages.nxの
    // 順序にしたがって持つようにします。
    // 順序を固定するために毎回 Constants.languages.nxとの積集合を計算してます。
    const editableLanguages: string[] = intersection(
      Array.from(this.availableLanguages),
      Array.from(new Set(
        this.selectableCountries.filter(
          (e: any) => (this.countryISOs().includes(e.iso2))
        ).reduce(
          (res: any, cur: any) => res.concat(cur.languages),
          []
        )
      ))
    );
    const storedDefaultLanguage = this.message.default_language;
    // storedDefaultLanguageの初期値はnullで、あえて指定しないを選んだ場合は''になります。
    // defaultLangageは以下のように決まります。
    // 1. 初期値はnull
    // 2. 宛先の国が決まったら、editableLanguageの最初の言語を初期値としてセットする
    // 3. 宛先の国、もしくは国グループが選択し直された時、更新されたeditableLanguagesに同じ言語がある場合と、
    //    指定がない場合は(''がstoreされている場合は)そのまま。
    //    そうでなければ、新しく決まったeditableLanguagesの先頭の言語を選択
    const defaultLanguage = storedDefaultLanguage === '' || editableLanguages.find(language => language === storedDefaultLanguage) ?
      storedDefaultLanguage :
      editableLanguages[0];
    return this.merge({
      defaultActiveLanguage: editableLanguages[0],
      editableLanguages: List(editableLanguages),
      featuredProductDetails: this.featuredProductDetails.map(
        (detail): FeaturedProductDetail => detail.createNewComments(editableLanguages)
      ),
    }).setIn(['message', 'default_language'], defaultLanguage);
  }
  public setCanSubmit(): MessageFormState {
    const canSubmit =
      this.countryISOs().isEmpty() ?
      false :
      this.countryISOs().toSet().isSubset(this.selectableCountries.map((e: any) => e.iso2).toSet());
    return this.set('canSubmit', canSubmit);
  }
  // FIXME: productは非同期で取らない方針に
  // productのマスタとidの選択状態は分離する
  public setProducts(products: any[]): MessageFormState {
    const newProducts = OrderedMap(products.map((product): [string, Product] => [product.ns_uid, new Product(product)]));
    return this
      .update('relatedProducts', relatedProducts => relatedProducts.merge({ items: newProducts, isFetching: false }))
      .set('featuredProductIds', this.featuredProductIds)
      .setIn(['message', 'related_products'], this.message.related_products);
  }
  public copyRating(payload: { base: string, target: string }): MessageFormState {
    const base = this.messageDetails.find(md => md.language === payload.base);
    const target = this.messageDetails.find(md => md.language === payload.target);
    if (base === undefined) { throw Error('MessageFormState#copyRating: base undefined'); }
    if (target === undefined) { throw Error('MessageFormState#copyRating: target undefined'); }
    return this.setIn(
      ['messageDetails', this.messageDetails.indexOf(target)],
      target
        .set('ratings', base.ratings)
        .set('contents_descriptors', base.contents_descriptors)
        .set('interactive_elements', base.interactive_elements),
    );
  }

  public setDefaultExpirationTime(): MessageFormState {
    return this.update('message', message => message.setDefaultExpirationTime());
  }
  public setDefaultPickupLimit(): MessageFormState {
    return this.update('message', message => message.setDefaultPickupLimit());
  }
  public clearPickupLimit(): MessageFormState {
    return this.setIn(['message', 'pickup_limit'], null);
  }
  public clearEssentialPickupLimit(): MessageFormState {
    return this
      .setIn(['message', 'essential_pickup_limit'], null)
      .setIn(['message', 'essential_priority'], null)
      .setIn(['message', 'decoration_type'], null);
  }
  public updateProductDetail({ nsUid, key, value }: { nsUid: string, key: keyof IFeaturedProductDetailRecord, value: ValueOf<IFeaturedProductDetailRecord> }): MessageFormState {
    return this.updateIn(['featuredProductDetails', nsUid], (detail: FeaturedProductDetail) => detail.updateDetail(key, value));
  }
  public toggleProductDiscount(nsUid: string): MessageFormState {
    return this.updateIn(['featuredProductDetails', nsUid], detail => detail.toggleDiscount());
  }
  public updateComment({ nsUid, key, language, value }: { nsUid: string, key: string, language: string, value: string}): MessageFormState {
    return this.updateIn(['featuredProductDetails', nsUid], detail => detail.updateComment(key, language, value));
  }
  public selectRelatedProduct(id: string): MessageFormState {
    return this.update('message', message => message.selectRelatedProduct(id));
  }
  public unselectRelatedProduct(id: string): MessageFormState {
    return this.update('message', message => message.unselectRelatedProduct(id));
  }
  public sortRelatedProducts({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }): MessageFormState {
    return this.update('message', message => message.sortRelatedProducts(oldIndex, newIndex));
  }
  public selectFeaturedProduct(nsUid: string): MessageFormState {
    const newIds = this.featuredProductIds.push(nsUid);
    if (this.featuredProductDetails.has(nsUid)) {
      return this.set('featuredProductIds', newIds);
    }
    const featuredDetail = new FeaturedProductDetail({
      ns_uid: nsUid,
      name: this.relatedProducts.getName(nsUid),
    }).createNewComments(this.editableLanguages);
    return this.merge({
      featuredProductIds: newIds,
      featuredProductDetails: this.featuredProductDetails.set(nsUid, featuredDetail),
    });
  }
  public unselectFeaturedProduct(nsUid: string): MessageFormState {
    const index = this.featuredProductIds.keyOf(nsUid);
    if (index === undefined) { throw Error('MessageFormState#unselectFeaturedProduct: index undefined'); }
    return this.update('featuredProductIds', list => list.delete(index));
  }
  public sortFeaturedProducts({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }): MessageFormState {
    return this.update('featuredProductIds', list => applySortAction(list, oldIndex, newIndex));
  }
  public selectShopList({ index, productId }: { index: number, productId: string }): MessageFormState {
    return this.updateIn(['messageDetails', index, 'more_shop_query'], shopQ => shopQ.select(productId));
  }
  public unselectShopList({ index, productId }: { index: number, productId: string }): MessageFormState {
    return this.updateIn(['messageDetails', index, 'more_shop_query'], shopQ => shopQ.unselect(productId));
  }
  public sortShopLists({ index, oldIndex, newIndex }: { index: number, oldIndex: number, newIndex: number }): MessageFormState {
    return this.updateIn(['messageDetails', index, 'more_shop_query'], shopQ => shopQ.sort(oldIndex, newIndex));
  }
  public switchMovies({ key, oldIndex, newIndex }: { key: any, oldIndex: number, newIndex: number }): MessageFormState {
    return this.updateIn(key, movieList => movieList.switch(oldIndex, newIndex));
  }
  public addMovie({ key, movieUrl, isQualityFirst }: { key: any, movieUrl: string, isQualityFirst: boolean }): MessageFormState {
    return this.updateIn(key, movieList => movieList.add(movieUrl, this.nemoEnv, isQualityFirst));
  }
  public updateMovie({ prefix, index, key, value }: { prefix: any, index: number, key: string, value: string | boolean}): MessageFormState {
    return this.updateIn(prefix, movieList => movieList.updateByIndex(index, key, value));
  }
  public deleteMovie({ key, index }: { key: any, index: number }): MessageFormState {
    return this.updateIn(key, movieList => movieList.delete(index));
  }
  public selectTopic(id: string): MessageFormState {
    return this.update('relatedTopics', topics => topics.select(id));
  }
  public unselectTopic(id: string): MessageFormState {
    return this.update('relatedTopics', topics => topics.unselect(id));
  }
  public sortTopics({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }): MessageFormState {
    return this.update('relatedTopics', topics => topics.sortByUser(oldIndex, newIndex));
  }
  // FIXME: これはSagaで受け取るべきでは？
  public notifyAction({ title, message, type = 'danger' }: { title: string, message: string, type?: string }) {
    notify({ title, message, type });
    return this;
  }

  // read methods
  public countryISOs(): List<string> {
    return this.message.countries.map((e: any) => e.iso2);
  }
  public createRelatedProductList(): ProductList {
    return this.relatedProducts.set('selectedIds', this.message.related_products);
  }
  public createFeaturedProductList(): ProductList {
    return this.relatedProducts.set('selectedIds', this.featuredProductIds).update(
      'items', items => items.filter(product => {
        return (product instanceof Product) ? product.usableForFeatured(this.editableLanguages) : false;
      })
    );
  }
  // FIXME: MessageDetail Containerにstateを渡して、言語に応じてProductListを作るほうが効率的だが、大工事が必要
  public createShopProductLists(): Map<string, ProductList> {
    return Map(
      this.messageDetails.map((md): [string, ProductList] =>
        [md.language, this.relatedProducts.set('selectedIds', md.more_shop_query.list_ids)]
      )
    );
  }
  public getFeaturedProducts(): List<FeaturedProductDetail | undefined> {
    const sortedDisplayProducts = this.featuredProductIds.map(nsUid => this.featuredProductDetails.get(nsUid));
    const destroyProducts = this.featuredProductDetails.filter(
      detail => !this.featuredProductIds.includes(detail.ns_uid)
    ).toList().map(detail => detail.set('destroy', true));
    return sortedDisplayProducts.concat(destroyProducts);
  }

  public setRelatedChannels(payload: { nsUid: string, relatedChannels: any }) {
    return this.setIn(['relatedProducts', 'items', payload.nsUid, 'related_channels'], payload.relatedChannels);
  }

  public setMoreShopText(payload: { index: number, defaultText: string }) {
    return this.updateIn(['messageDetails', payload.index], messageDetail => messageDetail.setMoreShopText(payload.defaultText));
  }
  public setMoreApplicationText(index: number) {
    return this.updateIn(['messageDetails', index], messageDetail => messageDetail.setMoreApplicationText());
  }

  public setMoreNsoText(payload: { index: number, defaultText: string }) {
    return this.updateIn(['messageDetails', payload.index], messageDetail => messageDetail.setMoreNsoText(payload.defaultText));
  }

  /*
   * main_contentsの追加
   * 編集中の新規追加の場合は、MainContentのオブジェクトを追加します。
   * 編集中に削除したMainContentオブジェクトはdestroyフラグが立っているだけで、
   * 実体としては残っているので、それがある場合は、destroyフラグを落とすだけにします。
   */
  public addNewMainContent(payload: { index: number, newMainContent: any, destroyedContentsIndex: number }) {
    const { index, newMainContent, destroyedContentsIndex } = payload;

    if (destroyedContentsIndex === -1) {
      return this.updateIn(
        ['messageDetails', index, 'main_contents_attributes'],
        mainContents => mainContents.push(new MainContent(newMainContent))
      );
    } else {
      return this.updateIn(
        ['messageDetails', index, 'main_contents_attributes', destroyedContentsIndex],
        mainContent => mainContent.recover()
      );
    }
  }

  /*
   * main_contentsの削除
   * MainContentオブジェクトのdestroyフラグを立てるだけです。
   * destroyフラグはform側でnested_formの_destroyパラメータに反映されます。
   */
  public removeMainContent(index: number) {
    const mainContents: List<MainContent> = this.getIn(['messageDetails', index, 'main_contents_attributes']);
    const destroyedContentsIndex = mainContents.findIndex((e) => e._destroy);

    if (destroyedContentsIndex === -1) {
      return this.updateIn(
        ['messageDetails', index, 'main_contents_attributes', mainContents.size - 1],
        mainContent => mainContent.destroy()
      );
    } else {
      return this.updateIn(
        ['messageDetails', index, 'main_contents_attributes', destroyedContentsIndex - 1],
        mainContent => mainContent.destroy()
      );
    }
  }

  public validateMainContentsBody(index: number) {
    const mainContents: List<MainContent> = this.getIn(['messageDetails', index, 'main_contents_attributes']);
    const bodyLength = mainContents.map((e) => e.body ? e.body.length : 0).reduce((acc: number, e) => (acc + e));

    const newMainContents = mainContents.map((mainContent) => {
      return lengthValidator<MainContent>('body', mainContent, bodyLength, mainContent.validators.body);
    });
    return this.setIn(['messageDetails', index, 'main_contents_attributes'], newMainContents);
  }

  public checkAllCountries() {
    return this.setIn(
      ['message', 'countries'],
      this.get('selectableCountries', []).map((e: any) => ({id: e.id, iso2: e.iso2}))
    );
  }

  public uncheckAllCountries() {
    return this.setIn(['message', 'countries'], List([]));
  }

  public updateProductsForChangeCountries(countries: string[]) {
    const { message, relatedProducts, featuredProductIds } = this;
    const nextRelatedProducts = message.related_products
      .filter(nsUid => relatedProducts.validProduct(relatedProducts.getItem(nsUid), countries));
    const nextFeaturedProductIds = featuredProductIds
      .filter(nsUid => relatedProducts.validProduct(relatedProducts.getItem(nsUid), countries));
    return this
      .setIn(['message', 'related_products'], nextRelatedProducts)
      .set('featuredProductIds', nextFeaturedProductIds);
  }

  public copyFeaturedProductsToShopLinkList({ index, nsUids }: { index: number, nsUids: List<string> }) {
    return this.updateIn(
      ['messageDetails', index, 'more_shop_query'],
      (moreShopQuery: MoreShopQuery) => moreShopQuery.replace(nsUids)
    );
  }
}
