import React from 'react';
import { connect } from 'react-redux';

import { IRootState } from '../../ducks/Subscribe';
import I18n from '../../lib/i18n';

import Select, { Option } from 'react-select';
import GlyphPopover from '../../lib/components/GlyphPopover';

function mapStateToProps(state: IRootState) {
  return {
    titles: state.$$subscribeStore.titles,
    newsTopics: state.$$subscribeStore.newsTopics,
    selectedTitleId: state.$$subscribeStore.selectedTitleId,
    selectedNewsTopicId: state.$$subscribeStore.selectedNewsTopicId,
    canSubmit: state.$$subscribeStore.canSubmit,
  };
}

interface IProps {
  errorMessage: string[];
}

type Props = IProps & ReturnType<typeof mapStateToProps>

interface IState {
  selectedTitleId: number;
  selectedNewsTopicId: number;
  canSubmit: boolean;
}

class Subscribes extends React.PureComponent<Props, IState> {

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedTitleId: props.selectedTitleId,
      selectedNewsTopicId: props.selectedNewsTopicId,
      canSubmit: props.canSubmit
    };
  }

  public handleChangeTitle = (e: Option<string>) => {
    const targetTitle = parseInt(e.value);
    this.setState({ selectedTitleId: targetTitle });
  }

  public handleChangeNewsTopic = (e: Option<string>) => {
    this.setState({ selectedNewsTopicId: parseInt(e.value) });
  }

  public applicationSelector() {
    const { titles } = this.props;
    const { selectedTitleId } = this.state;
    const titleOptions = Object.keys(titles)
      .map<[string, string]>(e => [(`[${titles[e].initial_code}] ${titles[e].application_id} ${titles[e].name}`) , titles[e].id])
      .map(this.toSelectOption);
    return (
      <div>
        <label htmlFor="subscribe-titles" className="control-label">
          <span title="required" className="required_mark">{I18n.t('simple_form.required.mark')}</span>
          {I18n.t('activerecord.attributes.title.name')}
        </label>
        <GlyphPopover
          title={I18n.t('activerecord.attributes.title.name')}
          body={I18n.t('titles.tooltips.subscribes.title')}
        />
        <div className="row">
          <div className="col-md-10" id="select-subscribe-titles">
            <Select
              name="subscribe[title_id]"
              value={selectedTitleId}
              options={titleOptions}
              onChange={this.handleChangeTitle}
              clearable={false}
            />
            <br />
          </div>
        </div>
      </div>
    );
  }

  public npnsTopicIdSelector() {
    const { newsTopics } = this.props;

    const newsTopicsOptions =
      Object.keys(newsTopics)
        .map<[string, string]>(e => [(`[${newsTopics[e].npns_topic_id}] ${newsTopics[e].general_news_name}`), newsTopics[e].id])
        .map(this.toSelectOption);
    return (
      <div>
        <label htmlFor="subscribe-topics" className="control-label">
          <span title="required" className="required_mark">{I18n.t('simple_form.required.mark')}</span>
          {I18n.t('activerecord.attributes.news_topic.npns_topic_id')}
        </label>
        <GlyphPopover
          title={I18n.t('activerecord.attributes.news_topic.npns_topic_id')}
          body={I18n.t('news_topics.tooltips.npns_topic_id')}
        />
        <div className="row">
          <div className="col-md-10" id="select-subscribe-topics">
            <Select
              name="subscribe[news_topic_id]"
              value={this.state.selectedNewsTopicId}
              options={newsTopicsOptions}
              onChange={this.handleChangeNewsTopic}
              clearable={false}
            />
          </div>
          {this.renderErrorMessage()}
        </div>
      </div>
    );
  }

  public render() {
    const { titles, newsTopics } = this.props;
    return (
      <div>
        {this.applicationSelector()}
        {this.npnsTopicIdSelector()}
        <br />
        <div className="form-action">
          <div className="button_column">
            <div className="button_left_area">
              <input
                type="submit"
                name="commit"
                value={I18n.t('common.action.create')}
                className="btn btn-primary"
                disabled={(titles.length == 0 || newsTopics.length == 0) ? true : !this.state.canSubmit}
                data-disable-with={I18n.t('common.action.sending')}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  public renderErrorMessage = () => {
    const { errorMessage } = this.props;

    if (errorMessage) {
      return(
        <div className="col-md-10 has-error">
          <span className="help-block">{errorMessage}</span>
        </div>
      );
    }

    return null;
  }

  private toSelectOption(option: [string, string]): Option<string>{
    return { label: option[0], value: option[1]};
  }
}

export default connect(mapStateToProps)(Subscribes);
