import { List, Record } from 'immutable';
import moment from 'moment-timezone';
import I18n from '../lib/i18n';
import MovieList from '../models/MovieList';

interface IUrlList {
  lang: string;
  url: string;
  ncms_lang: string;
  movie_meta_id: string;
}

interface IList {
  video_name: string;
  distribution_start_datetime: number;
  distribution_end_datetime: number;
  url_list: IUrlList[];
}

interface IRecord {
  list: IList[];
  selectedProduct: {
    label?: string;
    value?: string;
    names?: {
      [lang: string]: string;
    };
  };
  selectedVideoIndex: string;
  selectedLanguages: List<string>;
  isFetching: boolean;
}
const defaultValue: IRecord = {
  list: [],
  selectedProduct: {},
  selectedVideoIndex: '',
  selectedLanguages: List(),
  isFetching: false,
};

export default class SuggestVideoList extends Record(defaultValue) {
  public static buildCountrySearchQuery(countries: string[]) {
    return countries.map(country => `countries[]=${country}`).join('&');
  }

  public updateList({ key, value }: { key: keyof IRecord, value: ValueOf<IRecord> }) {
    return this.set(key, value);
  }
  public toggleLanguageStatus(language: string, $$state: any) {
    let newSelectedLanguages = null;
    if (this.languageExists(language)) {
      const deleteIndex = this.selectedLanguages.indexOf(language);
      newSelectedLanguages = this.selectedLanguages.delete(deleteIndex);
    } else {
      // toSet().toList() でuniqを取る
      newSelectedLanguages = this.selectedLanguages.push(language).toSet().toList();
    }

    return $$state.setIn(['suggestVideoList', 'selectedLanguages'], newSelectedLanguages);
  }
  // 選択された言語分、関連動画を追加する
  // 追加した後は関連動画サジェストの動画リストと言語のチェック状態を初期値に戻す
  public applySuggestVideoList($$state: any) {
    const { selectedProduct, list } = this;
    const selectedVideo = this.selectedVideo();

    if (selectedVideo === undefined) {return $$state; }

    let $$newState = $$state.set('suggestVideoList', new SuggestVideoList({ selectedProduct, list }));
    this.selectedLanguages.forEach(language => {
      const movieMetaId = this.getMovieMetaIdByLanguage(language);
      const key = ['messageDetails', this.getMessageDetailIndexByLanguage($$state, language), 'related_movies'];
      $$newState = $$newState.updateIn(key, (movieList: any) => movieList.add(movieMetaId, $$state.nemoEnv, true, selectedVideo.video_name));
    });
    $$state.notifyAction({
      title: I18n.t('message_details.form.suggest_video_list.title'),
      message: I18n.t('message_details.tooltips.suggest_video_list.notify', { language: '' }),
      type: 'info',
    });
    return $$newState;
  }

  public applyMainVideoSuggestVideoList($$state: any) {
    const { selectedProduct, list } = this;
    let $$newState = $$state.set('suggestVideoList', new SuggestVideoList({ selectedProduct, list }));
    this.selectedLanguages.forEach(language => {
      const movieMetaId = this.getMovieMetaIdByLanguage(language);
      if (!movieMetaId) {
        return;
      }
      const key = ['messageDetails', this.getMessageDetailIndexByLanguage($$state, language), 'main_movie_attributes'];
      $$newState = $$newState.setIn(key, (new MovieList()).add(movieMetaId, $$state.nemoEnv, true) );
    });
    $$state.notifyAction({
      title: I18n.t('message_details.form.suggest_video_list.title'),
      message: I18n.t('message_details.tooltips.suggest_video_list.notify', { language: '' }),
      type: 'info',
    });
    return $$newState;
  }

  public applyListVideoSuggestVideo($$state: any) {
    const { selectedProduct, list } = this;
    let $$newState = $$state.set('suggestVideoList', new SuggestVideoList({ selectedProduct, list }));
    this.selectedLanguages.forEach(language => {
      const movieMetaId = this.getMovieMetaIdByLanguage(language);
      if (!movieMetaId) {
        return;
      }
      const key = ['messageDetails', this.getMessageDetailIndexByLanguage($$state, language), 'list_movie_attributes'];
      $$newState = $$newState.setIn(key, (new MovieList()).add(movieMetaId, $$state.nemoEnv, false) );
    });
    $$state.notifyAction({
      title: I18n.t('message_details.form.suggest_video_list.title'),
      message: I18n.t('message_details.tooltips.suggest_video_list.notify', { language: '' }),
      type: 'info',
    });
    return $$newState;
  }

  public applyMainContentVideoSuggestVideoList($$state: any, mainContentsIndex: number) {
    const { selectedProduct, list } = this;
    let $$newState = $$state.set('suggestVideoList', new SuggestVideoList({ selectedProduct, list }));
    this.selectedLanguages.forEach(language => {
      const movieMetaId = this.getMovieMetaIdByLanguage(language);
      if (!movieMetaId) {
        return;
      }

      const key = ['messageDetails', this.getMessageDetailIndexByLanguage($$state, language), 'main_contents_attributes'];
      const notifyPayload = {
        title: I18n.t('message_details.form.suggest_video_list.title'),
        message: I18n.t('message_details.tooltips.suggest_video_list.failed_notify', { language }),
        type: 'danger',
      };

      if ($$newState.getIn(key).get(mainContentsIndex) !== undefined) {
        key.push(mainContentsIndex, 'main_movie_attributes');
        $$newState = $$newState.setIn(key, (new MovieList()).add(movieMetaId, $$state.nemoEnv, true) );
        notifyPayload.message = I18n.t('message_details.tooltips.suggest_video_list.notify', { language });
        notifyPayload.type = 'info';
      }
      $$state.notifyAction(notifyPayload);
    });
    return $$newState;
  }

  public getMessageDetailIndexByLanguage($$state: any, language: string): number {
    const targetMessageDetail = $$state.messageDetails.find((messageDetail: any) => messageDetail.language === language);
    return $$state.messageDetails.indexOf(targetMessageDetail);
  }

  public getMovieMetaIdByLanguage(language: string) {
    const selectedVideo = this.selectedVideo();
    if (selectedVideo === undefined) { return; }
    const urlList = selectedVideo.url_list.find(elem => elem.lang === language);
    if (urlList === undefined) { return; }
    return urlList.movie_meta_id;
  }

  public selectedVideo(): IList {
    return this.selectedVideoIndex ? this.list[this.selectedVideoIndex] : undefined;
  }

  public toSelectOption(timezone: string) {
    return this.list.map((video, index) => {
      const startDatetime =
        video.distribution_start_datetime ?
        moment(video.distribution_start_datetime).tz(timezone).format('Y-MM-DD HH:mm Z') :
        '';
      const endDatetime =
        video.distribution_end_datetime ?
        moment(video.distribution_end_datetime).tz(timezone).format('Y-MM-DD HH:mm Z') :
        '';

      const label =
        video.video_name +
        ` ${I18n.t('activerecord.attributes.movie.start_datetime')}: ${startDatetime} / ${I18n.t('activerecord.attributes.movie.end_datetime')}: ${endDatetime}`;
      return { label, value: index.toString() };
    });
  }

  public languageExists(language: string) {
    return this.selectedLanguages.includes(language);
  }

  public selectedProductExists() {
    return Object.keys(this.selectedProduct).length !== 0;
  }

  public availableLanguage(editableLanguages: string[]) {
    const selectedVideo = this.selectedVideo();
    if (selectedVideo === undefined || selectedVideo === null) { return []; }

    return selectedVideo.url_list
      .map(list => list.lang)
      .filter(lang => editableLanguages.includes(lang)); // 現在編集中の言語のみに絞る
  }
}
