import { List, OrderedMap, Record } from 'immutable';

export interface IShopCountry {
  ja: string;
  en: string;
  iso3: string;
  iso2: string;
  region: string;
  languages: string[];
}

const defaultValue : {
  languages: List<string>,
  shopCountries: OrderedMap<string, IShopCountry>,
  platforms: string[],
} = {
  languages: List(),
  shopCountries: OrderedMap(),
  platforms: undefined,
};

export default class Constants extends Record(defaultValue) {
  constructor(props: any = defaultValue) {
    const { languages, shopCountries, platforms } = props;
    super({
      languages: List(languages),
      shopCountries: OrderedMap(shopCountries),
      platforms: platforms,
    });
  }
}
