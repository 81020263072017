import { times } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../ducks/MessageForm';
import { SimpleFormCheckbox } from '../../../lib/components/SimpleForm';
import { getDispatch } from '../../../lib/dispatchExporter';
import I18n from '../../../lib/i18n';
import SuggestVideoList from '../../../models/SuggestVideoList';
import { IRootState } from '../../../store/MessageForm';

interface IProps {
  suggestVideoList: SuggestVideoList;
}

function mapStateToProps(state: IRootState) {
  const {
    messageDetails,
    editableLanguages
  } = state.$$formStore;
  const maxMainContentsSize = messageDetails
    .filter((md: any) => editableLanguages.includes(md.language))
    .map((md: any) => md.main_contents_attributes.filter((mc: any) => mc._destroy === false).size)
    .max();
  return {
    editableLanguages,
    maxMainContentsSize,
    semanticsVersion: state.$$formStore.message.semantics_version,
  };
}

type Props = IProps & ReturnType<typeof mapStateToProps>;

class AvailableLanguagesVideo extends React.PureComponent<Props> {
  private dispatch = getDispatch();

  public handleLanguageChange = (language: string) => () => {
    this.dispatch(actions.toggleSuggestVideoListLanguageStatus(language));
  }

  public handleClickAllLanguages = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { suggestVideoList, editableLanguages } = this.props;
    suggestVideoList.availableLanguage(editableLanguages.toArray()).forEach((language: string) => {
      if (suggestVideoList.selectedLanguages.includes(language)) { return; }
      this.dispatch(actions.toggleSuggestVideoListLanguageStatus(language));
    });
  }

  public handleClickClearAllLanguages = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { suggestVideoList, editableLanguages } = this.props;
    suggestVideoList.availableLanguage(editableLanguages.toArray()).forEach((language: string) => {
      if (!suggestVideoList.selectedLanguages.includes(language)) { return; }
      this.dispatch(actions.toggleSuggestVideoListLanguageStatus(language));
    });
  }

  public handleApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    this.dispatch(actions.applySuggestVideoList());
  }

  public handleMainVideoApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    this.dispatch(actions.applyMainVideoSuggestVideoList());
  }

  public handleListVideoApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    this.dispatch(actions.applyListVideoSuggestVideo());
  }

  public handleMainContentVideoApply = (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    this.dispatch(actions.applyMainContentVideoSuggestVideoList(index));
  }

  public renderMainMovieApply() {
    const {
      suggestVideoList,
      maxMainContentsSize,
    } = this.props;
    if (this.props.semanticsVersion < 5) {
      return (
        <button
          onClick={this.handleMainVideoApply}
          disabled={suggestVideoList.selectedLanguages.size === 0}
          className="btn btn-primary"
        >
          {I18n.t('messages.action.add_movie_url')}
        </button>
      );
    }

    const buttons = times(maxMainContentsSize).map(index => (
      <button key={`suggest-video-list-main-movies-${index}`}
        onClick={this.handleMainContentVideoApply(index)}
        disabled={this.props.suggestVideoList.selectedLanguages.size === 0}
        className="btn btn-primary"
      >
        {I18n.t('main_contents.action.add_movie_url', { index: index + 1 })}
      </button>
    ));
    return buttons;
  }

  public renderListMovieApply() {
    const {
      suggestVideoList,
    } = this.props;
    if (this.props.semanticsVersion >= 10) {
      return (
        <button
          onClick={this.handleListVideoApply}
          disabled={suggestVideoList.selectedLanguages.size === 0}
          className="btn btn-primary"
        >
          {I18n.t('messages.action.add_list_movie_url')}
        </button>
      );
    }
  }

  public render() {
    const { suggestVideoList, editableLanguages } = this.props;
    if (suggestVideoList.selectedVideo() === undefined) { return null; }

    return (
      <div className="col-md-10">
        {suggestVideoList.availableLanguage(editableLanguages.toArray()).map((language: string) => {
          return (
            <div key={`available-languages-video-${language}`} className="col-md-2">
              <SimpleFormCheckbox
                label={language}
                checked={suggestVideoList.languageExists(language)}
                onChange={this.handleLanguageChange(language)}
              />
            </div>
          );
        })}
        <button
          style={{ marginTop: '5px' }}
          onClick={this.handleClickAllLanguages}
          className="btn btn-primary"
        >
          {I18n.t('message_details.form.suggest_video_list.check_all')}
        </button>
        <button
          style={{ marginTop: '5px' }}
          onClick={this.handleClickClearAllLanguages}
          className="btn btn-danger"
        >
          {I18n.t('message_details.form.suggest_video_list.remove_all')}
        </button>
        <div className="col-md-12">
          {this.renderListMovieApply()}
          {this.renderMainMovieApply()}
          <button
            onClick={this.handleApply}
            disabled={suggestVideoList.selectedLanguages.size === 0}
            className="btn btn-primary"
          >
            {I18n.t('messages.action.add_related_movies')}
          </button>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(AvailableLanguagesVideo);
