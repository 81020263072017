import { List } from 'immutable';
import * as React from 'react';
import Select from 'react-select';

import { actions } from '../../../../ducks/MessageForm';
import { SimpleFormSelect, SimpleFormString } from '../../../../lib/components/SimpleForm';
import { getDispatch } from '../../../../lib/dispatchExporter';
import I18n from '../../../../lib/i18n';
import notify from '../../../../lib/notify';
import ProductList from '../../../../models/ProductList';
import ProductDragContainer from '../../DragComponents/ProductDragContainer';

interface IProps {
  index: number;
  parentFormName: string;
  countries: List<{ iso2: string }>;
  products: ProductList;
  listName?: string;
  listType?: string;
  featuredProductIds: List<string>;
  displayType: string;
  updateShopJumpQuery: (key: string, value: string) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export default class ProductListForm extends React.PureComponent<IProps> {
  private dispatch = getDispatch();

  public selectProduct = (option: { value: string }) => {
    const { index, products } = this.props;
    const maxProductsLength = (window as any).Constants.validators.message.moreShopQuery.listIdsSize;
    if (products.selectLimitExceeded(maxProductsLength)) {
      notify({
        title: `${I18n.t('activerecord.hints.message_detail.more_shop_query.list')}: `,
        message: I18n.t('activerecord.errors.product.limit_product_number'),
        type: 'danger',
      });
    } else {
      this.dispatch(actions.selectShopList({ productId: option.value, index }));
    }
  }

  public handleSort = (index: number) => ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    this.dispatch(actions.sortShopLists({ index, oldIndex, newIndex }));
  }

  public handleUnselect = (index: number) => (id: string) => {
    this.dispatch(actions.unselectShopList({ productId: id, index }));
  }

  public handleCopyFeaturedProductsClick = () => {
    const { index, featuredProductIds } = this.props;
    this.dispatch(actions.copyFeaturedProductsToShopLinkList({ index, nsUids: featuredProductIds }));
  }

  public renderCopyFeaturedProductsBtn() {
    const { featuredProductIds, displayType } = this.props;
    if (displayType === 'NORMAL' || featuredProductIds.size === 0) { return null; }
    return (
      <div
        className="btn btn-primary"
        onClick={this.handleCopyFeaturedProductsClick}
      >
        { I18n.t('message_details.form.copy_featured_products_to_shop_list_link') }
      </div>
    );
  }

  public render() {
    const {
      index,
      products,
      parentFormName: prevParentFormName,
      listName,
      listType,
      handleChange,
      countries,
    } = this.props;
    const types = (window as any).Constants.message.moreShopQuery.listTypes.map((e: string) => [e, e]);
    const parentFormName = `${prevParentFormName}[list_ids][]`;
    const maxLength = (window as any).Constants.validators.message.moreShopQuery.listNameLength;
    const listNameLength = listName ? listName.length : 0;
    const length = `${listNameLength}/${maxLength}`;
    const error = maxLength < listNameLength ? [''] : undefined;

    return (
      <div>
        <div className="col-md-12">
          <SimpleFormString
            label={I18n.t('activerecord.attributes.message_detail.list_name')}
            value={listName || ''}
            name={`${prevParentFormName}[list_name]`}
            onChange={handleChange}
            helpMessage={length}
            errorMessage={error}
          />
        </div>
        {/* 現在は選択の余地がないので表示しない */}
        <div className="col-md-2" style={{ display: 'none' }}>
          <SimpleFormSelect
            name={`${prevParentFormName}[list_type]`}
            label={''}
            values={types}
            value={listType || ''}
            onChange={handleChange}
          />
        </div>

        <div className="col-md-12">
          <div className="row">
            <div className="col-md-10">
              <Select
                name="related-products-select"
                options={products.filterAvailableCountriesAndPlatforms(countries.map(c => c.iso2).toArray())}
                onChange={this.selectProduct}
              />
            </div>
            <div className="col-md-2">
              { this.renderCopyFeaturedProductsBtn() }
            </div>
          </div>
        </div>
        <div style={{ marginTop: '10px' }} className="col-md-12">
          <ProductDragContainer
            $$products={products}
            countries={countries}
            sortProducts={this.handleSort(index)}
            unselectProduct={this.handleUnselect(index)}
            {...{ parentFormName }}
          />
        </div>
      </div>
    );
  }
}
