import React from 'react';
import ReactOnRails from 'react-on-rails';
import { DiscoveryApproval } from '../components/DiscoveryApproval/DiscoveryApproval';

interface IDiscoveryApprovalAppProps {
  messageId: number;
  canApproveReview: boolean;
  canApproveCensor: boolean;
  canApproveTencentCensor: boolean;
  canApproveDiscovery: boolean;
  hasDuplicatedDiscoveriesByCountry: boolean;
}

const DiscoveryApprovalApp = (props: IDiscoveryApprovalAppProps) => {
  return (
    <DiscoveryApproval
      messageId={props.messageId}
      canApproveReview={props.canApproveReview}
      canApproveCensor={props.canApproveCensor}
      canApproveTencentCensor={props.canApproveTencentCensor}
      canApproveDiscovery={props.canApproveDiscovery}
      hasDuplicatedDiscoveriesByCountry={
        props.hasDuplicatedDiscoveriesByCountry
      }
    />
  );
};

ReactOnRails.register({ DiscoveryApprovalApp });
