import * as React from 'react';

import { actions } from '../../../../ducks/MessageForm';
import { getDispatch } from '../../../../lib/dispatchExporter';
import I18n from '../../../../lib/i18n';
import isKeydownClick from '../../../../lib/isKeydownClick';
import MessageDetail from '../../../../models/MessageDetail';
import Message from '../../../../models/Message';
import MainContentForm from './MainContentForm';

interface IProps {
  index: number;
  $$message: Message;
  $$messageDetail: MessageDetail;
}

export default class MainContentsForm extends React.PureComponent<IProps> {
  private dispatch = getDispatch();

  public handleAddContentBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isKeydownClick(e)) { return; }
    const { index } = this.props;
    const { fetchNewMainContentRequest } = actions;
    this.dispatch(fetchNewMainContentRequest({ index }));
  }

  public renderAddButton() {
    const { $$message, $$messageDetail } = this.props;
    const maxMainContentsSize = $$message.semantics_version >= 10 ?
      (window as any).Constants.message.maxMainContentsSizeV10OrLater :
      (window as any).Constants.message.maxMainContentsSize;

    if ($$messageDetail.main_contents_attributes.filter((e) => !e._destroy).size < maxMainContentsSize) {
      return (
        <button
          className="btn btn-primary"
          onClick={this.handleAddContentBtn}
        >
          {I18n.t('message_details.form.add_main_content')}
        </button>
      );
    }

    return null;
  }

  public isLastMainContent(mainContentIndex: number): boolean {
    const { $$messageDetail } = this.props;
    const mainContents = $$messageDetail.main_contents_attributes;
    return mainContentIndex === mainContents.filter((e) => !e._destroy).size - 1 && mainContentIndex !== 0;

  }

  public render() {
    const { index, $$messageDetail } = this.props;
    const mainContents = $$messageDetail.main_contents_attributes;
    // bodyはMainContentsの合計で判定します。
    const totalBodyLength = mainContents.map((e) => e.body ? e.body.length : 0).reduce((acc: number, e: number) => (acc + e));

    return(
      <div className="message-main-contents">
        { mainContents.map((mainContent, mainContentIndex) =>
          <MainContentForm
            key={mainContentIndex}
            { ...{index, mainContent, mainContentIndex, totalBodyLength} }
            isLastMainContent={this.isLastMainContent(mainContentIndex)}
          />
        )}
        { this.renderAddButton() }
      </div>
    );
  }
}
