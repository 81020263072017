import {
  applyMiddleware,
  compose,
  createStore,
  Middleware,
  Reducer,
} from 'redux';
import { createLogger } from 'redux-logger';
import ravenMiddleware from 'redux-raven-middleware';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import { ForkEffect } from 'redux-saga/effects';

export default function storeCreator<S>(
  context: RailsContext,
  rootReducer: Reducer<any>,
  initialState: S,
  rootSaga?: () => IterableIterator<ForkEffect>,
) {
  const middlewares: Middleware[] = [ravenMiddleware((window as any).raven)];
  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(
      createLogger({
        stateTransformer: state => {
          return state.toJS();
        },
      }),
    );
  }
  const devtools: any =
    process.env.NODE_ENV !== 'production' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
      : (f: any) => f;

  const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(sagaMiddleware, ...middlewares),
      devtools,
    ),
  );
  if (rootSaga) {
    sagaMiddleware.run(rootSaga);
  }

  return store;
}
