import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import 'react-select/dist/react-select.css';

import { actions } from '../../ducks/MessageForm';
import {
  SimpleFormCheckbox,
  SimpleFormDateTime,
  SimpleFormHidden,
  SimpleFormSelect,
  SimpleFormString,
  SimpleFormTextarea,
  SimpleFormLabel,
} from '../../lib/components/SimpleForm';
import { getDispatch } from '../../lib/dispatchExporter';
import I18n from '../../lib/i18n';
import Message, { optionsForEndTime, optionsForStartTime } from '../../models/Message';
import { IRootState } from '../../store/MessageForm';
import FeaturedForm from './FeaturedForm';
import MessageDetailListFormContainer from './MessageDetailList';
import PickupForm from './PickupForm';
import ProductForm from './ProductForm';
import RelatedTopicsForm from './RelatedTopicsForm';
import SearchableApplicationIdsForm from './SearchableApplicationIdsForm';
import SuggestRatingForm from './SuggestRatingForm';
import SuggestVideoListForm from './SuggestVideoListForm';

function mapStateToProps(state: IRootState) {
  return {
    $$message: state.$$formStore.message,
    $$relatedProducts: state.$$formStore.createRelatedProductList(),
    $$featuredProducts: state.$$formStore.createFeaturedProductList(),
    $$relatedTopics: state.$$formStore.relatedTopics,
    $$editableLanguages: state.$$formStore.editableLanguages,
    $$subscribeTitles: state.$$formStore.subscribeTitles,
    selectableCountries: state.$$formStore.selectableCountries,
    byCountryGroup: state.$$formStore.byCountryGroup,
    canSubmit: state.$$formStore.canSubmit,
    copyingDetails: state.$$formStore.copyingDetails,
    selectablePlatforms: state.$$formStore.selectablePlatforms,
    canReadPlatforms: state.$$formStore.canReadPlatforms,
    i18nPlatforms: state.$$formStore.i18nPlatforms,
    isDiscovery:  state.$$formStore.isDiscovery
  };
}
interface IProps {
  countryGroupsOptions: any;
  byCountryGroup: boolean;
  // relatedTopicOption: any;
}
type Props = IProps & ReturnType<typeof mapStateToProps>;

class MessageForm extends React.PureComponent<Props> {
  private dispatch = getDispatch();
  public componentWillMount() {
    const { byCountryGroup } = this.props;
    this.dispatch(actions.updateEditableLanguages());
    if (!byCountryGroup) { return; }
    this.dispatch(actions.fetchProductsRequest());
  }

  public handleChange = (e: any) => {
    // name = message[summary]から
    // key = ["message", "summary"]を作る必要がある
    const key: string[] = e.target.name.replace(/]/g, '').split('[');

    const value: string = e.target.value;
    this.dispatch(actions.updateFormStore({ key, value }));
    this.dispatch(actions.validateForm({ key }));
  }

  public handleClickCheckAllCountriesBtn = (e: React.MouseEvent<Button>) => {
    e.preventDefault();

    this.dispatch(actions.checkAllCountries());
    this.dispatch(actions.updateProductsForChangeCountries(this.props.selectableCountries.map(s => s.iso2).toArray()));
    this.dispatch(actions.updateEditableLanguages());
    this.dispatch(actions.updateCanSubmit());
  }

  public handleClickUncheckAllCountriesBtn = (e: React.MouseEvent<Button>) => {
    e.preventDefault();

    this.dispatch(actions.uncheckAllCountries());
    this.dispatch(actions.updateProductsForChangeCountries([]));
    this.dispatch(actions.updateEditableLanguages());
    this.dispatch(actions.updateCanSubmit());
  }

  public handleChangeCountries = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { $$message, selectableCountries } = this.props;
    const key = ['message', 'countries'];
    const countryId = parseInt(e.target.value, 10);
    if (e.target.parentNode === null) { return; }
    const checked = e.target.checked;
    const { updateFormStore, updateEditableLanguages, updateCanSubmit, updateProductsForChangeCountries } = actions;
    const country = selectableCountries.find(c => c.id === countryId);
    if (country === undefined) { throw Error(`#handleChangeCountries: undefined country(id: ${countryId})`); }

    const nextTargetCountries =
      checked ?
      $$message.countries.push(country) :
      $$message.countries.filterNot(x => x.id === countryId);

    this.dispatch(updateFormStore({ key, value: nextTargetCountries }));
    this.dispatch(updateProductsForChangeCountries(nextTargetCountries.map(c => c.iso2).toArray()));
    this.dispatch(updateEditableLanguages());
    this.dispatch(updateCanSubmit());
  }

  public handlePlatforms = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { $$message } = this.props;
    const key = ['message', 'platforms'];
    const checked = e.target.checked;
    const platform = e.target.value;
    const targetPlatforms =
      checked ?
      $$message.platforms.concat(platform) :
      $$message.platforms.filter(x => x !== platform);
    this.dispatch(actions.updateFormStore({ key, value: targetPlatforms }));
  }

  public handleDiscovery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = ['message', ':message_discovery_attributes'];
    const checked = e.target.checked;
    this.dispatch(actions.updateFormStore({ key, value: checked }));
  }

  public handleBlurPublishingTime = () => {
    const { $$message } = this.props;
    this.dispatch(actions.setDefaultExpirationTime());
    this.dispatch(actions.setDefaultPickupLimit());
    if ($$message.published) {
      alert(I18n.t('messages.alert.warning_update_publishing_time'));
    }
  }

  public validationErrorNotification() {
    const { $$message } = this.props;
    let messageDetailHasErrors = false;
    let featuredProductHasErrors = false;

    if (Object.keys($$message.errors).length !== 0) {
      // form入力中にエラーになった場合は、errorのvalueに[null]という値が入ります。
      // 発生しているエラーが[null]のみの場合は、このエラーコンポーネントを表示しないようにします
      const hasErrorMessages = Object.values($$message.errors).reduce((acc: boolean, cur: string[]) => (acc || cur[0] !== null), false);

      if (!hasErrorMessages) { return null; }

      return (
        <div className="alert alert-danger" role="alert">
          {I18n.t('simple_form.error_notification.default_message')}
          <ul>
            { Object.entries($$message.errors).map(([column, errors]) => {
              if (column === 'base') { return null; }
              if (errors.length === 1 && errors[0] === null) { return null; }
              if (column.match(/^message_details/)) {
                if (!messageDetailHasErrors) {
                  messageDetailHasErrors = true;
                  return(
                    <li key={column}>
                      <a href="#message_message_details" data-role="scroll" className="no-warning">
                        {I18n.t('activerecord.errors.message.message_details_have_errors')}
                      </a>
                    </li>
                  );
                }
                return(null);
              }

              if (column.match(/^featured_products/)) {
                if (!featuredProductHasErrors) {
                  featuredProductHasErrors = true;

                  return(
                    <li key={column}>
                      <a href="#message_featured_products" data-role="scroll" className="no-warning">
                        {I18n.t('activerecord.errors.message.featured_products_have_errors')}
                      </a>
                    </li>
                  );
                }
                return(null);
              }

              const columnLabel = I18n.t(`activerecord.attributes.message.${column}`);
              return (
                <li key={column}>
                  <a href={`#message_${column}`} data-role="scroll" className="no-warning">{columnLabel}</a>
                  {`: ${errors.join(' ')}`}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }

    return (null);
  }

  public deletionPrioritySelector() {
    const { $$message, isDiscovery } = this.props;
    if (!$$message.hasNxNewsLevel()) { return null; }
    if (isDiscovery) { return null; }
    const priorities = (window as any).Constants.message.deletionPriorities as { [s: string]: number };
    const priorityOptions = Object.keys(priorities).map<[string, number]>(e => [e, priorities[e]]);

    return (
      <SimpleFormSelect
        name="message[deletion_priority]"
        required={true}
        values={priorityOptions}
        value={$$message.deletion_priority}
        errorMessage={$$message.errors.deletion_priority}
        popoverText={I18n.t('messages.tooltips.deletion_priority')}
        label={I18n.t('activerecord.attributes.message.deletion_priority')}
        onChange={this.handleChange}
      />
    );
  }

  public displayTypeSelector() {
    const { $$message, isDiscovery } = this.props;
    if (!$$message.hasNxNewsLevel()) { return null; }
    if ($$message.isSystemNews()) { return null; }
    if (isDiscovery) { return null; }
    const { displayTypes } = (window as any).Constants.message;
    const displayTypeOptions = Object.keys(displayTypes)
      // v1はNORMALしか選べない
      .filter(displayType => (Message.LANUNCH_NEWS_VERSION < $$message.semantics_version ? true : displayType === 'NORMAL'))
      .map<[string, string]>(e => [e, e]);

    return (
      <SimpleFormSelect
        name="message[display_type]"
        required={true}
        label={I18n.t('activerecord.attributes.message.display_type')}
        values={displayTypeOptions}
        value={$$message.display_type || ''}
        errorMessage={$$message.errors.display_type}
        popoverText={I18n.t('messages.tooltips.display_type')}
        onChange={this.handleChange}
      />
    );
  }

  public noPhotographySelector() {
    const { $$message } = this.props;
    const noPhotographyOptions: Array<[string, number]> = [
      [I18n.t('activerecord.attributes.message.no_photography_items.allow'), 0],
      [I18n.t('activerecord.attributes.message.no_photography_items.prohibit'), 1],
    ];

    return (
      <SimpleFormSelect
        name="message[no_photography]"
        required={true}
        label={I18n.t('activerecord.attributes.message.no_photography')}
        values={noPhotographyOptions}
        value={$$message.no_photography || ''}
        errorMessage={$$message.errors.no_photography}
        popoverText={I18n.t('messages.tooltips.no_photography')}
        onChange={this.handleChange}
      />
    );
  }

  public captionSelector() {
    const { $$message, isDiscovery } = this.props;
    if (isDiscovery) { return null; }
    const captionOptions: Array<[string, number]> = [
      [I18n.t('activerecord.attributes.message.caption_items.display'), 1],
      [I18n.t('activerecord.attributes.message.caption_items.hide'), 0],
    ];

    return (
      <SimpleFormSelect
        name="message[caption]"
        required={true}
        label={I18n.t('activerecord.attributes.message.caption')}
        values={captionOptions}
        value={$$message.caption || 0}
        errorMessage={$$message.errors.caption}
        popoverText={I18n.t('messages.tooltips.caption')}
        onChange={this.handleChange}
      />
    );
  }

  public countryGroupsSelector() {
    const { $$message, byCountryGroup, countryGroupsOptions, $$relatedProducts } = this.props;

    if (byCountryGroup) {
      return (
        <div>
          <SimpleFormSelect
            name="message[country_group_id]"
            required={true}
            label={I18n.t('activerecord.models.country_group')}
            values={countryGroupsOptions}
            value={$$message.country_group_id || ''}
            errorMessage={$$message.errors.country_group_id}
            popoverText={I18n.t('messages.tooltips.country_group')}
            onChange={this.handleCountryGroupChange}
            disabled={$$relatedProducts.isFetching}
          />

          {this.countryCheckboxes()}
        </div>
      );
    }

    return (null);
  }

  public countryCheckboxes() {
    const { $$message, selectableCountries, $$relatedProducts } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <Button onClick={this.handleClickCheckAllCountriesBtn} bsSize="xsmall" disabled={$$relatedProducts.isFetching}>
              {I18n.t('messages.form.select_all_countries')}
            </Button>
            <Button onClick={this.handleClickUncheckAllCountriesBtn} bsSize="xsmall" disabled={$$relatedProducts.isFetching}>
              {I18n.t('messages.form.unselect_all_countries')}
            </Button>
          </div>
        </div>
        <div className="row">
          {
            $$message.countries.map(e => e.iso2).includes('CA') ?
              <div className="col-md-12">
                IMPORTANT: Due to Canadian law, news article MUST include French to distribute to Canada.
                Do not check CA if the article doesn’t have French.
              </div> : null
          }
          {
            $$message.countries.map(e => e.iso2).includes('MX') ?
              <div className="col-md-12">
                IMPORTANT: Due to updated Mexican rating guidelines,
                news articles that include an ESRB rating MUST also include a SMEC rating to distribute to Mexico.
              </div> : null
          }
          {selectableCountries.map(country =>
            <div key={country.iso2} className="col-md-1">
              <SimpleFormCheckbox
                name="message[country_ids][]"
                controlId={`message_countries_${country.iso2}`}
                label={country.iso2}
                value={country.id}
                checked={$$message.countries.map(e => e.iso2).includes(country.iso2)}
                collection={true}
                onChange={this.handleChangeCountries}
                disabled={$$relatedProducts.isFetching}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  public platformsCheckboxes() {
    const { $$message, selectablePlatforms, canReadPlatforms, i18nPlatforms, isDiscovery } = this.props;
    if (!canReadPlatforms) { return false; }
    if (isDiscovery) { return false; }
    const selectorHasError = (errorMessages) => {
      if (errorMessages === undefined || errorMessages.length === 0) {
        return ('');
      }
      return ('has-error');
    };
    const errorMessage = (errorMessages) => {
      if (errorMessages !== undefined) {
        const errorMessageTags = [];
        errorMessages.map((errorMessage, index) => {
          errorMessageTags.push(<p className="help-block" key={`platforms_error_${index}`}> {errorMessage} </p>);
        });
        return (errorMessageTags);
      }
      return (null);
    };

    return (
      <div id="message_platforms" className={selectorHasError($$message.errors.platforms)}>
        <SimpleFormLabel
          label={I18n.t('attributes.platforms')}
          required={true}
          className="platforms-checkbox"
        >
          <div className="row">
            {selectablePlatforms.map(platform =>
              <div key={platform} className="col-md-2">
                <SimpleFormCheckbox
                  name="message[checkbox_platforms][]"
                  controlId={`message_checkbox_platforms_${platform}`}
                  label={i18nPlatforms[`${platform}`]}
                  value={platform}
                  collection={true}
                  checked={$$message.platforms.includes(platform)}
                  onChange={this.handlePlatforms}
                />
                <SimpleFormHidden
                  name="message[platforms][]"
                  controlId={`message_platforms_${platform}`}
                  value={$$message.platforms.includes(platform) ? platform : ''}
                />
              </div>
            )}
          </div>
        </SimpleFormLabel>
        {errorMessage($$message.errors.platforms)}
      </div>
    );
  }

  public handleCountryGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const selectedCountryGroupId = e.target.value;
    this.handleChange(e);
    this.dispatch(actions.fetchCountriesRequest({
      countryGroupId: selectedCountryGroupId,
    }));
  }

  public renderMessageDetailErrors() {
    const { $$message } = this.props;

    if ($$message.errors.message_details) {
      return (
        <div className="form-group has-error">
          <span className="help-block">{$$message.errors.message_details.join(',')}</span>
        </div>
      );
    }

    return (null);
  }

  public semanticsVersionInput() {
    const { $$message } = this.props;
    if ($$message.hasDebuggerNewsLevel()) {
      return (<SimpleFormString
        name="message[semantics_version]"
        required={true}
        label={I18n.t('activerecord.attributes.message.semantics_version')}
        value={$$message.semantics_version}
        errorMessage={$$message.errors.semantics_version}
        onChange={this.handleChange}
      />);
    }

    return null;
  }

  public renderDefaultLanguageSelect() {
    const { $$message, $$editableLanguages, byCountryGroup } = this.props;
    const languages: string[] = $$editableLanguages.toArray();

    const defaultLanguageOptions = languages.map<[string, string]>(language => [language, language]);
    defaultLanguageOptions.unshift([I18n.t('activerecord.attributes.message.default_language_items.not_specified'), '']);

    return (
      <SimpleFormSelect
        name="message[default_language]"
        label={I18n.t('activerecord.attributes.message.default_language')}
        required={$$message.requiredDefaultLanguage(byCountryGroup)}
        values={defaultLanguageOptions}
        value={$$message.default_language || ''}
        errorMessage={$$message.errors.default_language}
        popoverText={I18n.t('messages.tooltips.default_language')}
        onChange={this.handleChange}
      />
    );
  }

  public renderProducts() {
    const { byCountryGroup, $$message, $$relatedProducts, $$featuredProducts } = this.props;
    if (!byCountryGroup) { return null; }
    if ($$message.isNormalNews()) {
      return <ProductForm {...{ countries: $$message.countries, relatedProducts: $$relatedProducts, platforms: $$message.platforms }} />;
    }
    return(
      <FeaturedForm
        {...{
          countries: $$message.countries,
          featuredProducts: $$featuredProducts,
          platforms: $$message.platforms,
          semanticsVersion: $$message.semantics_version,
          errors: $$message.errors.featured_products
        }}
      />
    );
  }

  public render() {
    const {
      $$message,
      $$relatedTopics,
      $$editableLanguages,
      $$subscribeTitles,
      canSubmit,
      byCountryGroup,
      copyingDetails,
      isDiscovery,
    } = this.props;

    const lengths: { [s: string]: string } = {};
    Object.keys($$message.validators).forEach(key => {
      const length = $$message[key] ? $$message[key].length : 0;
      lengths[key] = `${length}/${$$message.validators[key].maximum}`;
    });

    const submitValue = $$message.id ? I18n.t('common.action.update') : I18n.t('common.action.create');
    const copySubmitValue = $$message.id ? I18n.t('messages.edit.copy_commit') : I18n.t('messages.new.copy_commit');
    const maximumRelatedTopicsLength = $$message.validators.related_topics.maximum;

    return (
      <div>
        <div className="form-inputs">
          {this.validationErrorNotification()}

          {/* summary*/}
          <SimpleFormString
            name="message[summary]"
            required={true}
            label={I18n.t('activerecord.attributes.message.summary')}
            value={$$message.summary || ''}
            errorMessage={$$message.errors.summary}
            helpMessage={lengths.summary}
            popoverText={I18n.t('messages.tooltips.summary')}
            onChange={this.handleChange}
          />

          {/* note*/}
          <SimpleFormTextarea
            name="message[note]"
            label={I18n.t('activerecord.attributes.message.note')}
            value={$$message.note || ''}
            errorMessage={$$message.errors.note}
            helpMessage={lengths.note}
            popoverText={I18n.t('messages.tooltips.note')}
            onChange={this.handleChange}
          />

          <span id="message_searchable_application_ids"/>
          { !isDiscovery ? (
            <SearchableApplicationIdsForm
              value={$$message.searchable_application_ids}
              options={$$subscribeTitles.toJS()}
              maximumSize={$$message.validators.searchable_application_ids.maximum}
              errorMessage={$$message.errors.searchable_application_ids}
              hasNxNewsFormLevel={$$message.hasNxNewsLevel()}
            />
            ) : null }

          {/* semantics_version*/}
          {this.semanticsVersionInput()}

          {/* country_grouup*/}
          {this.countryGroupsSelector()}

          {/* platforms*/}
          {this.platformsCheckboxes()}

          {/* discovery*/}
          { isDiscovery ? (
            <SimpleFormHidden
              name="message[message_discovery_attributes]"
              value={isDiscovery.toString()}
            />
          ) : null }

          <div className="flex_column">
            {/* deletion_priority*/}
            {this.deletionPrioritySelector()}

            {/* display_type*/}
            {this.displayTypeSelector()}
          </div>

          <div className="flex_column">
            {/* no_photography */}
            {this.noPhotographySelector()}

            {/* caption */}
            {this.captionSelector()}
          </div>

          <div className="flex_column">
            <SimpleFormDateTime
              name="message[publishing_time]"
              required={true}
              label={I18n.t('activerecord.attributes.message.publishing_time')}
              value={$$message.publishing_time || ''}
              errorMessage={$$message.errors.publishing_time}
              popoverText={I18n.t('messages.tooltips.publishing_time')}
              options={optionsForStartTime}
              onChange={this.handleChange}
              onBlur={this.handleBlurPublishingTime}
            />
            <SimpleFormDateTime
              name="message[expiration_time]"
              label={I18n.t('activerecord.attributes.message.expiration_time')}
              value={$$message.expiration_time || ''}
              errorMessage={$$message.errors.expiration_time}
              popoverText={I18n.t('messages.tooltips.expiration_time')}
              options={optionsForEndTime}
              onChange={this.handleChange}
            />
            { !isDiscovery ? (
              <SimpleFormDateTime
                name="message[display_limit]"
                label={I18n.t('activerecord.attributes.message.display_limit')}
                value={$$message.display_limit || ''}
                errorMessage={$$message.errors.display_limit}
                popoverText={I18n.t('messages.tooltips.display_limit')}
                options={optionsForEndTime}
                onChange={this.handleChange}
              />
            ) : null }
          </div>

          { !isDiscovery ? (
            <PickupForm {...{ $$message }} />
          ) : null }

          <div className="flex_column">
            {/* default_language*/}
            { this.renderDefaultLanguageSelect() }

            {/* summary*/}
            {/* 1stNUP 以後の機能なので隠す
            <SimpleFormString
              name="message[age_limit]"
              label={I18n.t('activerecord.attributes.message.age_limit')}
              value={$$message.age_limit || ''}
              errorMessage={$$message.errors.age_limit}
              onChange={(e) => { this.handleChange(e); }}
            />
            */}
          </div>

          {!isDiscovery ? (
            $$message.hasNxNewsLevel() && byCountryGroup && Message.LANUNCH_NEWS_VERSION < $$message.semantics_version ? (
              <RelatedTopicsForm
                {...{ $$relatedTopics, maximumRelatedTopicsLength, $$message }}
              />
            ) : null
          ) : null }

          { !isDiscovery ? (
            <div id="message_featured_products">
              {this.renderProducts()}
            </div>
          ) : null }

          <SimpleFormHidden
            name="message[id]"
            value={$$message.id || ''}
          />

          <h3 style={{ marginBottom: '20px', fontWeight: 'bold' }}>
            {I18n.t('messages.form.language_specific_settings')}
          </h3>
          <SuggestVideoListForm />
          <SuggestRatingForm />
          { this.renderMessageDetailErrors() }

          <div id="message_message_details">
            <MessageDetailListFormContainer />
          </div>
        </div>

        <div className="form-action">
          <div className="button_column">
            <div className="button_left_area">
              <input
                type="submit"
                name="commit"
                value={submitValue}
                className="btn btn-primary"
                disabled={!canSubmit || copyingDetails}
                data-disable-with={I18n.t('common.action.sending')}
              />
              <input
                type="submit"
                name="copy_commit"
                value={copySubmitValue}
                className={$$editableLanguages.size > 1 ? 'btn btn-primary' : 'not-display'}
                disabled={!(canSubmit && $$message.hasDefaultLanguage())}
                data-disable-with={I18n.t('common.action.sending')}
                data-confirm={I18n.t('messages.confirm.copy_commit')}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(MessageForm);
