import { OrderedMap, Record } from 'immutable';

import Data from './Data';

const defaultValue: {
  id: number,
  directoryId: number,
  filename: string,
  platforms: string[],
  note: string,
  warnBlankSchedule: boolean,
  canChangeFilename: boolean,
  canDestroy: boolean,
  canEditVirtualFile: boolean,
  canReadPlatforms: boolean,
  dataEntities: OrderedMap<string, Data | undefined>,
  errors: {},
} = {
  id: 0,
  directoryId: 0,
  filename: '',
  platforms: [],
  note: '',
  warnBlankSchedule: false,
  canChangeFilename: true,
  canDestroy: false,
  canEditVirtualFile: false,
  canReadPlatforms: false,
  dataEntities: OrderedMap(),
  errors: {},
};

export default class VirtualFile extends Record(defaultValue) {
  public appendData(data: Data): VirtualFile {
    return this.setIn(['dataEntities', data.id], data);
  }
  public removeData(dataId: string): VirtualFile {
    if (this.dataEntities.has(dataId)) { return this.deleteIn(['dataEntities', dataId]); }
    return this;
  }
  public updateData(dataId: string, key: string, value: string | {}): VirtualFile {
    return this.setIn(['dataEntities', dataId, key], value);
  }
  public setOriginalFileName(dataId: string, name: string): VirtualFile {
    return this.setIn(['dataEntities', dataId, 'binary', 'contents_file_name'], name);
  }
  public getPreviousData(dataId: string): Data | undefined {
    const ids = this.dataEntities.keySeq();
    const index = ids.keyOf(dataId);
    if (index === undefined || index === 0) { return undefined; }
    const prevDataId = ids.get(index - 1);
    if (typeof prevDataId === 'undefined') { return undefined; }
    return this.dataEntities.get(prevDataId);
  }
  public getNextData(dataId: string): Data | undefined {
    const ids = this.dataEntities.keySeq();
    const index = ids.keyOf(dataId);
    if (index === undefined || (index + 1 === ids.size)) { return undefined; }
    const nextDataId = ids.get(index + 1);
    if (typeof nextDataId === 'undefined') { return undefined; }
    return this.dataEntities.get(nextDataId);
  }
  public dataIsEmpty(): boolean {
    return this.dataEntities.size === 0;
  }
  public parentPath(): string {
    return `/directories/${this.directoryId}/files`;
  }
}
