import React from 'react';
import I18n from '../../lib/i18n';
import PropTypes from 'prop-types';

export const DiscoveryApproval = (props) => {
  const SubmitButton = (props) => {
    // 任天堂レビュー・Tencentレビュー・配信前レビューの有無によって、クリック時のpost先を変更
    let href = '';
    switch(true) {
      case props.canApproveCensor:
        href = 'approve_censor';
        break;
      case props.canApproveTencentCensor:
        href = 'approve_tencent_censor';
        break;
      case props.canApproveReview:
        href = 'approve_review';
        break;
      default:
        href = 'approve_publishing';
    }

    return (<a
      type="button"
      className={`btn btn-primary ${props.canApproveDiscovery ? '' : 'disabled'}`}
      data-method="post"
      href={`/messages/${props.messageId}/${href}`}>
      {I18n.t('data_lists.show.notify')}
    </a>);
  };

  return (
    <div>
      <div className='publish'>{I18n.t('deliverables.confirm.publish')}</div>
      { props.hasDuplicatedDiscoveriesByCountry ? (
        ( props.canApproveDiscovery ? (
          // 今から承認するディスカバリー記事の配信国に既にディスカバリー記事が配信されている場合、警告が含まれる文言を表示する
          <div>
            <div className='note'>
              <strong>{I18n.t('deliverables.confirm.note')}: </strong>
              {I18n.t('deliverables.confirm.note_details')}
            </div>
            {I18n.t('deliverables.confirm.note_tips')}
          </div>
        ) : (
          // 古い配信日のイレギュラーなディスカバリー記事が作成された場合、承認不可の文言を表示
          <div className='error'>
            <strong>{I18n.t('deliverables.confirm.error')}: </strong>
            {I18n.t('deliverables.confirm.cannot_approve_discovery')}
          </div>
        ))
      ) : (
        <div>{I18n.t('deliverables.confirm.publish_tips')}</div>
      )}

      <div className="flex_column_full button_area">
        <button type="button" className="btn btn-default btn-cancel" data-dismiss="modal">
          {I18n.t('deliverables.action.cancel')}
        </button>
        { SubmitButton(props) }
      </div>
    </div>
  );
};

DiscoveryApproval.propTypes = {
  messageId: PropTypes.number,
  canApproveReview: PropTypes.bool,
  canApproveCensor: PropTypes.bool,
  canApproveTencentCensor: PropTypes.bool,
  canApproveDiscovery: PropTypes.bool,
  hasDuplicatedDiscoveriesByCountry: PropTypes.bool
};
