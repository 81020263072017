import ReactOnRails from 'react-on-rails';
import { IDataFile } from '../startup/DistributionEditorApp';
import SerializeService, { ISubmitDataFile } from './SerializeService';

function apiOptions(method: string, body?: FormData): RequestInit {
  return {
    headers: ReactOnRails.authenticityHeaders(),
    body,
    credentials: 'include',
    method,
  };
}

interface IResponseBody {
  datafiles: IDataFile[];
  totalPages: number;
}

interface IResponseError {
  errors?: {};
  message?: string;
}

function requestExec(path: string, requestOptions: RequestInit) {
  return fetch(path, requestOptions).then(response =>
    response.json().then((json: IResponseBody | IResponseError) => {
      if (response.ok) {
        return { response: json };
      }
      return { response: json };
    }).catch(() => ({ response: { message: `${response.status} ${response.statusText}` } as IResponseError }))
  );
}

export function createData(submitData: ISubmitDataFile, page: number, include_expired: boolean) {
  const path = `/directories/${submitData.directory_id}/datafiles.json?page=${page}&include_expired=${include_expired}`;
  const options = apiOptions('POST', SerializeService.toFormData(submitData));
  return requestExec(path, options);
}

export function updateData(submitData: ISubmitDataFile, page: number, include_expired: boolean) {
  const path = `/datafiles/${submitData.id}.json?page=${page}&include_expired=${include_expired}`;
  const options = apiOptions('PATCH', SerializeService.toFormData(submitData));
  return requestExec(path, options);
}

export function changeDataStatus({ dataId, actionName, page, include_expired }: { dataId: string, actionName: string, page: number, include_expired: boolean }) {
  let path: string;
  let options: RequestInit;
  if (actionName === 'destroy') {
    path = `/datafiles/${dataId}.json?page=${page}&include_expired=${include_expired}`;
    options = apiOptions('DELETE');
  } else {
    path = `/datafiles/${dataId}/${actionName}.json?page=${page}&include_expired=${include_expired}`;
    options = apiOptions('POST');
  }
  return requestExec(path, options);
}

export function getData(page: number, include_expired: boolean, id: number) {
  const path = `/files/${id}?page=${page}&include_expired=${include_expired}`;
  return requestExec(path, apiOptions('GET'));
}
