import * as React from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { actions } from '../../ducks/DistributionEditor';
import I18n from '../../lib/i18n';
import { IDistributionEditorStore } from '../../store/DistributionEditor';
import DataEditView from './DataEditView';
import DataView from './DataView';
import FileView from './FileView';
import TimelineView from './TimelineView';

function mapStateToProps(state: IDistributionEditorStore) {
  return { state: state.distributionEditor };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return {
    ...bindActionCreators(actions, dispatch),
  };
}

export type DistributionEditorProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class DistributionEditor extends React.PureComponent<DistributionEditorProps> {
  public renderErrorMessage = () => {
    const { errorMessage } = this.props.state;
    if (errorMessage) {
      return <Alert bsStyle="danger">{errorMessage}</Alert>;
    }
    return null;
  }

  public render() {
    const state = this.props.state;
    const editingData = state.getEditingData();
    const canSubmit = state.canSubmitData();
    const canEditDatafile = state.permittedActions.includes('update');
    const canExpire = state.permittedActions.includes('expire');
    const dataEntities = state.getDataEntities();
    return (
      <div>
        <FileView file={state.file} canEditDatafile={canEditDatafile} i18nPlatforms={state.i18nPlatforms} />
        {dataEntities.toList().size !== 0 ? <TimelineView timeline={state.getTimelineDataSet()} timezone={state.timezone} selectData={this.props.selectData} /> : null}
        {this.renderErrorMessage()}
        <DataView
          dataEntities={dataEntities}
          page={state.page}
          totalPages={state.totalPages}
          canEditDatafile={canEditDatafile}
          timezone={state.timezone}
          changePage={this.props.changePage}
          selectData={this.props.selectData}
          addPrevData={this.props.addPrevData}
          addNextData={this.props.addNextData}
          startEditData={this.props.startEditData}
          changeStatus={this.props.changeStatus}
          changeConditions={this.props.changeConditions}
        />
        {editingData ? <DataEditView
          data={editingData}
          canSubmit={canSubmit}
          canExpire={canExpire}
          updateData={this.props.updateData}
          submitData={this.props.submitData}
          endEditData={this.props.endEditData}
          setFile={this.props.setFile}
          deleteFile={this.props.deleteFile}
        /> : null}
        <div className="flex-column">
          {state.file.dataIsEmpty() && canEditDatafile ?
            <button
              className="btn btn-primary"
              onClick={this.props.addLonelyData}
            >
              {I18n.t('virtual_files.form.add_datafile')}
            </button> : null}
          <a href={state.file.parentPath()} className="btn btn-info">{I18n.t('common.back')}</a>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributionEditor);
