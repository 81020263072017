import { Record } from 'immutable';
import { ITitle } from '../interfaces/TitleState';
import { INewsTopic } from '../interfaces/NewsTopicState';

export interface ISubscribeState {
  titles: ITitle[];
  newsTopics: INewsTopic[];
  selectedTitleId: number;
  selectedNewsTopicId: number;
  canSubmit: boolean;
}

const defaultValue: ISubscribeState = {
  titles: [],
  newsTopics: [],
  selectedTitleId: 0,
  selectedNewsTopicId: 0,
  canSubmit: true,
};

export default class SubscribeState extends Record(defaultValue) {
  public readonly titles: ITitle[];
  public readonly newsTopics: INewsTopic[];
}
