import PropTypes from 'prop-types';
import { Record } from 'immutable';
import { recordOf } from 'react-immutable-proptypes';
import SelectableList from './SelectableList';

const VirtualFileRecord = new Record({
  id: undefined,
  filename: undefined,
  platforms: undefined,
  selected: false,
});

export default class VirtualFile extends VirtualFileRecord {
  static PropTypes = recordOf({
    id: PropTypes.number.isRequired,
    filename: PropTypes.string.isRequired,
  })

  static fromDatafiles(datafiles) {
    const uniqueVirtualFiles = new Set(datafiles.map(
      datafile => ({ id: datafile.virtual_file_id, filename: datafile.filename, platforms: datafile.platforms })
    ));
    return new SelectableList([...uniqueVirtualFiles], VirtualFile);
  }
  toggle() {
    return this.set('selected', !this.selected);
  }
  select() {
    return this.set('selected', true);
  }
  unselect() {
    return this.set('selected', false);
  }
  label() {
    return this.filename;
  }
}
