import { List, Map, Record } from 'immutable';
import { compact } from 'lodash';
import Attachment from './Attachment';

// name, note以外はデフォルトnullにして、null以外の値がサーバからセットされたら表示可能とする
export interface INewsSetting {
  channel_option_id: number;
  news_topic_id: number;
  name?: string;
  note?: string;
  platforms?: string[];
  news_passphrase_type?: string;
  npns_topic_id?: string;
  enable_censor?: boolean;
  enable_tencent_censor?: boolean;
  enable_review?: boolean;
  news_form_level?: number;
  semantics_version?: number;
  pseudo?: boolean;
  list_image_required?: boolean;
  na_required?: boolean;
  service_status?: string;
  official?: boolean;
  public?: boolean;
  is_china?: boolean;
  default_language?: string;
  countries?: List<string>;
  expiration_time?: string;
  news_icon_image?: Attachment;
  can_read_platforms?: boolean;
  topic_countries?: boolean;
  news_descriptions?: boolean;
  length: { [s: string]: number };
  errors: Map<string, string[] | undefined>;
}
const defaultValue: INewsSetting = {
  channel_option_id: 0,
  news_topic_id: 0,
  name: undefined,
  note: undefined,
  platforms: undefined,
  news_passphrase_type: undefined,
  npns_topic_id: undefined,
  enable_censor: undefined,
  enable_tencent_censor: undefined,
  enable_review: undefined,
  news_form_level: undefined,
  semantics_version: undefined,
  pseudo: undefined,
  list_image_required: undefined,
  na_required: undefined,
  service_status: undefined,
  official: undefined,
  public: undefined,
  is_china: undefined,
  default_language: undefined,
  countries: undefined,
  expiration_time: undefined,
  news_icon_image: undefined,
  can_read_platforms: undefined,
  topic_countries: undefined,
  news_descriptions: undefined,
  length: {},
  errors: Map(),
};

export type newsSettingStringProperties = 'name' | 'note' | 'news_passphrase_type' | 'npns_topic_id' | 'service_status' | 'default_language' | 'expiration_time';

export default class NewsSetting extends Record(defaultValue) {
  constructor(object: any = defaultValue) {
    const newObject = object;
    if (object.errors) { newObject.errors = Map(object.errors); }
    if (object.news_icon_image) { newObject.news_icon_image = new Attachment(object.news_icon_image); }
    if (object.countries) { newObject.countries = List(newObject.countries); }
    super(newObject);
  }
  // mutations
  public updateSetting({ key, value }: { key: keyof INewsSetting, value: any }): NewsSetting {
    return this.set(key, value).validate(key);
  }

  public updatePlatforms({ key, value }: { key: keyof INewsSetting, value: any }): NewsSetting {
    if (this.platforms.includes(value)) {
      return this.set(key, this.platforms.filter(platform => platform !== value));
    } else {
      return this.set(key, this.platforms.concat(value));
    }
  }

  public validate(key: keyof INewsSetting): NewsSetting {
    const value = this.get(key);
    if (typeof value !== 'string') { return this; }
    const maxLength = this.length[key];
    if (maxLength === undefined) { return this; }

    // 長さオーバーしているときにはnullを突っ込み、解消されたらcompactでnullだけ消す処理
    // this.errors[key]がundefinedなケースがあるのでその場合は[null]を代入します
    if (value.length > maxLength) {
      return this.updateIn(['errors', key], messages => messages ? messages.concat(null) : [null]);
    }
    return this.updateIn(['errors', key], messages => compact(messages));
  }

  public isValid(): boolean {
    // validateのelse節でcompactにより[]が入るため
    return this.errors.reduce((sum, v) => sum + (v || []).length, 0) === 0;
  }
  public getError(column: string): string[] | undefined {
    return this.errors.get(column);
  }
  public getLength(column: newsSettingStringProperties): string {
    return `${(this.get(column) || '').length}/${this.length[column]}`;
  }
}
