import ActionReducer from 'action-reducer';
import SubscribeState from '../models/SubscribeState';

export const $$initialState = new SubscribeState();
const { reducer } = ActionReducer($$initialState);
export interface IRootState {
  $$subscribeStore: SubscribeState;
}

export default reducer;

