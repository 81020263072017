import ActionReducer from 'action-reducer';
import PromotionState from '../models/PromotionState';

export const $$initialState = new PromotionState();
const { reducer } = ActionReducer($$initialState);
export interface IRootState {
  $$promotionStore: PromotionState;
}

export default reducer;

