import { List } from 'immutable';
import * as React from 'react';
import Select, { Option } from 'react-select';

import { actions } from '../../ducks/MessageForm';
import GlyphPopover from '../../lib/components/GlyphPopover';
import { getDispatch } from '../../lib/dispatchExporter';
import I18n from '../../lib/i18n';
import notify from '../../lib/notify';
import { ICountry } from '../../models/Message';
import Product from '../../models/Product';
import ProductList from '../../models/ProductList';
import ProductDragContainer from './DragComponents/ProductDragContainer';

interface IProps {
  featuredProducts: ProductList;
  countries: List<ICountry>;
  semanticsVersion: number;
  errors?: string[];
}

export default class FeaturedForm extends React.PureComponent<IProps> {
  private dispatch = getDispatch();

  public selectProduct = (e: Option<string>) => {
    const nsUid = e.value;
    if (nsUid === undefined) { return; }
    const { featuredProducts } = this.props;
    // FIXME: 以下のnotifyも含めたロジックはmodelへ移動し、エラーだけ渡す形にしたい
    if (featuredProducts.selectLimitExceeded(this.maxProductsLength())) {
      notify({
        title: `${I18n.t('featured_products.selector.label')}: `,
        message: I18n.t('activerecord.errors.product.limit_product_number'),
        type: 'danger',
      });
      return;
    }
    this.dispatch(actions.selectFeaturedProduct(nsUid));
    if (Product.isSoftDetail(nsUid)) {
      this.dispatch(actions.fetchRelatedChannelsRequest({ nsUid }));
    }
  }

  public handleSort = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    this.dispatch(actions.sortFeaturedProducts({ oldIndex, newIndex }));
  }

  public handleUnselect = (id: string) => {
    this.dispatch(actions.unselectFeaturedProduct(id));
  }

  public maxProductsLength = () => {
    // FeaturedFormはdisplayType.NORMALではrenderされない
    // RANKINGとLISTの選択数は同じという条件が前提
    const displayType = (window as any).Constants.message.displayTypes.RANKING;
    const { semanticsVersion } = this.props;
    if (semanticsVersion >= 6) {
      return (window as any).Constants.validators.message.maxProductsLength[displayType];
    } else {
      return (window as any).Constants.validators.message.maxProductsLengthSemanticsVersion5OrBelow[displayType];
    }
  }

  public renderError = () => {
    const { errors } = this.props;

    if (errors) {
      return(
        <div className="col-md-10 has-error">
          <span className="help-block">{errors.join(',')}</span>
        </div>
      );
    }

    return null;
  }

  public render() {
    const { featuredProducts, countries } = this.props;
    const parentFormName = '';

    return (
      <div>
        <label htmlFor="related-products" className="control-label">
          {I18n.t('featured_products.selector.label')}
        </label>
        <GlyphPopover
          title={I18n.t('featured_products.selector.tooltip', {maximum: this.maxProductsLength()})}
          body={I18n.t('messages.tooltips.products')}
        />
        <br />
        <div className="row">
          <div className="col-md-10">
            <Select
              name="featured-products-select"
              options={featuredProducts.filterAvailableCountriesAndPlatforms(countries.map(e => e.iso2).toArray())}
              onChange={this.selectProduct}
              isLoading={featuredProducts.isFetching}
              disabled={featuredProducts.isFetching}
              menuContainerStyle={{ position: 'relative' }}
            />
          </div>
          <div style={{ marginTop: '10px' }} className="col-md-12">
            <ProductDragContainer
              $$products={featuredProducts}
              countries={countries}
              sortProducts={this.handleSort}
              unselectProduct={this.handleUnselect}
              {...{ parentFormName }}
            />
          </div>
          { this.renderError() }
        </div>
      </div>
    );
  }
}
