import React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../ducks/MessagePreview';
import { getDispatch } from '../../../lib/dispatchExporter';
import I18n from '../../../lib/i18n';
import ListImagePreviews from '../ListImagePreviews';
import LoadingIndicator from '../LoadingIndicator';
import NewsHeader from '../sharedUnpackeNewsComponents/NewsHeaderV2OrLater';
import RelatedChannelList from '../sharedUnpackeNewsComponents/RelatedChannelList';
import RelatedProductList from '../sharedUnpackeNewsComponents/RelatedProductList';
import NewsBody from './NewsBody';

function mapStateToProps(state: any) {
  return {
    isFetching: state.$$previewStore.get('isFetching'),
    semanticsVersion: state.$$previewStore.get('semanticsVersion'),
    languagesAndIds: state.$$previewStore.get('languagesAndIds'),
    activeLanguage: state.$$previewStore.get('activeLanguage'),
    unpackedNews: state.$$previewStore.get('unpackedNews'),
    isPrintPreview: state.$$previewStore.get('isPrintPreview'),
  };
}

type Props = ReturnType<typeof mapStateToProps>;

export class UnpackedNewsV11View extends React.PureComponent<Props> {
  private dispatch = getDispatch();

  public componentWillMount() {
    const { languagesAndIds, activeLanguage } = this.props;
    this.dispatch(actions.fetchUnpackedNewsRequest(languagesAndIds[activeLanguage]));
  }

  public render() {
    const { unpackedNews, isFetching, isPrintPreview } = this.props;
    if (isFetching) {
      return <LoadingIndicator />;
    }
    if (!unpackedNews) {
      return null;
    }

    const displayClassName =
      isPrintPreview ?
      'display-box-print-preview' :
      'display-box';

    return (
      <div className="message-preview">
        <ListImagePreviews
          listImage={unpackedNews.list_image}
          subjectText={unpackedNews.subject.text}
          iconImage={unpackedNews.topic_image}
          isCaptionDisplay={unpackedNews.isCaptionDisplay()}
        />
        <div className="nx-display">
          <div className={displayClassName}>
            <div className="scroll">
              <div className="main-area">
                <div className="main-contents">
                  <NewsHeader
                    newsIcon={unpackedNews.topic_image}
                    topicName={unpackedNews.topic_name}
                    publisher={unpackedNews.topic_publisher}
                  />
                  <NewsBody
                    subject={unpackedNews.subject.text}
                    body={unpackedNews.body}
                    ranking={unpackedNews.display_type === 'RANKING'}
                    featuredProducts={unpackedNews.featured_products}
                    more={unpackedNews.more}
                    relatedMovies={unpackedNews.related_movies}
                    footerText={unpackedNews.footer.text}
                    ratingInformation={unpackedNews.rating_information}
                  />
                </div>
              </div>
              <div className="side-area">
                <RelatedProductList relatedProducts={unpackedNews.related_products} />
                <RelatedChannelList relatedChannels={unpackedNews.related_channels} />
              </div>
            </div>
          </div>
          <div className="news-viewer-footer-area" />
        </div>
        <p className="hints warning">
          {I18n.t('messages.hints.only_support_chrome')}
        </p>
        <p className="hints">
          {I18n.t('messages.hints.preview_area_emulate_nx_screen_size')}
        </p>
        <p className="hints">
          {I18n.t('messages.hints.preview_area_is_scrollable')}
        </p>
        <p className="hints">
          {I18n.t('messages.hints.line_feed_position_may_not_be_accurate')}
        </p>
      </div>
    );
  }
}

export const UnpackedNewsV11ViewContainer = connect(mapStateToProps)(UnpackedNewsV11View);
