import Data from '../models/DistributionFile/Data';
import VirtualFile from '../models/DistributionFile/VirtualFile';

interface ISubmitBinary {
  id?: number;
  contents: File;
  contents_type: string;
}

export interface ISubmitDataFile {
  id: string;
  summary: string;
  filename: string;
  platforms: string[];
  directory_id: number;
  virtual_file_id: number;
  publishing_time: string;
  expiration_time?: string;
  binary_attributes?: ISubmitBinary;
}

export interface ISubmitVirtualFile {
  id: number;
  filename: string;
  platforms: string[];
  note: string;
  warn_blank_schedule: boolean;
}

// ImmutableのモデルからnestedなJSONをつくります
// サーバへcreate, updateデータをPOSTするためのシリアライザです
export default class SerializeService {
  public static serializeData(virtualFile: VirtualFile, data: Data, file?: File): ISubmitDataFile {
    const dataFile: ISubmitDataFile = {
      id: data.id,
      summary: data.summary,
      filename: virtualFile.filename,
      platforms: virtualFile.platforms,
      directory_id: virtualFile.directoryId,
      virtual_file_id: virtualFile.id,
      publishing_time: data.publishingTime,
      expiration_time: data.expirationTime,
    };

    if (file !== undefined) {
      const binaryAttributes: ISubmitBinary = {
        id: data.binary.id,
        contents: file,
        contents_type: 'datafile',
      };

      if (data.isNewData()) {
        delete binaryAttributes.id;
        delete dataFile.id;
      }
      dataFile.binary_attributes = binaryAttributes;
    }
    return dataFile;
  }

  public static toFormData(dataFile: ISubmitDataFile): FormData {
    const formData = new FormData();

    Object.keys(dataFile).forEach((e) => {
      if (dataFile[e] === undefined) {
        return;
      }

      if (e === 'binary_attributes') {
        const binaryAttributes = dataFile.binary_attributes || {};
        Object.keys(binaryAttributes).forEach((f) => {
          formData.append(`datafile[binary_attributes][${f}]`, binaryAttributes[f]);
        });
        return;
      } 

      formData.append(`datafile[${e}]`, dataFile[e]);
    });

    return formData;
  }

  public static serializeFile(virtualFile: VirtualFile): ISubmitVirtualFile {
    return {
      id: virtualFile.id,
      filename: virtualFile.filename,
      platforms: virtualFile.platforms,
      note: virtualFile.note,
      warn_blank_schedule: virtualFile.warnBlankSchedule,
    };
  }
}
