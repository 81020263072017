import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import ravenMiddleware from 'redux-raven-middleware';
import createSagaMiddleware, { SagaMiddlewareOptions } from 'redux-saga';

import messagePreviewReducer, { $$initialState, messagePreviewSaga } from '../ducks/MessagePreview';
import { IMessagePreviewAppProps } from '../startup/MessagePreviewApp';

export default (props: IMessagePreviewAppProps) => { // eslint-disable-line
  const { semanticsVersion, languagesAndIds, messageId, isPrintPreview } = props;

  // Redux expects to initialize the store using an Object, not an Immutable.Map
  const initialState = {
    $$previewStore: $$initialState.merge({
      semanticsVersion,
      languagesAndIds,
      messageId,
      isPrintPreview,
      activeLanguage: Object.keys(languagesAndIds)[0],
    }),
  };

  // reducer
  const combinedReducer = combineReducers({
    $$previewStore: messagePreviewReducer,
  });

  const sagaMiddlewares: Array<SagaMiddlewareOptions<{}>> = [];

  const middlewares =
    process.env.NODE_ENV === 'production' ?
    [ravenMiddleware((window as any).raven)] :
    [createLogger(), ravenMiddleware((window as any).raven)];

  const sagaMiddleware = createSagaMiddleware( ...sagaMiddlewares );
  const devtools = process.env.NODE_ENV !== 'production' &&
      typeof window !== 'undefined' &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f;
  const store = createStore(
    combinedReducer,
    initialState,
    compose(
      applyMiddleware(sagaMiddleware, ...middlewares),
      devtools,
    )
  );

  // Sagaの起動
  sagaMiddleware.run(messagePreviewSaga);

  return store;
};
