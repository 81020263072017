import { Record } from 'immutable';
import { ITitle } from '../interfaces/TitleState';
import { INewsTopic } from '../interfaces/NewsTopicState';

export interface IPromotionState {
  titles: ITitle[];
  newsTopics: INewsTopic[];
  selectedTitleId: number;
  selectedNewsTopicId: number;
  canSubmit: boolean;
}

const defaultValue: IPromotionState = {
  titles: [],
  newsTopics: [],
  selectedTitleId: 0,
  selectedNewsTopicId: 0,
  canSubmit: true,
};

export default class PromotionState extends Record(defaultValue) {
  public readonly titles: ITitle[];
  public readonly newsTopics: INewsTopic[];
}
