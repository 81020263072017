import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import ravenMiddleware from 'redux-raven-middleware';
import createSagaMiddleware from 'redux-saga';
import distributionEditorReducer, { distributionEditorSaga } from '../ducks/DistributionEditor';
import DeserializeService from '../infrastructure/DeserializeService';
import DistributionEditorState from '../models/DistributionEditorState';
import Data from '../models/DistributionFile/Data';
import { IDistributionEditorProps } from '../startup/DistributionEditorApp';

export interface IDistributionEditorStore {
  distributionEditor: DistributionEditorState;
}

export default (props: IDistributionEditorProps, railsContext: RailsContext) => {
  const { permittedActions, totalPages, page, selected, i18nPlatforms } = props;
  const file = DeserializeService.deserializeVirtualFile(props);
  let state = new DistributionEditorState({ file, permittedActions, totalPages, page, timezone: railsContext.timezone, i18nPlatforms });

  // dataFileをいい感じに選択しておく処理
  const firstDataFile: Data = file.dataEntities.first();
  let selectedDataId: string | undefined;
  if (firstDataFile && 'id' in firstDataFile) {
    selectedDataId = firstDataFile.id;
  } else {
    selectedDataId = undefined;
  }
  if (selected !== undefined) { selectedDataId = selected.toString(); }
  if (selectedDataId) { state = state.selectData(selectedDataId); }

  const initialState = { distributionEditor: state };

  // reducer
  const combinedReducer = combineReducers({
    distributionEditor: distributionEditorReducer,
  });

  const middlewares =
    process.env.NODE_ENV === 'production' ?
    [ravenMiddleware((window as any).raven)] :
    [createLogger(), ravenMiddleware((window as any).raven)];

  const sagaMiddleware = createSagaMiddleware();
  const composedStore = compose(
    applyMiddleware(sagaMiddleware, ...middlewares),
    process.env.NODE_ENV !== 'production' &&
      typeof window !== 'undefined' &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f
  );
  const store = createStore(
    combinedReducer,
    initialState,
    composedStore
  );

  // Sagaの起動
  sagaMiddleware.run(distributionEditorSaga);

  return store;
};
