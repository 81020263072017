import { applyMiddleware, combineReducers, compose, createStore, Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import ravenMiddleware from 'redux-raven-middleware';
import reducer, { $$initialState } from '../ducks/Promotion';
import { IPromotionState } from '../models/PromotionState';

export default (props: IPromotionState) => {
  // reducer
  const combinedReducer = combineReducers({
    $$promotionStore: reducer,
  });

  const middlewares: Middleware[] = [ravenMiddleware((window as any).raven)];
  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(createLogger());
  }
  const devtools: any = process.env.NODE_ENV !== 'production' && (window as any).__REDUX_DEVTOOLS_EXTENSION__ ?
    (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f;

  const { titles, newsTopics, selectedTitleId, selectedNewsTopicId } = props;

  const store = createStore(
    combinedReducer,
    {
      $$promotionStore: $$initialState.merge({
        newsTopics: newsTopics,
        titles,
        selectedTitleId: selectedTitleId,
        selectedNewsTopicId: selectedNewsTopicId,
      }),
    },
    compose(
      applyMiddleware(...middlewares),
      devtools,
    ),
  );

  return store;
};
