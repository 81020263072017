import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import I18n from '../../lib/i18n';
import { TestDeliveryState } from '../../models';
import * as form from '../../ducks/TestDeliveryForm';
import NintendoAccountForm from './NintendoAccountForm';
import DeviceForm from './DeviceForm';
import ListNameForm from './ListNameForm';
import DataExplorer from './DataExplorer';

export class TestDeliveryForm extends React.PureComponent {
  static propTypes = {
    state: TestDeliveryState.PropTypes.isRequired,
    selectDirectory: PropTypes.func.isRequired,
    toggleVirtualFile: PropTypes.func.isRequired,
    toggleTestData: PropTypes.func.isRequired,
    unselectTestData: PropTypes.func.isRequired,
    selectDataExclusive: PropTypes.func.isRequired,
    updateName: PropTypes.func.isRequired,
  }
  dataSizeIndicator() {
    const { state } = this.props;
    const currentDataSize = state.getDeliveryDataSize();
    const dataSizeLimit = state.dataSizeLimit;
    const availableDataSize = dataSizeLimit - currentDataSize;
    const className = availableDataSize < 0 ? 'red' : '';

    // ニュースのテスト配信の場合、データサイズの設定がないのでnullを返す
    if (dataSizeLimit === null) {
      return null;
    }
    return(
      <div className={className} >
        <div className="row">
          <div className="col-md-3 text-right"><strong>{I18n.t('data_lists.form.current_data_size')}</strong></div>
          <div className="col-md-3">{`${currentDataSize.toLocaleString()} byte`}</div>
        </div>
        <div className="row">
          <div className="col-md-3 text-right"><strong>{I18n.t('data_lists.form.available_data_size')}</strong></div>
          <div className="col-md-3">{`${availableDataSize.toLocaleString()} byte`}</div>
        </div>
        <div className="row">
          <div className="col-md-3 text-right"><strong>{I18n.t('data_lists.form.maximum_data_size')}</strong></div>
          <div className="col-md-3">{`${dataSizeLimit.toLocaleString()} byte`}</div>
        </div>
      </div>
    );
  }

  submitButton() {
    const buttonText = I18n.t('data_lists.form.save_and_notify');
    const submitButton = (
      <input
        type="submit"
        value={buttonText}
        className="btn btn-primary"
        data-confirm={I18n.t('data_lists.form.confirm')}
      />
    );
    const dummySubmit = (
      <div>
        <div className="red">{I18n.t('data_lists.form.invalid')}</div>
        <button disabled className="btn btn-primary">
          {buttonText}
        </button>
      </div>
    );
    return this.props.state.isValid() ? submitButton : dummySubmit;
  }
  render() {
    const {
      state,
      toggleVirtualFile,
      toggleTestData,
      unselectTestData,
      selectDataExclusive,
      selectDirectory,
      updateName,
      i18nPlatforms,
    } = this.props;
    return (
      <div>
        { this.dataSizeIndicator() }
        <div className="form-inputs">
          <DataExplorer
            directories={state.directories}
            selectDirectory={selectDirectory}
            toggleVirtualFile={toggleVirtualFile}
            toggleTestData={toggleTestData}
            unselectTestData={unselectTestData}
            selectDataExclusive={selectDataExclusive}
            canReadPlatforms={state.canReadPlatforms}
            i18nPlatforms={state.i18nPlatforms}
          />
          <div className="testDataCheck clearfix clear">
            <ListNameForm name={state.name} errorMessage={state.errors.name} updateName={updateName} />
            <NintendoAccountForm nintendoAccounts={state.nintendoAccounts} />
            <DeviceForm devices={state.devices} />
          </div>
        </div>
        <div className="form-action">
          <div className="button_column">
            <div className="button_left_area">
              {this.submitButton()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { state: state.$$formStore };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(form.actions, dispatch),
  };
}

export const TestDeliveryFormContainer = connect(mapStateToProps, mapDispatchToProps)(TestDeliveryForm);
