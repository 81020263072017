import * as React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../ducks/MessageForm';
import { getDispatch } from '../../../../lib/dispatchExporter';
import I18n from '../../../../lib/i18n';
import MovieList from '../../../../models/Movie';
import { IRootState } from '../../../../store/MessageForm';

function mapStateToProps(state: IRootState) {
  return {
    nemoEnv: state.$$formStore.nemoEnv,
    timezone: state.$$formStore.timezone,
  };
}

export interface IMovieInputFormProps {
  updateKey: string[];
  movieIndex: number;
  formName: string;
  movie: MovieList;
}

type Props = IMovieInputFormProps & ReturnType<typeof mapStateToProps>;

class InputForm extends React.PureComponent<Props> {
  private dispatch = getDispatch();

  public componentWillMount() {
    this.fetchMovieImageAndSchedule();
  }

  public componentDidUpdate() {
    if (this.props.movie.image === undefined) {
      this.fetchMovieImageAndSchedule();
    }
  }

  public fetchMovieImageAndSchedule() {
    const { updateKey: prefix, movieIndex: index, movie } = this.props;
    const digest = movie.digest();
    if (digest === undefined) { return; }
    this.dispatch(actions.fetchMovieImage({ prefix, index, digest }));
    this.dispatch(actions.fetchMovieSchedule({ prefix, index, digest }));
  }

  public handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { movieIndex: index, updateKey: key } = this.props;
    this.dispatch(actions.deleteMovie({ key, index }));
  }

  public render() {
    const { movie, movieIndex, formName, nemoEnv, timezone } = this.props;
    const { _destroy: destroy } = movie;
    const lengths: { [s: string]: string } = {};
    Object.keys(movie.validators).forEach(key => {
      const length = movie[key] ? movie[key].length : 0;
      lengths[key] = `${length}/${movie.validators[key].maximum}`;
    });

    return (
      <div className="card card-block" style={{ display: destroy ? 'none' : '' }}>
        <div className="card-block main-movie-container">
          <div>
            <img src={movie.image} className="card-img-top" role="presentation" />
          </div>
          <div className="main-movie-schedules">
            <dl>
              <dt>{I18n.t('activerecord.attributes.movie.start_datetime')}</dt>
              <dd>{movie.humanStartDatetime(timezone) || ' '}</dd>
              <dt>{I18n.t('activerecord.attributes.movie.end_datetime')}</dt>
              <dd>{movie.humanEndDatetime(timezone) || ' '}</dd>
            </dl>
            <div className="main-movie-ncms-link">
              <a href={`${(window as any).Constants.ncmsVideoUrl}?id=${movie.ncms_video_id}`} target="_blank" rel="noreferrer">
                {I18n.t('message_details.form.view_on_ncms')}
              </a>
            </div>
          </div>
        </div>
        <pre className="card-text"><code>{movie.digest()}</code></pre>
        <div>
          <input name={`${formName}[_destroy]`} type="hidden" value={destroy ? '1' : '0'} />
          <input name={`${formName}[start_datetime]`} type="hidden" value={movie.humanStartDatetime(timezone)} />
          <input name={`${formName}[end_datetime]`} type="hidden" value={movie.humanEndDatetime(timezone)} />
          <input name={`${formName}[ncms_video_id]`} type="hidden" value={movie.ncms_video_id || ''} />
          <input name={`${formName}[id]`} type="hidden" value={movie.id || ''} />
          <input name={`${formName}[url]`} type="hidden" value={movie.convertUrl(nemoEnv)} />
          <input name={`${formName}[nintendo_switch_2_url]`} type="hidden" value={movie.convertNintendoSwitch2Url(nemoEnv, true)} />
          <input name={`${formName}[order]`} type="hidden" value={movieIndex} />
          <div className="btn btn-danger" onClick={this.handleClick}>
            {I18n.t('common.action.destroy')}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(InputForm);
