import { OrderedMap } from 'immutable';
import Attachment from '../models/Attachment';
import Data from '../models/DistributionFile/Data';
import VirtualFile from '../models/DistributionFile/VirtualFile';
import { IDataFile, IVirtualFile } from '../startup/DistributionEditorApp';

// JSON化されたnestedなオブジェクトをImmutableなモデル構造へデシリアライズします
// サーバからのレスポンス、初期データの変換に用います
export default class DeserializeService {
  public static createKeyValuePair(data: Data): [string, Data] {
    return [data.id, data];
  }
  public static deserializeDatafile(object: IDataFile): Data {
    const {
      id,
      publishing_time,
      expiration_time,
      summary,
      binary,
      archive,
      available_actions,
      ...others
    } = object;
    const value = {
      id: id.toString(),
      publishingTime: publishing_time,
      expirationTime: expiration_time || undefined,
      binary: new Attachment(binary),
      archiveId: archive.id,
      actions: available_actions,
      summary: summary || '', // summaryがnullでも表示できるように
      ...others,
    };
    return new Data(value);
  }
  public static deserializeVirtualFile(object: IVirtualFile): VirtualFile {
    const { id, filename, platforms, note } = object;
    const dataEntities = OrderedMap(object.datafiles.map(this.deserializeDatafile).map(this.createKeyValuePair));
    const value = {
      directoryId: object.directory_id,
      warnBlankSchedule: object.warn_blank_schedule,
      canChangeFilename: object.can_change_filename,
      canDestroy: object.can_destroy,
      canEditVirtualFile: object.can_edit_virtual_file,
      canReadPlatforms: object.can_read_platforms,
      dataEntities,
      id,
      filename,
      platforms,
      note,
    };
    return new VirtualFile(value);
  }
}
