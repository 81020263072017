import I18n from './_i18n'
I18n.translations || (I18n.translations = {});
I18n.translations["de"] = I18n.extend((I18n.translations["de"] || {}), {
  "nx": {
    "channel_discription": {
      "expiration_announcement": "Dieser Kanal wurde am %{day}.%{month}.%{year}. (UTC) eingestellt. Du kannst jederzeit manuell festlegen, dem Kanal nicht mehr zu folgen.",
      "expiration_pre_announcement": "Dieser Kanal wird am %{day}.%{month}.%{year}. (UTC) eingestellt. Ab diesem Datum kannst du keine Artikel dieses Kanals mehr ansehen. Du kannst jederzeit manuell festlegen, dem Kanal nicht mehr zu folgen."
    },
    "default_link_text": {
      "browser": "",
      "eshop": {
        "aoc_list": "DLC jetzt ansehen",
        "consumption": "",
        "membership_service": "",
        "membership_top": "Nintendo Switch Online-Seite im eShop aufrufen",
        "preticket_detail": "Besuche den Nintendo eShop ",
        "product_detail": "Besuche den Nintendo eShop ",
        "product_list": "",
        "sale": "Aktuelle Angebote ansehen ",
        "subscriptions": "",
        "top": "Besuche den Nintendo eShop "
      },
      "game": "Software starten"
    },
    "description_of_contents_excuse": "",
    "description_of_distribution_area": {
      "au_nz": "Diese Neuigkeiten richten sich an australische und neuseeländische Nintendo-Accounts.",
      "eur": "Diese Neuigkeiten richten sich an europäische Nintendo-Accounts.",
      "eur_au": "Diese Neuigkeiten richten sich an europäische und australische Nintendo-Accounts.",
      "eur_au_nz": "Diese Neuigkeiten richten sich an europäische, australische und neuseeländische Nintendo-Accounts.",
      "eur_nz": "Diese Neuigkeiten richten sich an europäische und neuseeländische Nintendo-Accounts."
    },
    "description_of_nso_precaution": "Während des Spielens online wird eine Internetverbindung benötigt. Um Online-Services nutzen zu können, musst du einen Nintendo-Account erstellen und den Vertrag zum Nintendo-Account akzeptieren. Die Nintendo-Account-Datenschutzrichtlinie findet Anwendung. Manche Online-Services sind möglicherweise nicht in allen Ländern verfügbar. Das Online-Spiel erfordert die Mitgliedschaft bei einem kostenpflichtigen Service: Nintendo Switch Online. Weitere Informationen zu kostenpflichtigen Online-Diensten (URL: https://www.nintendo.de/Nintendo-Switch/Nintendo-Switch-Online/Nintendo-Switch-Online-1183143.html).",
    "product": {
      "aoc": "Herunterladbare Inhalte",
      "bundle": "Set",
      "new": "Neu",
      "pre_order": {
        "above_v8": "Vorbestellung",
        "below_v7": "Vorbestellung"
      },
      "pre_purchase": "Vorbestellung",
      "title": "Software",
      "upgrade_pass": "UpgradePass"
    },
    "suggest_product_text": {
      "demo": "DEMO"
    }
  }
});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
  "ab_tests": {
    "edit": {
      "title": "Edit AB Test News"
    },
    "form": {
      "confirm": "Because the news being delivered is linked, it may be applied to the user immediately. When linking the news being delivered with the serial number, it cannot be changed in the future. Do you want to continue?",
      "no_send": "Do Not Send News to This Device Serial Number",
      "serial": "Delivery Target Device’s Serial Number: %{number}",
      "serial_description": "The serial number to be delivered is the 13th digit from the left of the Switch serial number."
    },
    "index": {
      "title": "List of AB Test News"
    },
    "new": {
      "title": "Create AB Test News"
    },
    "show": {
      "title": "AB Test News Details"
    }
  },
  "active_flag": {
    "label_platform": {
      "nx": "NX",
      "ounce": "Ounce"
    },
    "platforms": {
      "nx": "NX"
    }
  },
  "activerecord": {
    "attributes": {
      "ab_test": {
        "id": "AB Test ID",
        "messages": "Associated News",
        "serials": "Sort by Serial",
        "summary": "Summary"
      },
      "activity_log": {
        "action": "Action",
        "actions": {
          "approve_censor": "Approve Nintendo Review",
          "approve_publishing": "Approve Delivery",
          "approve_review": "Approve Pre-Delivery Review",
          "approve_review_and_request_censor": "Approve Pre-Delivery Review and Request Nintendo Review",
          "cancel_censor": "Cancel Nintendo Review",
          "cancel_review": "Cancel Pre-Delivery Review",
          "create": "Create New",
          "destroy": "Delete",
          "duplicate": "Duplicate",
          "expire": "Stop Delivery",
          "modify": "Modify Content",
          "publish": "Start Delivery",
          "reject_censor": "Reject Nintendo Review",
          "reject_review": "Reject Pre-Delivery Review",
          "request_censor": "Request Nintendo Review",
          "request_review": "Request Pre-Delivery Review",
          "unapprove": "Cancel Approval and Modify Content",
          "update": "Update"
        },
        "channel": "Channel",
        "controller": "Controller",
        "created_at": "Operation Date and Time",
        "diff": "Difference",
        "general_news": "News",
        "link": "Link",
        "resource": "Resource",
        "resource_name": "Resource Name",
        "resource_type": "Resource Type",
        "title": "Application",
        "user": "User"
      },
      "aging": {
        "base_deliverable_id": "Select Source Data",
        "end_time": "End Time",
        "interval": "Delivery Interval (Seconds)",
        "start_time": "Start Time"
      },
      "announcement": {
        "body": "Body",
        "body_en": "Body (English)",
        "body_ja": "Body (Japanese)",
        "expired": "Expired?",
        "id": "Notification ID",
        "publishing_date": "Publishing Date",
        "subject": "Subject",
        "subject_en": "Subject (English)",
        "subject_ja": "Subject (Japanese)"
      },
      "api_account": {
        "created_at": "Date Created",
        "name": "Account Name"
      },
      "api_key": {
        "client_id": "Client ID",
        "client_secret": "Client Secret",
        "created_at": "Date Created",
        "used_at": "Last Used"
      },
      "bcat_setting": {
        "contents_line_count_limit": "Maximum number of Targeted News recipients (The maximum number of settings that can be set: 48 million)",
        "global_semantics_version": "Global Semantics Version",
        "ha_wait_range_max": "Maximum HA Random Wait (seconds)",
        "ha_wait_range_min": "Minimum HA Random Wait (seconds)",
        "latest_semantics_version": "Latest Semantics Version",
        "main_image_required_form_level": "Main Image Required Form Level",
        "random_wait": "Random Wait (seconds)",
        "rate": "Rate (req/seconds)"
      },
      "channel_option": {
        "enable_censor": "Run a Nintendo Review",
        "enable_review": "Run a Pre-Delivery Review",
        "enable_tencent_censor": "Run a Tencent Review",
        "list_image_required": "Require (for debugging) a news list image.",
        "news_form_level": "Browsing Level for the News Form",
        "news_passphrase_type": "Passphrase (for SDK Debugging) Used to Encrypt News",
        "required_application_version": "Required Application Version",
        "semantics_version": "News Semantics Version"
      },
      "company": {
        "name": "Company Name"
      },
      "country": {
        "region": "Delivery Region"
      },
      "country_group": {
        "countries": "Affiliated Country",
        "name": "Name",
        "region": "Delivery Region"
      },
      "data_list": {
        "datafiles": "File",
        "devices": "Destination Device",
        "id": "ID",
        "messages": "News",
        "name": "List Name",
        "nintendo_accounts": "Destination Account",
        "updated_at": "Date and Time Updated"
      },
      "data_topic": {
        "is_china": "For China",
        "na_required": "NA Required Flag",
        "npns_topic_id": "NPNS Topic ID",
        "pseudo": "Pseudo-Topic Flag",
        "service_status": "Service Status"
      },
      "datafile": {
        "binary": "Binary",
        "directory": "Directory",
        "expiration_time": "Delivery End Date and Time",
        "filename": "Filename",
        "id": "ID",
        "note": "Notes",
        "publishing_time": "Delivery Start Date and Time",
        "status": "Status",
        "summary": "Summary"
      },
      "device": {
        "list": "Latest Test Delivery List",
        "name": "Name",
        "note": "Notes",
        "serial": "Serial Number"
      },
      "directory": {
        "by_country_group": "Deliver by Country Group",
        "by_location": "Deliver by Location",
        "directory_type": "Directory Type",
        "id": "ID",
        "mode": "Mode",
        "name": "Name",
        "note": "Notes"
      },
      "general_news": {
        "created_at": "Created At",
        "name": "Name",
        "note": "Notes"
      },
      "group": {
        "name": "Group Name"
      },
      "ip_restriction": {
        "ip_addr": "IP Address",
        "note": "Notes"
      },
      "jedi_record": {
        "aggregated_date": "Aggregated Date",
        "average_scroll_percent": "Average Scroll Percent",
        "average_view_time": "Average View Time",
        "bad": "Oh",
        "download": "Download Device Count",
        "feedback": "Great Rate",
        "good": "Great!",
        "movie_view": "Video View Device Count",
        "notice": "Note: The cumulative value as of the aggregation date is displayed.",
        "read": "Read Device Count",
        "shopjump": "eShop Jump Count"
      },
      "job": {
        "arguments": "Arguments",
        "created_at": "Time Queued",
        "finished_at": "Finished Time",
        "id": "ID",
        "job_class": "Job Class",
        "job_id": "Job ID",
        "last_error_detail": "Error Detail",
        "progress": "Progress",
        "queur_name": "Queue",
        "status": "Status",
        "tried_count": "Number of Trials",
        "updated_at": "Last Updated"
      },
      "mail_setting": {
        "mail_enabled": "Receive other notifications."
      },
      "main_content": {
        "body": "Body",
        "main_image": "Main Image",
        "main_movie": "Main Video(eShop Video Access ID)"
      },
      "message": {
        "age_limit": "Software Rating",
        "base": "News",
        "bashotorya": "Placeholder Flag",
        "caption": "Overlap Display of News Titles in List Image",
        "caption_items": {
          "display": "Show",
          "hide": "Hide"
        },
        "countries": "Delivery Countries",
        "country_group_id": "Country Group",
        "decoration_type": "Special Feature",
        "decoration_type_item": {
          "disabled": "Special Feature Disabled",
          "simple": "Special Feature Enabled"
        },
        "default_language": "Default Language",
        "default_language_items": {
          "not_specified": "No Default Language"
        },
        "deletion_priority": "Deletion Priority",
        "discovery": "Discovery",
        "discovery_setting": {
          "disabled": "Disable",
          "enabled": "Enable"
        },
        "display_limit": "Delivered News Display Term",
        "display_type": "Display Format",
        "essential_pickup_disabled": "Assured Feature Disabled",
        "essential_pickup_flag": "Assured Feature",
        "essential_pickup_limit": "Assured Feature Expiration Date and Time",
        "expiration_time": "Delivery End Date and Time",
        "featured_products": "Rankings and Featured Products",
        "fixed_pickup_disabled": "Fixed Feature Disabled",
        "fixed_pickup_enabled": "Fixed Feature Enabled",
        "fixed_pickup_flag": "Fixed Feature",
        "format_version": "Format Version",
        "id": "News ID",
        "message_discovery": "Discovery",
        "movie_delivery": "Movie Delivery",
        "no_photography": "Screenshot Restrictions",
        "no_photography_items": {
          "allow": "Enable captures",
          "prohibit": "Prohibit captures"
        },
        "note": "Notes",
        "online_archives_and_movie_expired": "チャンネル詳細に表示中／プロモーション中、かつ配信期限切れ動画を含む記事を探す",
        "pickup_disabled": "Feature Disabled",
        "pickup_flag": "Feature",
        "pickup_limit": "Feature Expiration Date and Time",
        "priority": "Featuring Priority",
        "publishing_time": "Delivery Start Date and Time",
        "related_products": "Related Products",
        "related_topics": "Related Channels",
        "searchable_application_ids": "Application ID for a Related Title",
        "semantics_version": "Semantics Version",
        "status": "Status",
        "summary": "Summary",
        "surprise": "Surprise Notification Flag",
        "total_number_of_countries": "Total %{size} Countries",
        "updated_at": "Last Updated",
        "user": "Assignee",
        "visible_in_online_archives": "チャンネル詳細に表示中／プロモーション中"
      },
      "message_detail": {
        "allow_domains": "Domain Whitelist",
        "application_arg": "Parameters for Starting Applications From News",
        "auto_play": "Video playback on the list view",
        "auto_play_items": {
          "not_play": "Don't play",
          "play": "Play"
        },
        "body": "Body",
        "contents_descriptors": "Content Descriptor",
        "decoration_text": "Special Feature Text",
        "featured_list_name": "Rankings and Featured List Name",
        "footer_text": "Footer Text",
        "interactive_elements": "Interactive Elements",
        "language": "Language",
        "list_image": "List Image",
        "list_movie": "List Video(eShop Video Access ID)",
        "list_movie_relation": {
          "auto_play": "Video playback on the list view",
          "start_seconds": "Playback start position of listed videos (in seconds)"
        },
        "list_name": "List Name",
        "main_image": "Main Image",
        "main_movie": "Video URL",
        "more_application_arg": "Parameters to Pass When Starting the Application",
        "more_application_ids": "Application ID to Start",
        "more_application_text": "Link Button Text",
        "more_applications": "Application to Start",
        "more_link_text": "Link Button Text",
        "more_link_url": "URL",
        "more_nso_relative_url": "Page to show with the Nintendo Switch Online system application",
        "more_nso_text": "Link button text",
        "more_shop_query": "Page to display in Nintendo eShop",
        "more_shop_text": "Link Button Text",
        "more_system_applet_text": "Link Button Text",
        "more_system_applet_type": "Type of System Applet to Start",
        "more_type": "Link Button Type",
        "movie_name": "Video Name",
        "ratings": "Rating",
        "ratings_items": {
          "no_rating": "No Rating"
        },
        "related_movies": "Related Videos",
        "related_movies_id": "Related Video(eShop Video Access ID)",
        "search_type": "Search Results Page to Display",
        "show_contents_excuse": "Note About News Content in Footer Text",
        "show_contents_excuse_items": {
          "include": "Include",
          "not_include": "Do Not Include"
        },
        "show_distribution_area": "Description of Delivery Region in Footer Text",
        "show_distribution_area_items": {
          "include": "Include",
          "not_include": "Do Not Include"
        },
        "show_nso_precaution": "Nintendo Switch Online Membership Requirement Information in Footer Text",
        "show_nso_precaution_items": {
          "include": "Include",
          "not_include": "Do Not Include"
        },
        "start_seconds": "Playback start position of listed videos (in seconds)",
        "subject": "Subject"
      },
      "message_serial": {
        "published": "Serial Number Delivery Status",
        "serial": "Delivery Target Device’s Serial Number"
      },
      "movie": {
        "end_datetime": "Delivery End Date and Time",
        "name": "Product Name",
        "start_datetime": "Delivery Start Date and Time"
      },
      "news_description": {
        "description": "Channel Description",
        "language": "Language",
        "name": "Channel Name",
        "publisher_name": "Published By",
        "search_string": "Topic Search String"
      },
      "news_topic": {
        "channel_start_time": "Channel Start Time",
        "countries": "News Delivery Countries",
        "default_language": "Default Language",
        "expiration_time": "Release End Date and Time",
        "is_china": "For China",
        "languages": "News Delivery Languages",
        "na_required": "NA Required Flag",
        "news_icon_image": "Channel Icon",
        "news_icon_images": "Channel Icon",
        "news_topic_id": "News Topic ID",
        "npns_topic_id": "NPNS Topic ID",
        "official": "Nintendo Official News",
        "pseudo": "Pseudo-Topic Flag",
        "public": "Displays in the Channel List",
        "publishing_time": "Release Start Date and Time",
        "service_status": "Service Status"
      },
      "nintendo_account": {
        "name": "Nickname"
      },
      "one2one": {
        "id": "ID",
        "message": "News",
        "note": "Notes",
        "summary": "Summary",
        "target_list": "Recipient NSA List (Not an NA List)"
      },
      "parcel": {
        "contents_dimensions": "Image Size",
        "contents_file_name": "Filename",
        "contents_file_size": "File Size",
        "contents_fingerprint": "MD5 Digest",
        "contents_line_count": "Number of Lines"
      },
      "product": {
        "aocs": "DLC",
        "available_countries": "Available Countries and Languages",
        "consumables": "Consumable Item",
        "country": "Country of Sale",
        "have": "Yes",
        "image": "Product Image",
        "initial_code": "Initial Code",
        "name": "Product Name",
        "names": {
          "AOC": "DLC",
          "BUNDLE": "Bundle",
          "CONSUMABLE": "Consumable Item",
          "PRETICKET": "Nintendo Switch Game Vouchers",
          "TICKET": "Service Ticket",
          "TITLE": "Software",
          "TRIAL": "Demo"
        },
        "not": "N/A",
        "oldest_release_at": "Release Date",
        "platforms": "Linkable Platform",
        "product_type": "Product Type",
        "tickets": "Service Item",
        "unsearchable_countries": "Unsearchable Countries",
        "updated_at": "Last Updated"
      },
      "promotion": {
        "news_topic_id": "News Topic ID"
      },
      "rating_system": {
        "name_items": {
          "CERO": "CERO",
          "CGSRR": "CGSRR",
          "CLASSIND": "CLASSIND",
          "COB": "COB",
          "ESRB": "ESRB",
          "GRB": "GRB",
          "HK": "HK",
          "IARC_COB": "IARC_COB",
          "IARC_GENERIC": "IARC_GENERIC",
          "IARC_OFLC": "IARC_OFLC",
          "IARC_PEGI": "IARC_PEGI",
          "IARC_PEGI_POR": "IARC_PEGI_POR",
          "IARC_USK": "IARC_USK",
          "NONE": "No Rating",
          "OFLC": "OFLC",
          "PEGI": "PEGI",
          "PEGI_POR": "PEGI_POR",
          "RAR": "RAR",
          "SMEC": "SMEC",
          "USK": "USK"
        }
      },
      "role": {
        "name": "Role Name",
        "service_roles": {
          "debugger": {
            "desc": "Users with the debugger role can access functionality specific to debugging.",
            "name": "Debugger"
          },
          "ncms": {
            "desc": "Users with the NCMS role can call the BCAT functions from NCMS.",
            "name": "NCMS"
          },
          "nintendo_data_observer": {
            "desc": "Users with the Nintendo data observer role can view all published data.",
            "name": "Nintendo Data Observer"
          },
          "nintendo_licensee_news_observer": {
            "desc": "Users with the Nintendo licensee news observer role can view all news items for the licensee.",
            "name": "Nintendo Licensee News Observer"
          },
          "nintendo_news_developer": {
            "desc": "Users with the Nintendo news developer role can view all published news.",
            "name": "Nintendo News Developer"
          },
          "nintendo_news_observer": {
            "desc": "Users with the Nintendo news observer role can view all news items.",
            "name": "Nintendo News Observer"
          },
          "nintendo_news_reviewer": {
            "desc": "Users with the Nintendo news reviewer role can check the news items of licensees and handle review requests.",
            "name": "Nintendo News Reviewer"
          },
          "nintendo_official_news_operator": {
            "desc": "Users with the Nintendo official news operator role can send official notices from Nintendo.",
            "name": "Nintendo Official News Operator"
          },
          "nintendo_subscribes_operator": {
            "desc": "Users with the Nintendo subscription operator role can add subscription titles to the channel.",
            "name": "Nintendo Subscription Operator"
          },
          "server_admin": {
            "desc": "BCAT Server Developer",
            "name": "Server Admin"
          },
          "service_admin": {
            "desc": "An overall BCAT service administrator can access all resources.",
            "name": "Service Admin"
          },
          "tencent_data_reviewer": {
            "desc": "Users with the Tencent data reviewer role can view data in specific regions and approve Tencent reviews.",
            "name": "Tencent Data Reviewer"
          },
          "tencent_news_reviewer": {
            "desc": "Users with the Tencent News reviewer role can view News in specific regions and approve Tencent reviews.",
            "name": "Tencent News Reviewer"
          }
        },
        "title_roles": {
          "editor": {
            "desc": "Users with the editor role can create and modify delivery settings.",
            "name": "Editor"
          },
          "manager": {
            "desc": "Users with the manager role can manage permissions.",
            "name": "Manager"
          },
          "none": {
            "desc": "Users With No Permissions",
            "name": "None"
          },
          "reviewer": {
            "desc": "Users with the reviewer role can approve pre-delivery reviews.",
            "name": "Reviewer"
          },
          "viewer": {
            "desc": "Users with the viewer role can view delivery content.",
            "name": "Viewer"
          }
        }
      },
      "role_request": {
        "approved_at": "Date of Determination",
        "approver": "Determined By",
        "channel": "Channel",
        "company": "Company",
        "created_at": "Requested Date",
        "role": "Requested Role",
        "status": "Status",
        "user": "User"
      },
      "target_list": {
        "id": "ID",
        "note": "Notes",
        "nsa_list": "Recipient NSA List (Not an NA List)"
      },
      "title": {
        "application_id": "Application ID",
        "data_size_limit": "Data Delivery Cache Storage Size",
        "image": "Image for Displaying Management Tools",
        "initial_code": "Initial Code",
        "name": "Application Name",
        "note": "Notes",
        "passphrase": "Passphrase",
        "title_role": "Application Roles"
      },
      "topic_country": {
        "country": "Country",
        "first_posted_at": "First Posted",
        "last_posted_at": "Last Posted",
        "publishing_time": "Release Start Date and Time"
      },
      "topic_stat": {
        "subscriber": "Subscriber"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "e-mail": "Email Address",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locale": "Language Locale",
        "locked_at": "Locked at",
        "loginid": "NDID",
        "name": "User Name",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "permission_synced_at": "Final Permission Synchronization Time",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "sync_status": "Permission Synchronization Status",
        "timezone": "Time Zone",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      },
      "virtual_file": {
        "datafiles": "Delivery Data",
        "directory": "Directory",
        "filename": "Filename",
        "note": "Notes",
        "summary": "Summary",
        "warn_blank_schedule": "Warn About Data Delivery Blank Schedule"
      }
    },
    "error": null,
    "errors": {
      "ab_test": {
        "has_not_messages": "The specified news item is not tied to this AB test. (News ID: %{news_ids})"
      },
      "api_account": {
        "invalid_titles": "Some API key members do not have the manager role for this title. Please grant the Manager role first."
      },
      "archive": {
        "message_pack_size": "The maximum size of the delivery message data is %{expected} bytes. The uploaded size was %{actual} bytes."
      },
      "country_group": {
        "invalid_countries": "Invalid countries are included.",
        "invalid_exclusion_of_countries": "An attempt was made to affiliate %{countries} with multiple country groups.",
        "multi_regions_are_detected": "You cannot specify combinations that span multiple delivery regions for a country group used for news."
      },
      "data_list": {
        "device_has_platform_mismatch": "TODO:（翻訳）配信リストと配信先本体のプラットフォームが一致しません。",
        "exceeded_test_delivery_limit": "The delivery list may have up to %{limit} data entries. This list has %{actual} entries.",
        "include_invalid_nintendo_accounts": "Invalid accounts are included (%{names})."
      },
      "datafile": {
        "cannot_destroy": "Cannot be deleted. A record for past deliveries exists.",
        "cannot_expand_schedule": "Cannot extend the schedule.",
        "country_group_id": "Country Group ID",
        "delivery_type": "Delivery Type",
        "expiration_time_must_be_future_than_publishing_time": "Set a delivery end date and time that is later than the delivery start date and time.",
        "message_directory_file_number": "A directory can contain up to %{limit} files.",
        "message_directory_size_limit": "A directory can contain files with a total size of 60 MiB or less.",
        "message_file_number": "File limit exceeded. You can deliver up to 2,000 files.",
        "message_total_size_limit": "The total size of delivered files must be %{limit} MiB or less.",
        "publishing_time_is_not_unique": "A file with the same delivery start date and time exists.",
        "publishing_time_is_overlapped": "The delivery start date and time overlap with the existing delivery period.",
        "too_future_expiration_time": "Set the delivery end date and time to a maximum of 6 months when the deletion priority was set to a value lower than 100.",
        "trailing_dot": "A filename cannot end with a period (.)."
      },
      "device": {
        "serial_check_digit_is_invalid": "The check digits do not match. Make sure that the serial number was entered correctly.",
        "serial_format_is_invalid": "Incorrect serial number format. Make sure that the serial number was entered correctly."
      },
      "directory": {
        "cannot_destroy": "You cannot delete a directory that includes delivered data.",
        "cannot_update_unless_empty": "You cannot modify this attribute when there is data within the directory.",
        "directory_number_exceeded": "Exceeded the directory number limit."
      },
      "general_news": {
        "message_has_platform_mismatch": "TODO: （翻訳） チャンネルに紐づくニュースに%{platforms}以外のプラットフォームが指定されているため変更できません。",
        "platforms": "Platforms are required.",
        "promotion_has_platform_mismatch": "TODO: （翻訳） チャンネルに紐づくプロモーションに%{platforms}以外のプラットフォームが指定されているため変更できません。",
        "subscribe_has_platform_mismatch": "TODO: （翻訳） チャンネルに紐づく自動購読設定に%{platforms}以外のプラットフォームが指定されているため変更できません。",
        "test_device_has_platform_mismatch": "TODO: （翻訳） チャンネルに紐づくテスト配信の配信先本体に%{platforms}以外のプラットフォームが指定されているため変更できません。"
      },
      "invalid_title_and_news_platform": "自動購読設定するアプリケーションのプラットフォームに、ニュースチャンネルのプラットフォームが対応していません。",
      "ip_restriction": {
        "invalid_ip_address": "Invalid IP address.",
        "invalid_ipv4_format": "Specify IPv4 format.",
        "not_allowed_fullopen": "0.0.0.0/0 cannot be specified.",
        "not_allowed_private_ip": "Private IP addresses cannot be specified."
      },
      "list_movie_relation": {
        "start_seconds": {
          "input_integer": "Please enter an integer for the \"Playback start position of listed videos (in seconds)\".",
          "length": "The maximum length of the Playback start position of listed videos is 9 digits."
        }
      },
      "log_request": {
        "maximum_request_period": "Specify a logging period within 31 days.",
        "start_time_larger_topic_created_at": "Specify a logging start time after %{time}.",
        "start_time_less_than_end_time": "Specify a logging start time that is earlier than the logging end time.",
        "two_hours_before": "Specify a logging end time that is earlier than two hours before the current time."
      },
      "lushan": {
        "linked": "Cannot be added because it is already associated by %{name}."
      },
      "main_content": {
        "too_many_headers": "A maximum of %{maximum} headers can be specified for one body.",
        "total_body_length": "The maximum length of the News body is %{expected_max} characters. The uploaded News body length is %{actual} characters.",
        "total_main_image_height": "The maximum image height is %{expected_max} pixels. The uploaded image height is %{actual} pixels.",
        "total_main_image_size": "The maximum total image size is %{expected_max} bytes. The total size of the uploaded images was %{actual} bytes."
      },
      "message": {
        "at_least_publishing_at": "To deliver after the product's release date and time, specify a news delivery start date and time later than %{release_at}.",
        "cant_publish_before_channel_publishing_time": "The release start date and time must be after the channel delivery start date and time of %{topic_publishing_time}.",
        "cant_publish_before_related_channel_publishing_time": "The delivery start date and time must be after the related channel delivery start date and time of %{topic_publishing_time}.",
        "display_limit_must_be_future_than_publishing_time": "The display expiration date and time must be after the delivery start date and time.",
        "display_limit_must_be_past_than_end_date_of_video": "The display expiration date and time must be earlier than the delivery end date and time of the video. Specify a display expiration date and time that is before %{end_datetime}.",
        "essential_pickup_limit_must_be_future_than_publishing_time": "The assured feature expiration date and time must be after the delivery start date and time.",
        "essential_pickup_limit_must_be_past_than_display_limit": "The assured feature expiration date and time must be earlier than the display expiration date and time.",
        "essential_pickup_limit_must_be_past_than_pickup_limit": "The assured feature expiration date and time must be earlier than the feature expiration date and time.",
        "essential_pickup_limit_must_be_up_to_2_week_from_publishing_time": "The assured feature expiration date and time must be no more than two weeks after the delivery start date and time.",
        "essential_priority_is_invalid": "Invalid Priority",
        "featured_products": {
          "featured_comments": {
            "invalid_characters": "'%{invalid_characters}' cannot be displayed on Switch."
          },
          "invalid_characters": "'%{invalid_characters}' cannot be displayed on Switch.",
          "invalid_platforms": "TODO （翻訳）リスト/ランキング商品%{ns_uid}はプラットフォーム「%{platforms}」に非対応です。",
          "invalid_products": "There are no rankings and featured products (%{ns_uid}) in %{lang}.",
          "invalid_products_for_country": "It is possible that the country of the product has changed or sales have been suspended. (%{ns_uid}).",
          "too_many_products": "Only a maximum of %{maximum} can be linked."
        },
        "featured_products_have_errors": "There are invalid parameters in Rankings and Featured Product settings.",
        "include_invalid_application_id": "An application ID in an invalid format is included.",
        "include_out_of_application_id_range": "An out-of-scope application ID value is included.",
        "include_same_application_ids": "There are multiple instances of the same value.",
        "invalid_china": {
          "country": "This topic cannot be delivered in regions other than China.",
          "topic": "This is not a topic for China."
        },
        "invalid_default_language": "No news for the language specified as the default language.",
        "message_details_have_errors": "There are invalid parameters in language-specific settings.",
        "multiple_identical_languages_of_news": "You cannot include multiple instances of the same language for news.",
        "pickup_limit_must_be_future_than_publishing_time": "The feature expiration date and time must be after the delivery start date and time.",
        "platforms": "Platforms are required.",
        "platforms_set_for_the_channel": "TODO",
        "publishing_time_must_be_future_than_channel_publishing_time": "The delivery start date and time must be after the channel release start date and time (%{channel_publishing_time}).",
        "related_products": {
          "invalid_platforms": "TODO （翻訳）関連商品%{ns_uid}はプラットフォーム「%{platforms}」に非対応です。",
          "invalid_products": "There are no associated products (%{ns_uid}) for %{lang}.",
          "invalid_products_for_country": "It is possible that the country of the product has changed or sales have been suspended. (%{ns_uid})",
          "too_many_products": "A maximum of %{actual} items can be linked.",
          "unlinkable_products": "There are products (%{ns_uids}) that cannot be linked."
        },
        "related_topics": {
          "cannot_set": "Cannot be set in this news item.",
          "include_invalid": "An invalid channel is specified.",
          "include_invalid_with_message": "An invalid channel is specified. %{message}",
          "invalid_platforms": "TODO（翻訳）関連チャンネル%{npns_topic_id}はプラットフォーム「%{platforms}」に非対応です。",
          "too_many_topics": "A maximum of %{maximum_size} items can be set."
        },
        "require_at_a_least_one_message_detail": "There must be at least one language entry for news.",
        "require_default_language": "You must specify the default language.",
        "too_many_application_ids": "You can only specify up to five application IDs."
      },
      "message_detail": {
        "application_id_out_of_application_id_range": "The value for the application ID is out-of-scope.",
        "duplicate_more_application_ids": "There are duplicated Application IDs.",
        "includes_invalid_allow_domain": "A domain in an invalid format was included. (Line: %{invalid_domain_index})",
        "invalid_allow_url": "An URL in an invalid format was included.",
        "invalid_asia_news": "The channel version setting is not valid.",
        "invalid_characters": "'%{invalid_characters}‘ cannot be displayed on Switch.",
        "invalid_contents_descriptors": "Either the format is not valid or an unintended value is included.",
        "invalid_interactive_elements": "Either the format is not valid or an unintended value is included.",
        "invalid_more_application_query": "This format is not valid.",
        "invalid_more_shop_query": "Parameter settings are not valid.",
        "invalid_more_shop_query_platforms": "TODO（翻訳）商品%{ns_uid}はプラットフォーム「%{platforms}」に非対応です。",
        "invalid_movie_url": "The entered value is invalid.",
        "invalid_movie_url_domain": "The specified domain is not responding.",
        "invalid_products_for_country": "It is possible that the country of the product has changed or sales have been suspended. (%{ns_uid}).",
        "invalid_ratings": "Either the format is not valid or an unintended value is included.",
        "list_image_is_required": "A list image is required.",
        "list_image_or_list_movie_is_required": "You must set at least one of either the list image or the list video URL.",
        "main_contents_exceed_maximum_size": "Exceeded the maximum size of the main content.",
        "main_contents_have_errors": "There is an error with the body, a main image, or a video URL. For example, this error occurs when neither the main image nor the video URL are set.",
        "main_image_is_required_if_movie_url": "When specifying a video URL, a main image is required.",
        "more_application_id_must_be_16_character_hexadecimal": "The application ID for the application to start must be a 16-digit hexadecimal.",
        "more_application_ids_are_over_maximum_size": "Specify up to %{maximum_size} applications.",
        "ncms_internal_server_error": "Failed to get data from NCMS. Please try again later. If the problem persists, contact support@noa.nintendo.com.",
        "no_ratings": "Ratings are required. Specify 'No rating' for one rating system if the products mentioned in the news item have no ratings.",
        "only_one_record_if_no_rating": "When specifying 'No rating', only specify it for one rating system. Leave other rating systems empty.",
        "presence_more_application_query": "Failed to get data from Nintendo eShop. Do not specify a link, or please try again later. If the problem persists, contact support@noa.nintendo.com.",
        "ratings": {
          "no_supported_countries": "No countries support %{rating_system} in delivery targets.",
          "specify_esrb_or_classind": "Only ESRB or CLASSIND may be specified as the rating system.",
          "too_many_ratings": "You can specify up to %{maximum_size} ratings for the rating system."
        },
        "related_movies": {
          "invalid_names": "Please enter names for all of the videos or leave all of the names blank.",
          "too_many_movies": "You can specify only %{maximum} or fewer related videos."
        },
        "related_movies_have_errors": "There are errors with Related Videos.",
        "required_ratings": "Required when using content descriptors or interactive elements.",
        "too_many_contents_descriptors": "Too many specified content descriptors.",
        "too_many_interactive_elements": "Too many specified interactive elements."
      },
      "message_serial": {
        "cannot_modify_because_published": "A serial number that received news delivery even once cannot link to other news items."
      },
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        },
        "taken": "%{attribute} already exists."
      },
      "movie": {
        "duplicate_movie_url": "The eShop Video Access ID of the related video is a duplicate.",
        "limit_movie_number": "Cannot specify any more items.",
        "not_found": "The video is not found."
      },
      "news_description": {
        "cant_destroy_saved_language": "You cannot delete news details for a language that already contains news items."
      },
      "news_topic": {
        "default_language_not_found_in_news_descriptions": "The news description must be set to the same language as the default language.",
        "includes_unsupported_countries": "An unsupported country is included.",
        "includes_unsupported_languages": "An unsupported language is included.",
        "npns_topic_id_news_format": "It must be an alphanumeric string beginning with 'nx_news_'."
      },
      "nintendo_account": {
        "linked": "Cannot be added because it is already associated by %{name}."
      },
      "parcel": {
        "icon_image_width": "Set the image width to 70px or 136px. The uploaded image width is %{actual} pixels.",
        "invalid_baseline_jpeg": "Specify only baseline JPEG images for news items.",
        "invalid_data_format": "Invalid data format.",
        "invalid_image_cmyk": "CMYK images cannot be used. Please convert your image to RGB color mode and try again.",
        "invalid_target_list": "Invalid target file. See line %{invalid_index}: \"%{invalid_record}\".",
        "list_image_height": "Set the height of the image to a range of %{expected_max} pixels. The uploaded image height is %{actual} pixels.",
        "main_image_aspect_ratio": "The maximum image size is %{expected_max} pixels. The uploaded image size was (%{actual_width}:%{actual_height}) pixels.",
        "message_application_arg_size": "The maximum size of the startup parameter is %{expected} bytes. The uploaded startup parameter size was %{actual} bytes.",
        "message_contents_empty": "The number of recipients is 0.",
        "message_contents_line_count": "The maximum number of recipients is %{limit} . The number of uploaded recipients was %{actual} .",
        "message_convert_image_fomat": "Specify only baseline JPEG, progressive JPEG or PNG images.",
        "message_convert_image_width": "Set the width of the image to a range of %{expected_max} pixels. The uploaded image width is %{actual} pixels.",
        "message_datafile_size": "The maximum size of the delivery data is %{expected} MiB. The uploaded delivery data size was %{actual} bytes.",
        "message_image_aspect_ratio": "The aspect ratio of the image should be %{expected_width}:%{expected_height}. The uploaded image aspect ratio is %{actual}.",
        "message_image_format": "Specify only baseline JPEG images.",
        "message_image_height": "Set the image height to %{expected} pixels. The uploaded image height is %{actual} pixels.",
        "message_image_height_range": "Set the image height in the range from %{expected_min} pixels to %{expected_max} pixels. The uploaded image height is %{actual} pixels.",
        "message_image_size": "The maximum image size is %{expected} bytes. The uploaded image size was %{actual} bytes.",
        "message_image_width": "Set the image width to %{expected} pixels. The uploaded image width is %{actual} pixels.",
        "message_image_width_range": "Set the image width in the range from %{expected_min} pixels to %{expected_max} pixels. The uploaded image width is %{actual} pixels."
      },
      "product": {
        "limit_product_number": "Cannot specify any more items.",
        "ns_uid_format_invalid": "The NS UID format is not valid."
      },
      "title": {
        "application_id_cannot_be_set": "An application ID cannot be configured.",
        "application_id_format_invalid": "The application ID format is not valid.",
        "application_id_is_required": "An application ID is required.",
        "application_id_out_of_application_id_range": "The value for the application ID is out-of-scope.",
        "platforms": "Platforms are required."
      },
      "virtual_file": {
        "blank_time": "There are periods where data is not delivered. If this setting is intentional, either ignore this warning or set \"Warn About Data Delivery Blank Schedule\" to false.",
        "cannot_change": "Cannot be changed if there is data delivery that is already in progress or that is scheduled.",
        "existing_multi_platforms_invalid_filename": "既存の配信データに複数プラットフォーム対応のものがある場合、配信先が異なっても、そのファイルとファイル名が重複してはいけません。",
        "infinite_overlap_time": "Specify the delivery end date and time.",
        "new_multi_platforms_invalid_filename": "複数プラットフォーム対応の配信データを作成する場合、配信先が異なっても既存ファイルとファイル名が重複してはいけません。",
        "new_multi_platforms_invalid_platforms": "既存の配信データと同名の配信データを更新する場合、プラットフォームが重複してはいけません。",
        "overlap_time": "Overlapping Data Delivery Schedule",
        "platforms": "Platforms are required.",
        "virtual_file_platforms": "アプリケーションのプラットフォームに存在しないプラットフォームは登録できません。"
      }
    },
    "hints": {
      "ab_test": {
        "lock": "Because this news item has already been delivered, this serial number cannot be changed."
      },
      "country_group": {
        "countries_for_data": "A single country can only be affiliated with a single country group for topics for data delivery.",
        "countries_for_news": "A single country can be affiliated with multiple country groups for topics for news delivery."
      },
      "device": {
        "csv_format": "Enter each record on its own row in the \"Name,Serial Number,Note” format. For the encoding, use UTF-8 without BOM. The line feed code type (CR, LF, or CRLF) does not matter."
      },
      "message": {
        "searchable_application_ids": "You can list up to five application_id values, one at a time, in a single row. List application_id values as a sixteen-digit hexadecimal."
      },
      "message_detail": {
        "allow_domains": "List a single domain per row.",
        "list_image": "Specify a baseline JPEG with a fixed width of 358 pixels, a fixed height of 201 pixels, and a file size of 270 KiB or less.",
        "list_image_v10_or_later": "Please specify a baseline JPEG within 7680 px in width, within 4320 px in height, 16:9 in input aspect ratio, and within 80000 KiB of capacity.",
        "main_image": "Specify a baseline JPEG with a fixed width of 800 pixels, a height of 1800 pixels or less, and a file size of 2500 KiB or less.",
        "main_image_v10_or_later": "Specify a baseline JPEG, progressive JPEG, or PNG with a width of 7680 pixels and a fixed height 4320 pixels or less, and a file size of 80 MiB or less.",
        "main_image_with_movie": "When the eShop Video Access ID is specified, the system automatically sets the thumbnail image registered in NCMS. You may choose to upload an image manually. Specify a baseline JPEG with a fixed width of 800 pixels, a fixed height of 450 pixels, and a file size of 2500 KiB or less.",
        "main_image_with_movie_v10_or_later": "When the eShop Video Access ID is specified, the system automatically sets the thumbnail image registered in NCMS. You may choose to upload an image manually. Specify a baseline JPEG with a fixed width of 1200 pixels, a fixed height of 675 pixels, and a file size of 5625 KiB or less.",
        "more_nso_relative_url": {
          "home": "home",
          "mission_rewards": "Mission&Rewards"
        },
        "more_shop_query": {
          "aocDetail": "DLC Details",
          "aocList": "DLC List",
          "aocs": "DLC List",
          "bundleDetail": "Bundle Details",
          "consumption": "Consumable Items List",
          "list": "Selected Products List",
          "membership_service": "Nintendo Switch Online Membership Purchase Page",
          "membership_top": "Nintendo Switch Online Top Page",
          "new": "Nintendo eShop New Page",
          "pickup": "Nintendo eShop Featured/Discover Page",
          "preticketDetail": "Nintendo Switch Game Voucher Details Page",
          "product_detail": "Product Details",
          "sale": "Nintendo eShop Sales",
          "search": "Search",
          "search_type": {
            "having_trial": "Demo Titles List"
          },
          "softDetail": "Software Details",
          "subscriptions": "Service Tickets List"
        }
      },
      "news_topic": {
        "require_news_descriptions": "Configure news descriptions for all languages with a planned delivery. Cannot deliver news that has no language set."
      },
      "title": {
        "application_id": "For example, 0100000abcdef000"
      }
    },
    "models": {
      "ab_test": "News Group",
      "activity_log": "Operations Log",
      "aging": "Duplicate Aging Data",
      "announcement": "Notification From Administrator",
      "channel_option": "Settings",
      "company": "Company",
      "copy": "Copy",
      "country": "Country",
      "country_group": "Country Group",
      "data_list": "Test Delivery List",
      "data_topic": "Topic",
      "datafile": "Binary",
      "device": "Destination Device",
      "directory": "Directory",
      "general_news": "Channel",
      "group": "Group",
      "ip_restriction": "IP Restriction",
      "jedi_record": "Statistics Record",
      "job": "Job",
      "log_request": "Log",
      "mail_setting": "Mail Settings",
      "message": "News",
      "message_detail": "News Details",
      "message_serial": "Delivery Target Device’s Serial Number",
      "movie": "Video",
      "news_description": "News Description",
      "news_topic": "Topic",
      "nintendo_account": "Destination Account",
      "one2one": "Targeted News",
      "parcel": "Uploaded File",
      "product": "Nintendo eShop Product",
      "promotion": "Promotion",
      "role": "Role",
      "role_request": "Role Request",
      "subscribe": "Subscribe",
      "target_list": "Recipient NSA List",
      "title": "Application",
      "topic_country": "Delivery Target Countries",
      "topic_stat": "Topic Stats",
      "user": "User",
      "virtual_file": "Delivery Data"
    }
  },
  "activity_logs": {
    "index": {
      "action": "Action",
      "api_key": "API Key",
      "controller": "Controller",
      "difference": "Difference",
      "resource": "Resource",
      "resource_name": "Resource Name",
      "timestamp": "Date and Time",
      "title": "Operations Log",
      "user": "User",
      "user_or_api_key": "User or API Key"
    },
    "show": {
      "resource_name": "Resource Name",
      "title": "Operations Log",
      "user_or_api_key": "User or API Key"
    }
  },
  "announcements": {
    "edit": {
      "title": "Edit %{announcement_subject}"
    },
    "empty": "There are no news items.",
    "index": {
      "title": "Notification From Administrator"
    },
    "new": {
      "title": "Add Notification From Administrator"
    },
    "show": {
      "title": "Details for %{announcement_subject}"
    }
  },
  "api_accounts": {
    "edit": {
      "title": "Edit Account"
    },
    "index": {
      "detail": "Permissions Details",
      "link_keys": "Manage API Keys",
      "swagger": {
        "v2": "V2 Swagger"
      },
      "swagger_hint": "You can create WebApi (V2) accounts for BCAT.",
      "title": "Manage Web API Accounts"
    },
    "new": {
      "title": "Create Account"
    },
    "show": {
      "title": "Details"
    }
  },
  "api_keys": {
    "hints": {
      "can_create": "You can create an API key if there is at least one associated application.",
      "can_know_secret": "Authentication information consists of the client ID and client secret, separated with a colon.",
      "may_copy": "Only the API key owner can view the client secret text."
    },
    "index": {
      "authorization": "Authentication Information",
      "title": "News API Key List"
    },
    "menu_title": "Manage News API Keys"
  },
  "attributes": {
    "label_platform": "LabelPlatform",
    "platforms": "Platforms"
  },
  "bcat_settings": {
    "edit": {
      "title": "BCAT Setting"
    },
    "show": {
      "title": "BCAT Setting"
    }
  },
  "calendars": {
    "show": {
      "title": "Calendar"
    }
  },
  "censors": {
    "index": {
      "title": "List of News Items in the Nintendo Review Queue"
    }
  },
  "censorship_mailer": {
    "data": {
      "tencent_censor_approval": {
        "body": "The data file has been approved as a result of the Tencent review.",
        "subject": "Notice of phase change for data item from BCAT (%{env}) (Tencent Review Approval: %{name})"
      },
      "tencent_censor_cancel": {
        "body": "Tencent review of a data file has been canceled.",
        "subject": "Notice of status phase for data item from BCAT (%{env}) (Tencent Review Canceled: %{name})"
      },
      "tencent_censor_rejection": {
        "body": "The data file has been rejected as a result of the Tencent review.",
        "subject": "Notice of phase change for data item from BCAT (%{env}) (Tencent Review Rejection: %{name})"
      },
      "tencent_censor_request": {
        "body": "Tencent review of a data file has been requested.",
        "subject": "Notice of phase change for data item from BCAT (%{env}) (Tencent Review Requested: %{name})"
      }
    },
    "directory_name": "Directory Name",
    "news": {
      "censor_approval": {
        "body": "The news item Nintendo review result has been approved.",
        "subject": "Notice of phase change for news item from BCAT (%{env}) (Nintendo Review Approval: %{name})"
      },
      "censor_cancel": {
        "body": "Nintendo review of a news item has been canceled.",
        "subject": "Notice of phase change for news item from BCAT (%{env}) (Nintendo Review Canceled: %{name})"
      },
      "censor_rejection": {
        "body": "Nintendo review of a news item has been rejected.",
        "subject": "Notice of phase change for news item from BCAT (%{env}) (Nintendo Review Rejection: %{name})"
      },
      "censor_request": {
        "body": "Nintendo review of a news item has been requested.",
        "subject": "Notice of phase change for news item from BCAT (%{env}) (Nintendo Review Request: %{name})"
      },
      "publishing_unapproval": {
        "body": "The news delivery approval was canceled.",
        "subject": "Notice of phase change for news item from BCAT (%{env}) (Nintendo Review Approval Canceled: %{name})"
      },
      "tencent_censor_approval": {
        "body": "The News item has been approved as a result of the Tencent review.",
        "subject": "Notice of phase change for News item from BCAT (%{env}) (Tencent Review Approval: %{name})"
      },
      "tencent_censor_cancel": {
        "body": "Tencent review of a News item has been canceled.",
        "subject": "Notice of phase change for News item from BCAT (%{env}) (Tencent Review Canceled: %{name})"
      },
      "tencent_censor_rejection": {
        "body": "The News item has been rejected as a result of the Tencent review.",
        "subject": "Notice of phase change for News item from BCAT (%{env}) (Tencent Review Rejection: %{name})"
      },
      "tencent_censor_request": {
        "body": "Tencent review of a News item has been requested.",
        "subject": "Notice of phase change for News item from BCAT (%{env}) (Tencent Review Requested: %{name})"
      }
    },
    "news_name": "News Name"
  },
  "channel_options": {
    "manage_semantics_version": {
      "except_system_news": "Except system News",
      "system_news": "System News",
      "title": "Update the semantics version of the channel",
      "update_semantics_version": "Update the semantics version of the channel with the displayed global semantics version."
    },
    "tooltips": {
      "system_news": "System News includes %{npns_topic_ids}."
    }
  },
  "cloudwatch_logs": {
    "test_logs": {
      "accessed_at": "Access Time",
      "aws_incident": "An error occurred in the log collection server and data cannot be displayed.",
      "ip_address": "IP Address",
      "notice": "Logs accessed from systems with test delivery settings in the last 6 hours are displayed.",
      "resource": "Resource",
      "serial": "Device Serial Number",
      "status": "Status",
      "title": "Test Delivery Access Logs",
      "type": "Type"
    }
  },
  "comment_mailer": {
    "new_comment_for_message": {
      "body": "A new comment was posted.",
      "comment": {
        "body": "Body",
        "user": "Poster"
      },
      "subject": "Notification of Comment Posted From BCAT (%{env}) (%{name}%{region})"
    },
    "new_comment_for_virtualfile": {
      "body": "A new comment was posted.",
      "comment": {
        "body": "Body",
        "user": "Poster"
      },
      "dir_name": "Directory Name",
      "subject": "Notification of Comment Posted From BCAT (%{env}) (%{name})"
    }
  },
  "comments": {
    "create": {
      "comment_posted": "Successfully Posted the Comment",
      "failed_to_post_comment": "Failed to post the comment. The body of the comment is required."
    }
  },
  "common": {
    "action": {
      "add": "Add",
      "approve": "Approve",
      "cancel": "Cancel",
      "create": "Add",
      "destroy": "Delete",
      "destroy_uploaded": "Delete Uploaded File",
      "disable": "Disable",
      "duplicate": "Duplicate",
      "edit": "Edit",
      "enable": "Enable",
      "error_occured_on_process_please_retry_later": "An error occurred while processing. Please try again later.",
      "import": "Import",
      "new": "Create New",
      "post_comment": "Post Comment",
      "reject": "Reject",
      "request": "Request",
      "reset_retrieving_conditions": "Reset Conditions",
      "sending": "Sending",
      "show": "Details",
      "stats": "Statistics",
      "sync": "Synchronization",
      "unable_to_find_available_bcat_application": "Cannot find an available BCAT usage request.",
      "update": "Update"
    },
    "alert": {
      "access_token_invalid_please_relogin": "Your access token is not valid. Please log out and log in again.",
      "unable_to_fetch_data_wait_while": "Cannot fetch NDID data. Please try again later."
    },
    "back": "Back",
    "browser_warning": "The site only supports using Google Chrome. Use Chrome as your browser because some pages do not work properly in other browsers.",
    "channel_side_nav": {
      "activity_logs": "Operations Log",
      "add_user": "Add User",
      "back_to_general_news_index": "Return to Channel List",
      "back_to_titles": "Return to Application List",
      "datafile": "Data",
      "general_news_detail": "Channel Details",
      "manage_bcat_application": "Manage BCAT Usage Requests",
      "manage_country_groups": "Manage Country Groups",
      "manage_data": "Manage Data Delivery",
      "manage_device": "Manage Destination Device",
      "manage_na": "Manage Destination Account",
      "manage_news": "Manage News Delivery",
      "manage_test_delivery": "Manage Test Delivery",
      "my_role": "My Role",
      "news": "News",
      "no_ip_restrictions_for_delivery": "Configure an IP address restriction. Data from this and any test deliveries cannot be received when there are no settings.",
      "no_ip_restrictions_for_test_delivery": "Configure an IP address restriction. Data from any test deliveries cannot be received when there are no settings.",
      "role_management": "Manage Roles",
      "role_requests": "Role Requests",
      "test_distribution": "Test Delivery",
      "test_logs": "Test Delivery Logs",
      "title_detail": "Application Details",
      "user_list": "User List"
    },
    "confirm": {
      "destroy": "Are you sure you want to delete?",
      "leave": "Any entries that have not been added or saved will be lost. Proceed?",
      "update": "Are you sure you want to update?"
    },
    "error": {
      "containing_invalid_characters": "Contains characters that cannot be used.",
      "failed_to_approve": "Failed to approve.",
      "failed_to_be_under_editing": "Failed to begin editing.",
      "failed_to_cancel": "Failed to cancel.",
      "failed_to_create": "Failed to add.",
      "failed_to_destroy": "Failed to delete.",
      "failed_to_duplicate": "Failed to duplicate.",
      "failed_to_expire": "Filed to expire delivery.",
      "failed_to_publish": "Failed to publish delivery.",
      "failed_to_reject": "Failed to reject.",
      "failed_to_request": "Failed to request.",
      "failed_to_unapprove": "Failed to cancel approval.",
      "failed_to_update": "Failed to update.",
      "mismatched_related_product": "Unauthorized related product."
    },
    "global_side_nav": {
      "data_distribution": "Data Delivery",
      "general_news_roles": "News Roles",
      "my_role": "My Role",
      "ndp_bcat_usage_document": "Management Tool Operations Manual",
      "ndp_news_guide_document": "News Delivery Guide",
      "news_distribution": "News Delivery",
      "role_requests": "Role Requests",
      "service_roles": "Service Roles",
      "subscribe": "Subscribe",
      "subscribes": "Subscription Settings",
      "title_roles": "Application Roles",
      "titles_subscribes": "Create Application",
      "user_settings": "User Settings"
    },
    "help": "Help",
    "locale_select": "Language",
    "logout": "Sign Out",
    "mailer": {
      "check_url_below": "For more information, go to the following URL.",
      "no_reply_notice": "This email was sent automatically. Please do not respond to this email.",
      "operator": "Operator"
    },
    "notice": {
      "approved": "Approved",
      "been_under_editing": "Changed to Under Editing.",
      "cancelled": "Canceled",
      "created": "Added",
      "destroyed": "Deleted",
      "disabled": "Disabled",
      "duplicated": "Duplicated",
      "enabled": "Enabled",
      "expired": "Delivery Expired",
      "notified": "Notified",
      "published": "Delivery Published",
      "rejected": "Rejected",
      "requested": "Requested",
      "unapproved": "Approval Removed",
      "updated": "Updated"
    },
    "rating_system": "Rating System",
    "role": {
      "editor_description": "In addition to Viewer permissions, you can create or update delivery settings.",
      "manager_description": "In addition to Reviewer permissions, you can manage roles.",
      "reviewer_description": "In addition to Editor permissions, you can approve pre-delivery reviews.",
      "roles_description": "Permissions Per Role",
      "viewer_description": "You can only view delivery settings."
    },
    "timezone_select": "Time Zone"
  },
  "country_groups": {
    "common": {
      "countries_summary": "... Total %{size} Countries"
    },
    "edit": {
      "title": "Edit %{country_group_name}"
    },
    "index": {
      "title": "Country Group"
    },
    "index_with_no_country_group": {
      "information": {
        "manual": {
          "body_html": "You can create a country group manually instead of leaving it up to the system. If you are not used to country group settings, create a country group with settings recommended by the system. You can manually create a country group later on, even after creating a country group with settings recommended by the system.",
          "title": "Create Country Group Manually"
        },
        "recommended": {
          "body_html": "Configure country group with settings recommended by the system. If there are no special reasons, create a country group with settings recommended by the system. You can edit or delete a created country group later. You can also add a country group manually. <br/>In the system-recommended settings, a country group is created for each delivery region, comprising target countries submitted in NCMS. There are four delivery regions: Japan—including only Japan, America—including mainly countries in the Americas, Europe—including mainly countries in Europe, and Australia—including Australia and New Zealand. If a delivery region does not include any target countries submitted in NCMS, a country group for that delivery region will not be created.",
          "title": "Create country group with settings recommended by the system."
        }
      },
      "not_allowed": "You do not have permission to perform this operation.",
      "title": "Country Group"
    },
    "new": {
      "title": "Create Country Group"
    },
    "selected_countries": "Selected Countries",
    "show": {
      "title": "Details for %{country_group_name}"
    }
  },
  "data_lists": {
    "edit": {
      "sub_title": "Select data for test delivery.",
      "title": "Edit Test Delivery List"
    },
    "form": {
      "available_data_size": "Remaining space in data delivery cache storage:",
      "confirm": "Saves the content and delivers it to running devices. It is not delivered to devices that are in sleep mode. After you have confirmed that the device is running, select OK.",
      "current_data_size": "Total size of the data in the list:",
      "devices": "Access Permission Settings",
      "exclude_expired": "Exclude Expired Items",
      "include_expired": "Include Expired Items",
      "invalid": "The distribution list is empty, or the data size has exceeded the data delivery cache storage size.",
      "maximum_data_size": "Data Delivery Cache Storage Size:",
      "name": "Test Delivery List Name",
      "nintendo_accounts": "Delivery Target Settings",
      "notify": "Redeliver",
      "save_and_notify": "Save and Deliver",
      "selected_data": "Selected Data"
    },
    "index": {
      "title": "Test Delivery"
    },
    "new": {
      "not_found": "There is no registered data.",
      "sub_title": "Select data for test delivery.",
      "title": "Create Test Delivery List"
    },
    "show": {
      "confirm": "Data is delivered to devices that are running. It is not delivered to devices that are in sleep mode. After you have confirmed that the device is running, select OK.",
      "hint": {
        "description": "Check that the device that you are using is not in sleep mode. <br>\nVerify that the serial number of the recipient device, recipient account, and IP address are configured in the management tool. <br>\nIn addition, check whether the recipient device has been set up to connect to the test delivery environment. <br>\nYou can use the test delivery log to check whether there was access from your device. <br>\nThere are other factors that may interfere with test delivery. For more information, see the following documentation pages.\n",
        "subtitle": "When Test Delivery Is Unavailable"
      },
      "notified": "Delivered to Nintendo Switch devices that have test delivery accounts.",
      "notify": "Deliver",
      "title": "Test Delivery Details"
    },
    "tooltips": {
      "devices": "Select the serial number for the Nintendo Switch permitting access. You can only receive data and news from this selected Nintendo Switch.",
      "name": "Name the test delivery list. A management-level name unrelated to the actual delivery.",
      "nintendo_accounts": "Specify the Nintendo Account that will send notifications. To receive notifications, users must be signed in to a Nintendo Switch, using the selected Nintendo Account."
    }
  },
  "data_size_chart_params": {
    "now": "Now",
    "yaxis": "Usage of Data Delivery Cache Storage [%]"
  },
  "data_tables": {
    "language": {
      "oAria": {
        "sSortAscending": ": Activate to Sort Column Ascending",
        "sSortDescending": ": Activate to Sort Column Descending"
      },
      "oPaginate": {
        "sFirst": "First",
        "sLast": "Last",
        "sNext": "Next",
        "sPrevious": "Previous"
      },
      "sEmptyTable": "No Access Logs From the Past 6 Hours",
      "sInfo": " Showing _START_ to _END_ of _TOTAL_ Entries",
      "sInfoEmpty": " Showing 0 to 0 of 0 Entries",
      "sInfoFiltered": "(Filtered from _MAX_ total entries.)",
      "sInfoPostFix": null,
      "sInfoThousands": ",",
      "sLengthMenu": "Show _MENU_ Entries",
      "sLoadingRecords": "Loading...",
      "sProcessing": "Processing...",
      "sSearch": "Search:",
      "sZeroRecords": "No Matching Records Found"
    }
  },
  "data_topics": {
    "edit": {
      "title": "Edit %{npns_topic_id}"
    },
    "index": {
      "title": "Data Delivery Management"
    },
    "show": {
      "title": "Details for %{npns_topic_id}"
    }
  },
  "datafiles": {
    "confirm": {
      "destroy_warning": "We recommend that you keep delivery data that has been sent for analysis purposes. \nAre you sure you want to delete this delivery data?\n"
    },
    "duplicate": {
      "from": "Duplication Source",
      "title": "Duplicate Delivery Data",
      "to": "Duplication Destination"
    },
    "edit": {
      "title": "Delivery Data"
    },
    "hints": {
      "filename": "Filenames can be up to 31 alphanumeric characters, including underscores (_) and periods (.)."
    },
    "index": {
      "title": "Delivery Data"
    },
    "new": {
      "title": "New Delivery Data"
    },
    "publishings": {
      "index": {
        "publishing_at": "Publicly Available Date and Time",
        "title": "Publicly Available Delivery Data"
      },
      "show": {
        "title": "Publicly Available Delivery Data Details"
      }
    },
    "show": {
      "title": "Delivery Data Details"
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "debugs": {
    "index": {
      "disable_becase_of_inconsistent_status": "Inconsistent country group. Contact the developer.",
      "disable_becase_of_officla_channel": "Change the official channel from Manage Country Groups."
    }
  },
  "deliverables": {
    "action": {
      "approve_censor": "Approve Nintendo Review",
      "approve_publishing": "Approve Delivery",
      "approve_review": "Approve Pre-Delivery Review",
      "approve_review_and_request_censor": "Approve Pre-Delivery Review and Request Nintendo Review",
      "approve_review_and_request_tencent_censor": "Approve Pre-Delivery Review and Request Tencent Review",
      "approve_tencent_censor": "Approve Tencent Review",
      "cancel": "Cancel",
      "cancel_censor": "Cancel Nintendo Review",
      "cancel_review": "Cancel Pre-Delivery Review",
      "cancel_tencent_censor": "Cancel Tencent Review",
      "duplicate": "Duplicate",
      "emergency_force_modify": "Emergency Stop Delivery",
      "expire": "Delivery Ended",
      "expire_and_modify": "Stop Delivery",
      "force_modify": "Forcibly Modify Content",
      "modify": "Modify Content",
      "notify": "Send Notification",
      "preview": "Preview",
      "publish": "Start Delivery",
      "reject_censor": "Reject Nintendo Review",
      "reject_review": "Reject Pre-Delivery Review",
      "reject_tencent_censor": "Reject Tencent Review",
      "request_censor": "Request Nintendo Review",
      "request_review": "Request Pre-Delivery Review",
      "request_tencent_censor": "Request Tencent Review",
      "show_censor": "Details (for Nintendo Review)",
      "stop_by_dirty_word_detection": "Stop by Profanity Detection",
      "unapprove": "Cancel Approval and Modify Content"
    },
    "confirm": {
      "cannot_approve_discovery": "This news cannot be distributed because discovery news has already distributed in future distribution date and time.",
      "emergency_force_modify": "Set the status to Under Editing regardless of the current status of the News. (This feature is for use by the Licensing Department.)",
      "error": "Error",
      "expire": "Are you sure you want to set the News status to end delivery without waiting for the delivery end date and time? (This operation is for debugging.)",
      "expire_and_modify": "Stop delivery and change the status to Under Editing. Are you sure you want this News item to be not displayed in Channel Details?",
      "force_modify": "Are you sure you want to set the status to Under Editing regardless of the current status of the News? (This operation is for debugging.)",
      "modify": "Are you sure you want to modify the content?",
      "note": "Note",
      "note_details": "Only one news item can be delivered per country. In case of overlap, the item with the most recent delivery start time will be displayed.",
      "note_tips": "News items will be removed from delivery once their start time has passed. Please be careful when setting the country and delivery start time.",
      "publish": "Do you want to deliver this news to Discovery?",
      "publish_tips": "This news will appear on Discovery after the specified delivery start date and time."
    },
    "status": {
      "censor_requesting": "Requesting Nintendo Review",
      "discovery": "ディスカバリー記事",
      "discovery_visible_in_online_archives": "ディスカバリー記事\n（プロモーション中）\n",
      "draft": "Under Editing",
      "expired": "Delivery Ended",
      "movie_expired": "Movie Expired",
      "normal": "通常記事",
      "passed_display_limit": "Display Expired",
      "publishing": "Delivering",
      "publishing_approved": "Delivery Approved",
      "review_requesting": "Requesting Pre-Delivery Review",
      "tencent_censor_requesting": "Requesting Tencent Review",
      "visible_in_online_archives": "通常記事\n（チャンネル詳細に表示中）\n"
    }
  },
  "detecting_dirty_word_mailer": {
    "withdraw": {
      "body": "Profanity was detected, so the editing status has been changed to Under Editing. \nIf this News item is in circulation, it will be temporarily withdrawn; \nto resume distribution, fix the profanity issue and then go through the delivery flow again.\n",
      "subject": "Notice of phase change for News item from BCAT (%{env}) (Profanity was detected: %{name})"
    }
  },
  "devices": {
    "edit": {
      "title": "Edit Destination Device"
    },
    "errors": {
      "invalid_csv": "The CSV format is not valid."
    },
    "index": {
      "title": "Manage Destination Device"
    },
    "new": {
      "empty_csv": "A CSV file is not specified.",
      "import_csv": "Bulk Import From CSV",
      "import_size_overflow": "There are too many devices specified in the CSV file. Do not exceed %{maximum} devices.",
      "invalid_csv": "The CSV format is not valid.",
      "title": "Add Destination Device"
    },
    "show": {
      "title": "Destination Device Details"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "directories": {
    "alert": {
      "too_many_messages": "You are currently delivering %{count} articles to %{country_group}. \nAdjust your status quo so that no more than %{max_count} articles are being delivered to each country group at a time.\n"
    },
    "confirm": {
      "destroy_warning": "Deleting this directory deletes the delivery data found within the directory. We recommend that you keep data that has been sent for analysis purposes. \nAre you sure you want to delete this directory?\n"
    },
    "edit": {
      "title": "Edit %{directory_name}"
    },
    "hints": {
      "filename": "The directory name can be up to 31 alphanumeric characters, including underscores (_)."
    },
    "index": {
      "data_size_chart": "Usage of Data Delivery Cache Storage",
      "document_url": "https://developer.nintendo.com/html/online-docs/g1kr9vj6-en/document.html?doc=Packages/Docs/Guides/news-delivery-guide/NintendoSwitch-News_Delivery_Guide.html",
      "new_directory": "Create Directory",
      "title": "Directory List",
      "warning_text_html": "If you distribute the Game News in Japan, pursuant to Article 548-2 to 548-4 of Civil Code of Japan, the News Delivery Guide at <a href=\"%{url}\" target=\"_blank\" rel=\"noopener noreferrer\">URL</a>(the \"Guide\") shall apply to your use of the News Channel features. You should confirm the Guide in advance of your use, and you can use the News Channel features if you agree to comply with the Guide."
    },
    "new": {
      "title": "Create Directory"
    },
    "show": {
      "confirm_expire_deliverables": "Are you sure you want to set all items to expired?",
      "confirm_modify_deliverables": "Are you sure you want to set all items to draft?",
      "expire_deliverables": "Set All Items to Delivery Expired",
      "modify_deliverables": "Set All Items to Draft",
      "title": "Details for %{directory_name}"
    }
  },
  "distribution_sizes": {
    "datafiles": {
      "title": "List of data delivery sizes"
    },
    "message_details": {
      "title": "List of News delivery sizes"
    }
  },
  "documents": {
    "index": {
      "download_link": "[Download]",
      "external_link": "[External Link]",
      "management_tool_usage_manual": "BCAT Management Tool Operations Manual",
      "news_delivery_guide": "News Delivery Guide",
      "private_management_tool_usage_manual": "Management Tool Operations Manual (Internal)",
      "private_news_delivery_guide": "News Delivery Guide (Private)",
      "title": "Documents"
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": {
        "one": "1 error prohibited this %{resource} from being saved:",
        "other": "%{count} errors prohibited this %{resource} from being saved:"
      },
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    }
  },
  "featured_products": {
    "action": {
      "fetch": "Fetch Price Information",
      "fetch_all": "Fetch All",
      "fetching": "Fetching"
    },
    "additional_labels": {
      "label": "Product Label",
      "tooltip": "You can add a product label such as “NEW” and “Advance Downloading.”"
    },
    "comment": {
      "label": "Product Introduction",
      "tooltip": "Text that introduces a product. You can enter text in multiple languages."
    },
    "country_price": {
      "label": "Country Price Settings",
      "tooltip": "Select the price format and price for the specified country."
    },
    "demo_info": {
      "label": "Demo Information",
      "tooltip": "Add information about demo versions, such as “demo is available.” You can enter text in multiple languages."
    },
    "discount": {
      "label": "Sale"
    },
    "discount_price": {
      "label": "Discount Price",
      "tooltip": "Enter the discount price. The same price is displayed regardless of the country and language."
    },
    "regular_price": {
      "label": "Regular Price",
      "tooltip": "Enter the regular price of the product. The same price is displayed regardless of the country and language."
    },
    "sale_info": {
      "label": "Sale Information",
      "tooltip": "Sale information with headings such as SALE and 20% OFF. You can enter text in multiple languages."
    },
    "selector": {
      "label": "Rankings and Featured Products",
      "tooltip": "Select ratings and featured products to be picked up in the news. Up to 30 can be selected."
    },
    "supplement": {
      "label": "Additional Sale Information",
      "tooltip": "Supplement sale information, such as displaying the sale period. You can enter text in multiple languages."
    }
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "general_news": {
    "confirm": {
      "please_destroy_news_before_destroy_channel": "If News remains in the channel, inconsistencies may occur."
    },
    "edit": {
      "title": "Edit %{general_news_name}"
    },
    "index": {
      "manage_topic": "Manage Topics",
      "search_field": {
        "name_not_cont": "Name is not included"
      },
      "title": "Channel List"
    },
    "manage_role": {
      "general_news_role": "News Role",
      "request_role": "Requested Role",
      "title": "User List"
    },
    "my_role": {
      "current_role": "Current Role",
      "requesting": "Requested",
      "role_request_desc": "To change your role, select the role type and send a request to a manager. When a manager accepts your request, you will be assigned to the role.",
      "title": "My Role"
    },
    "new": {
      "title": "Create Channel"
    },
    "show": {
      "manage_data": "Manage Delivery Data",
      "manage_topic": "Manage Topics",
      "title": "Details for %{general_news_name}",
      "toggle_display_countries": "Show or Hide Details"
    },
    "tooltips": {
      "countries": "Select the countries that will support the news item.",
      "expiration_time": "Specify the date and time when the channel ends.",
      "name": "Name of the news management tool. This content is not displayed.",
      "news_icon_image": "Set the icon that appears as the news source.",
      "publisher_name": "Set the name that appears as the news source.",
      "publishing_time": "Specify the date and time for the public release of the channel.",
      "service_status": "Specify the news service status."
    }
  },
  "hello": "Hello world",
  "helpers": {
    "actions": "Actions",
    "links": {
      "back": "Back",
      "cancel": "Cancel",
      "confirm": "Are you sure?",
      "destroy": "Delete",
      "edit": "Edit",
      "new": "New",
      "show": "Show"
    },
    "page_entries_info": {
      "entry": {
        "one": "entry",
        "other": "entries",
        "zero": "entries"
      },
      "more_pages": {
        "display_entries": "Displaying <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
      },
      "one_page": {
        "display_entries": {
          "one": "Displaying <b>1&nbsp;-&nbsp;1</b> of <b>1</b> in total",
          "other": "Displaying <b>1&nbsp;-&nbsp;%{count}</b> of <b>%{count}</b> in total",
          "zero": "Displaying <b>0&nbsp;-&nbsp;0</b> of <b>0</b> in total"
        }
      }
    },
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    },
    "titles": {
      "delete": "Delete",
      "edit": "Edit",
      "new": "New",
      "save": "Save"
    }
  },
  "i18n": {
  },
  "ip_restrictions": {
    "edit": {
      "title": "Edit IP Address Restriction"
    },
    "index": {
      "no_ip_restrictions": "Restrictions on IP addresses are not set.",
      "restrictions_for_list": "The configured IP address restrictions are applied only to production delivery. It does not apply to test delivery.",
      "restrictions_for_list_and_test_list": "The configured IP address restrictions are applied to both production delivery and test delivery.",
      "restrictions_for_test_list": "The configured IP address restrictions are applied only to test delivery. It does not include production deliveries.",
      "title": "IP Address Restrictions"
    },
    "new": {
      "title": "Create IP Address Restriction"
    },
    "show": {
      "title": "IP Address Restrictions Details"
    }
  },
  "jedi_records": {
    "message": {
      "title": "%{summary} Statistics"
    },
    "virtual_file": {
      "title": "%{filename} Statistics"
    }
  },
  "log_request_mailer": {
    "notify": {
      "created_at": "Request Date and Time",
      "end_at": "Logging End Time",
      "expiration_at": "Expiration Date",
      "follow_url": "You can download logs from the following URL.",
      "request_log_information": "Request Log Information",
      "start_at": "Logging Start Time",
      "subject": "Notice of log preparation request from BCAT (%{env})"
    }
  },
  "log_requests": {
    "index": {
      "title": "Log Requests"
    }
  },
  "lushan": {
    "action": {
      "refresh": "Successfully updated the account for China: %{name}.",
      "success": "Successfully linked the account for China."
    },
    "confirm": {
      "destroy": "Are you sure you want to disassociate the account for China?"
    },
    "index": {
      "add": "Add account for China",
      "destroy": "Remove Account",
      "follow_logout/html": "If you are signed in to an account for China, you may not be able to add the intended recipient account. <br />In this case, sign out from the account for China and add the account again.",
      "logout_html": "To Page for Account for China",
      "name": "Nickname",
      "title": "Manage recipient accounts for China"
    }
  },
  "mail_settings": {
    "edit": {
      "all_description": "You can change email receive settings for the email to send with the BCAT Management Tool.",
      "title": "Mail Settings"
    },
    "form": {
      "another": "Other Settings",
      "hint": {
        "mail_enabled_html": "For example, the following applies. \n- Change Role\n- Operations for Data Delivery\n",
        "mail_enabled_html_for_nintendo": "- For Nintendo: News Operations for Special Channels (including the Nintendo Switch Information Center not configured for country groups)\n"
      },
      "news": "News Email Notice",
      "regions": {
        "AUS": "Receive notifications for \"豪州宛て/for Austrarian and New Zealand\".",
        "CHN": "Receive notifications for \"中国宛て/for China\".",
        "EUR": "Receive notifications for \"欧州宛て/for European\".",
        "JPN": "Receive notifications for \"日本宛て/for Japan\".",
        "KOR": "Receive notifications for \"韓国宛て/for Korea\".",
        "TWN": "Receive notifications for \"香港台湾宛て/for Hong Kong and Taiwan\".",
        "USA": "Receive notifications for \"米州宛て/for American\".",
        "hint": "These settings apply to all channels. \nIf you disable all of the items, you will no longer receive News notification emails.\n",
        "label": "Select the country groups to receive."
      }
    }
  },
  "main_contents": {
    "action": {
      "add_movie_url": "Add to Video %{index}"
    },
    "form": {
      "body": "Body %{index}",
      "main_image": "Main Image %{index}",
      "main_movie": "Main Video(eShop Video Access ID)%{index}"
    },
    "tooltips": {
      "body": "Bold (<strong></strong>) tags are available for emphasizing text in the body of the News article. \nBold tags do not support nesting, such as further emphasizing text within already-emphasized text.\n",
      "body_v6": "Bold (<strong></strong>) and heading (<header></header>) tags are available for emphasizing text in the body of the News article. \nThe bold and heading tags do not support nesting (such as when using a heading inside bolded text). \nAs an exception, you can use bolded text inside a heading. \nIf you use unsupported nesting, the displays on the preview and console may differ. \nYou may use up to ten headings within one body text. \nYou can only display up to two lines in a single heading. \nIf the heading text continues onto a third line, the third and subsequent lines are hidden.\n",
      "body_v7_or_later": "Bold (<strong></strong>), italics (<ita></ita>), and heading (<header></header>) tags are available for emphasizing text in the body of the News article. \nIf you use nesting in an unsupported order, the displays on the preview and console may differ. \nYou can nest italics tags inside bold tags to create bold italic text. \nThe same effect can be achieved by nesting bold tags inside italics tags. \nWhile you can use bold and italics inside headings, \nyou cannot uses heading tags inside bold and italics tags. \nYou may use up to ten headings within one body text. \nYou can only display up to two lines in a single heading. \nIf the heading text continues onto a third line, the third and subsequent lines are hidden.\n",
      "main_movie": "eShop Video Access ID for video to display in the News Details page. Enter the eShop Video Access ID that were requested in NCMS."
    }
  },
  "message_details": {
    "error": {
      "failed_to_copy_message_details": "Failed to copy and save from the default language. Some items, such as the footer, have different character limits depending on the language. For this reason, even if the original language meets the character limit, there may be an error in the target language. Adjust the number of characters in the source language so that the target language also meets the character limit, copy the News item from the default language again, and run the update."
    },
    "form": {
      "add_main_content": "Add Video URL, Main Image, and Body",
      "copy_featured_products_to_shop_list_link": "Copy Rankings and Featured Products",
      "copy_rating": "Copy From Another Language Tab",
      "remove_main_content": "Remove Video URL, Main Image, and Body",
      "set_default_text": "Set the Default Text",
      "suggest_video_list": {
        "check_all": "Check All",
        "remove_all": "Remove All",
        "title": "Select Title to Reference Videos",
        "video_list_title": "Related Videos List"
      },
      "view_on_ncms": "View on NCMS"
    },
    "hints": {
      "filter": "Enter the text you want to filter on.",
      "strong_and_header_tags_are_available": "Bold (<strong></strong>) and heading (<header></header>) tags are available for emphasizing text in this form. You can check the appearance in the preview.",
      "strong_and_ita_tags_are_available": "Bold (<strong></strong>) and italics (<ita></ita>) tags are available for emphasizing text in this form. You can check the appearance in the preview.",
      "strong_header_and_ita_tags_are_available": "Bold (<strong></strong>), italics (<ita></ita>), and heading (<header></header>) tags are available for emphasizing text in this form. You can check the appearance in the preview."
    },
    "tooltips": {
      "allow_domains": "Specify a list of accessible domains after transitioning to the browser. Access to any domain not specified in this field will be blocked.",
      "application_arg": "Specify the argument (in binary and up to 4 KiB in size) for starting the application.",
      "application_arg_from_v4_on": "Specify the argument (in binary and up to 16 KiB in size) for starting the application.",
      "auto_play": "Specifies whether to play listed video on the list view. *This is effective only when listed video is registered.",
      "body": "News body.",
      "copy_rating": "Copy the ESRB rating information of the specified language. If you later change the copy source, the ESRB rating will not be updated in the copy destination automatically. Click the Copy button again, if necessary.",
      "decoration_text": "This string is displayed in the Special Feature callout.",
      "featured_list_name": "Name of the list to be displayed for Rankings and Features Products List in Nintendo eShop.",
      "filter": "Items matching the filter text are displayed.",
      "footer_text": "Text to display at the end of the news item. It is displayed with a more subdued appearance than the body.",
      "list_image": "Image to display on the entrance or the News Details page.",
      "list_image_v10": "Image to display on the entrance or the News Details page. You must set at least one of either the list image or the list video URL.",
      "list_movie": "eShop Video Access ID for list video to display in the entrance or the News Details page. Enter the eShop Video Access ID that were requested in NCMS. You must set at least one of either the list image or the list video.",
      "main_image": "Image to display on the News Details page. You must set at least one of either the main image or the video URL.",
      "main_movie": "eShop Video Access ID for video to display in the News Details page. Enter the eShop Video Access ID that were requested in NCMS. You must set at least one of either the main image or the main video.",
      "more_application_ids": "Specify IDs for the applications to start from the news item. Up to five items can be specified.",
      "more_application_text": "Text to display on the Links button.",
      "more_applications": "Specify applications to start from the news item. Up to five items can be specified.",
      "more_link_text": "Text to display on the Links button.",
      "more_link_url": "URL for content to display in the browser from the Links button.",
      "more_nso_relative_url": "You can specify the page to display on Nintendo Switch Online system application from the Links button.",
      "more_nso_text": "Text to display on the Links button.",
      "more_shop_query": "You can specify the page to display on Nintendo eShop from the Links button.",
      "more_shop_text": "Text to display on the Links button.",
      "more_system_applet_text": "Text to display on the Links button.",
      "more_system_applet_type": "Specify the console feature to start from the Links button.",
      "more_type": "Specify the link type.",
      "movie_name": "Specify the name of the video. The names are either specified for all videos or not specified for all videos.",
      "ratings": "Specify the news rating. In general, specify the same rating as for the application.",
      "related_movies": "Up to nine items can be specified. Enter the eShop Video Access ID from NCMS, and then click Add.",
      "show_contents_excuse": "Specifies whether to include the \"%{description}\" text at the end of the footer. Select \"Include\" as long as there is no special reason not to.",
      "show_distribution_area": "Specify whether to add \"This news is created for Nintendo Account in ***.\" at the end of the footer. Select \"Include\" as long as there is no special reason not to.",
      "show_list_movie": "eShop Video Access ID for list video to display in the entrance or the News Details page.",
      "show_nso_precaution": "Specify whether to add that Nintendo Switch Online membership is required for online play to the end of the footer.",
      "start_seconds": "Specifies the start time in seconds for playing listed video on the list view. *This is effective only when listed video is registered.",
      "subject": "The news item title. It appears in an overlap on the list image, on the entrance page when 'Overlap Display of News Titles in List Image' is set to ‘Display.’",
      "suggest_video_list": {
        "failed_notify": "Failed to apply the %{language} list.",
        "main": "Suggested videos for the title are displayed.",
        "notify": "Applied the %{language} list.",
        "video_list": "A list of suggested videos for the title."
      }
    }
  },
  "message_serials": {
    "tooltips": {
      "published": "After the linked news item is published, it cannot be edited automatically.",
      "serial": "The numeric value of the 13th digit from the left of the serial number on the Switch console."
    }
  },
  "messages": {
    "action": {
      "add_list_movie_url": "Add to List Video URL",
      "add_movie_url": "Add to Video URL",
      "add_related_movies": "Add Related Videos"
    },
    "alert": {
      "warning_update_publishing_time": "When changing the delivery start date and time, verify that the News is correct as of the delivery start date and time after the change."
    },
    "confirm": {
      "copy_commit": "News in other languages will be overwritten by the default news language. Proceed?",
      "destroy_warning": "We recommend that you keep news that has been sent for analysis purposes. \nAre you sure you want to delete the news item?\n"
    },
    "duplicate": {
      "cannot_duplicate_message": "This news item cannot be duplicated. Please create a new News item.",
      "copy_products": "Copy Related Products Information",
      "copy_rating": "Copy Rating Information",
      "description_html": "You can duplicate news across directories. <br />\nYou can select the language in which News text of each language is duplicated. <br/>\nThe country group is specified automatically when copying from a directory that does not deliver by language to a directory that does deliver by language. (This issue will be fixed in future updates.)",
      "from": "Duplication Source",
      "message_detail": "News Text",
      "title": "Duplicate News",
      "to": "Duplication Destination"
    },
    "edit": {
      "copy_commit": "Copy news from the default language and update",
      "discovery_title": "Edit Discovery News",
      "title": "Edit News"
    },
    "error": {
      "failed_to_fetch_products": "Failed to load product information. Reload the page to try again."
    },
    "form": {
      "decoration_on": "Enable",
      "destroy_message_detail": "Delete This Language Setting",
      "enable_to_access_outer_domains": "Allow access to domains other than the domain for the specified URL",
      "language_specific_settings": "Language-Specific Settings",
      "manage_country_groups": "Set Country Groups",
      "manage_news_icon": "Set the News Icon",
      "must_be_selected_at_least_one_country": "You must specify at least one country destination.",
      "pickup_function_on": "Enable",
      "require_country_groups": "Configure the country group to deliver to.",
      "require_news_icon_settings": "Configure the news icon.",
      "select_all_countries": "Select All Countries",
      "too_long_header": "%{header} cannot be displayed correctly on Nintendo Switch because there are three or more lines.",
      "unselect_all_countries": "Cancel Selection for All Countries"
    },
    "hints": {
      "essential_pickup_limit_should_be_up_to_1week": "We recommend selecting an assured feature expiration date and time that is about one week after the delivery start date and time.",
      "line_feed_position_may_not_be_accurate": "In some cases, the location of the carriage return will not be correct. Check for carriage return accuracy using the device.",
      "nested_tags_are_unsupported": "Nesting tags other than strong tags inside header tags is not supported in the preview or on Nintendo Switch. If there are nested tags, the preview display might not necessarily match the appearance on Nintendo Switch.",
      "only_support_chrome": "Preview only supports the Chrome browser. Other browsers may display the preview incorrectly.",
      "preview_area_emulate_nx_screen_size": "The preview display area is nearly identical to the display size for the device. Please reference the appearance.",
      "preview_area_is_scrollable": "Scrolling is possible for the display area."
    },
    "index": {
      "message_detail_subject": "News Subject",
      "title": "News List"
    },
    "new": {
      "copy_commit": "Copy news in the default language and commit",
      "discovery_title": "Create Discovery News",
      "title": "Create News"
    },
    "preview": {
      "entrance": "View on the Entrance Page",
      "news_list_item": "View in News List",
      "news_list_item_searched": "View Filtered News List",
      "news_list_item_unread": "View Unread News List",
      "pickup": "View Featured",
      "print_preview": "Print Preview",
      "related_channel_subscribe": "Sample Articles"
    },
    "show": {
      "back_to_censor": "Return to the News List That Is Under Nintendo Review",
      "back_to_tencent_censor": "Return to the News List That Is Under Tencent Review",
      "bookmark": "Bookmarks",
      "debug_button_column": "Debugging Features",
      "discovery_title": "Discovery News Details",
      "display_expiration_warning": "Even if you modify the news display expiration, the system cannot update content already stored on Nintendo Switch consoles.",
      "download_msgpack": "Download msgpack (for debugging)",
      "has_dirty_words": "Includes profanity. Fix the content to proceed with delivery.",
      "in_progress": "Profanity check in progress. The delivery operation cannot proceed until the check is complete.",
      "like": "Great!",
      "message_is_invalid": "This news item is triggering a data inconsistency for some reason. As a result, it cannot be duplicated and its status cannot be changed.",
      "no_nsa_list": "No recipient list is specified. Specify a recipient list before proceeding with the approval workflow.",
      "old_semantics_version_alert": "This News item is an earlier version, so some features might not be available. In addition, if you duplicate this News item, the version remains the same. For this reason, the duplicated News item will be a version that cannot use the latest features. To use the latest features, create a new News item instead of duplicating the existing one.",
      "online_archives": "Article Type",
      "title": "News Details",
      "unlike": "Oh."
    },
    "status": {
      "online_archives": "Article Type"
    },
    "tooltips": {
      "caption": "Specify whether to overlap the title display over the list image, on the entrance page.",
      "country_group": "Specify the country group to deliver to. Delivery countries are determined using Nintendo Account countries. You can exclude specific countries from delivery by clearing the check boxes for those countries.",
      "decoration_type": "This feature emphasizes the News article on the Entrance page.",
      "default_language": "When there is no text for the language specified in the system settings, you can specify a language to use instead.",
      "deletion_priority": "News items will be deleted starting from the MIDDLE selection until the end when the number of saved news items is exceeded.",
      "display_limit": "The time when received news will stop appearing. Specify a time when you need to strictly restrict the display period because of copyright or another reason.",
      "display_type": "The news display method. You can create NORMAL for regular news, LIST for featured news, or RANKING for ranking news.",
      "essential_pickup_limit": "The News item is assured to be featured during the specified period. However, it might not be featured if three or more News items are specified as assured to be featured. If fixed feature is enabled, this will be the period for the fixed feature.",
      "essential_priority": "Even if it was already viewed in addition to the assured feature, the item will continue to be featured during this period.",
      "expiration_time": "Specify the delivery end date and time. News will automatically become unavailable at that time.",
      "invalid_product": "Invalid products are removed from news items when you save.",
      "no_photography": "Specify whether to block news screenshots.",
      "note": "Enter additional summary information if any. This content will not be delivered.",
      "online_archives": "This indicates the article type and whether that article can be viewed on a console. Details page. Nothing is displayed if content cannot be displayed.",
      "pickup_limit": "The period during which news items are picked up by the entrance or News List page. The system automatically configures a setting of one week after the delivery start date and time.",
      "priority": "News may be displayed in the Featured column or Entrance if this feature is enabled.",
      "products": "Specify products to appear in the Related Products column.",
      "publishing_time": "Specify the delivery start date and time. News will automatically be delivered at that time.",
      "related_topics": "Specify channels to appear in the Related Channels column.",
      "searchable_application_ids": "Add the application ID for the related title. This makes it easier to display on the entrance page while playing this application.",
      "summary": "Description of news management. This content will not be delivered."
    }
  },
  "messages_survey": {
    "index": {
      "title": "News List"
    }
  },
  "msgpacks": {
    "index": {
      "disable_with": "Reload this page, if you want to download again.",
      "no_message_details": "No News Items",
      "select_help": "Get the latest %{max_msgpack_count} News items from all channels in all languages, if channel or language is not selected.",
      "title": "Message Pack for Testing"
    }
  },
  "ndids": {
    "index": {
      "current_role": "Current Role",
      "desc": "The NDIDs of users you can manage are listed. You can grant roles to the listed users.",
      "title": "Add User",
      "user_not_exist": "Not in the User List"
    }
  },
  "news_topics": {
    "edit": {
      "title": "Edit %{npns_topic_id}"
    },
    "index": {
      "title": "Manage News Delivery"
    },
    "show": {
      "title": "Details for %{npns_topic_id}"
    },
    "tooltips": {
      "countries": "Select the countries that will support the news item.",
      "description": "Set the gist of the news item.",
      "name": "Set the name that appears as the news source.",
      "news_icon_image": "Set the icon that appears as the news source.",
      "npns_topic_id": "通知に使うNPNSトピックIDとチャンネル名を選択します。",
      "publishing_time": "Specify the date and time for the news release.",
      "search_string": "FIXME",
      "service_status": "Specify the news service status."
    }
  },
  "nintendo_accounts": {
    "action": {
      "refresh": "Successfully updated the Nintendo Account: %{name}.",
      "success": "Successfully connected the Nintendo Account."
    },
    "confirm": {
      "destroy": "Are you sure you want to disconnect the Nintendo Account?"
    },
    "index": {
      "add": "Add Account",
      "destroy": "Remove Account",
      "follow_logout/html": "If you are signed in to a Nintendo Account, you may not be able to add the intended recipient account. Please sign out from the Nintendo Account site and add the account again.",
      "logout_html": "To Nintendo Account Page",
      "name": "Nickname",
      "title": "Manage Destination Nintendo Account"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007f890f976d60 /usr/local/bundle/gems/activesupport-6.1.7.9/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007f890f976e00 /usr/local/bundle/gems/activesupport-6.1.7.9/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "one2ones": {
    "edit": {
      "title": "Edit Targeted News"
    },
    "index": {
      "news_summary": "News Summary",
      "title": "Targeted News List"
    },
    "new": {
      "title": "Create Targeted News Item"
    },
    "show": {
      "title": "Details for Targeted News"
    }
  },
  "pickup_aggregations": {
    "action": {
      "notify_china": "Send a notification to devices for China",
      "notify_ounce": "Send a notification to Ounce devices",
      "notify_ww": "Send a notification to WW devices",
      "update_uuid": "Update UUID"
    },
    "index": {
      "title": "Featured Aggregation"
    },
    "show": {
      "title": "Featured Aggregation"
    }
  },
  "pickup_stats": {
    "index": {
      "title": "Aggregation: %{date}"
    }
  },
  "products": {
    "form": {
      "accepted": "Accepted. Processing may take several minutes.",
      "new_product_from_ns_uid": "Enter the NS UID to add products (software, bundles, DLC, Nintendo Switch Game Vouchers).",
      "not_found": "The specified product could not be found."
    },
    "index": {
      "invalid_product": "Product data is not valid.",
      "linked_messages": "Linked News List",
      "refresh": "Update",
      "title": "Nintendo eShop Product List"
    },
    "messages": {
      "index": {
        "title": "List of News linked to %{product}"
      }
    },
    "notice": {
      "down_pms_or_ncms": "News May Not Be Updated Properly",
      "maintenance_body/html": "The eShop server may be offline for maintenance or for other reasons. News, including videos, cannot be updated and eShop product information will not sync while the server is offline.",
      "maintenance_subject": "eShop Server Maintenance or Error"
    },
    "tooltips": {
      "platforms": "Platform of news channel where product can be linked as related item."
    }
  },
  "promotions": {
    "index": {
      "title": "Promotion Settings List"
    },
    "new": {
      "title": "Add Promotion Settings"
    }
  },
  "publishing_mailer": {
    "datafile": {
      "publishing_approval": {
        "body": "The data is approved for publication. \nDelivery starts at the scheduled delivery time.\n",
        "subject": "Notice of phase change for data item from BCAT (%{env}). (Approve Delivery: %{name})"
      },
      "publishing_unapproval": {
        "body": "The data delivery approval was canceled. \nTo start data delivery, you must approve data delivery again.\n",
        "subject": "Notice of phase change for data item from BCAT (%{env}). (Remove Approval: %{name})"
      }
    },
    "message": {
      "publishing_approval": {
        "body": "The news is approved for delivery. \nNews delivery starts at the scheduled delivery time.\n",
        "subject": "Notice of phase change for news item from BCAT (%{env}). (Approve Delivery: %{name})"
      },
      "publishing_unapproval": {
        "body": "The news delivery approval was canceled. \nTo start News delivery, you must approve News delivery again.\n",
        "subject": "Notice of phase change for news item from BCAT (%{env}). (Delivery Approval Removed: %{name})"
      }
    }
  },
  "ransack": {
    "all": "all",
    "and": "and",
    "any": "any",
    "asc": "ascending",
    "attribute": "attribute",
    "combinator": "combinator",
    "condition": "condition",
    "desc": "descending",
    "or": "or",
    "predicate": "predicate",
    "predicates": {
      "blank": "is blank",
      "cont": "contains",
      "cont_all": "contains all",
      "cont_any": "contains any",
      "does_not_match": "doesn't match",
      "does_not_match_all": "doesn't match all",
      "does_not_match_any": "doesn't match any",
      "end": "ends with",
      "end_all": "ends with all",
      "end_any": "ends with any",
      "eq": "equals",
      "eq_all": "equals all",
      "eq_any": "equals any",
      "false": "is false",
      "gt": "greater than",
      "gt_all": "greater than all",
      "gt_any": "greater than any",
      "gteq": "greater than or equal to",
      "gteq_all": "greater than or equal to all",
      "gteq_any": "greater than or equal to any",
      "in": "in",
      "in_all": "in all",
      "in_any": "in any",
      "lt": "less than",
      "lt_all": "less than all",
      "lt_any": "less than any",
      "lteq": "less than or equal to",
      "lteq_all": "less than or equal to all",
      "lteq_any": "less than or equal to any",
      "matches": "matches",
      "matches_all": "matches all",
      "matches_any": "matches any",
      "not_cont": "doesn't contain",
      "not_cont_all": "doesn't contain all",
      "not_cont_any": "doesn't contain any",
      "not_end": "doesn't end with",
      "not_end_all": "doesn't end with all",
      "not_end_any": "doesn't end with any",
      "not_eq": "not equal to",
      "not_eq_all": "not equal to all",
      "not_eq_any": "not equal to any",
      "not_in": "not in",
      "not_in_all": "not in all",
      "not_in_any": "not in any",
      "not_null": "is not null",
      "not_start": "doesn't start with",
      "not_start_all": "doesn't start with all",
      "not_start_any": "doesn't start with any",
      "null": "is null",
      "present": "is present",
      "start": "starts with",
      "start_all": "starts with all",
      "start_any": "starts with any",
      "true": "is true"
    },
    "search": "search",
    "sort": "sort",
    "value": "value"
  },
  "rating_systems": {
    "form": {
      "suggest": {
        "apply": "Use Recommended Rating System Settings",
        "label": "Configure Rating System Based on Target Country"
      }
    },
    "show": {
      "rating_icon_cache_cannot_be_found": "Rating icons cannot be found. Update the rating system."
    },
    "tooltip": {
      "suggest": "Configures the recommended rating system based on languages from the target country."
    }
  },
  "review_mailer": {
    "datafile": {
      "review_approval": {
        "body": "Pre-delivery review of a data file has been approved.",
        "subject": "Notice of phase change for data item from BCAT (%{env}). (Pre-Delivery Review Approval: %{name})"
      },
      "review_cancel": {
        "body": "Pre-delivery review of a data file has been canceled.",
        "subject": "Notice of status phase for data item from BCAT (%{env}). (Pre-Delivery Review Canceled: %{name})"
      },
      "review_rejection": {
        "body": "Pre-delivery review of a data item has been rejected.",
        "subject": "Notice of phase change for data item from BCAT (%{env}). (Pre-Delivery Review Rejection: %{name})"
      },
      "review_request": {
        "body": "Pre-delivery review of a data file has been requested.",
        "subject": "Notice of phase change for data item from BCAT (%{env}). (Pre-Delivery Review Request: %{name})"
      }
    },
    "message": {
      "review_approval": {
        "body": "Pre-delivery review of a news item has been approved.",
        "subject": "Notice of phase change for news item from BCAT (%{env}). (Pre-Delivery Review Approval: %{name})"
      },
      "review_cancel": {
        "body": "Pre-delivery review of a news item has been canceled.",
        "subject": "Notice of phase change for news item from BCAT (%{env}). (Pre-Delivery Review Canceled: %{name})"
      },
      "review_rejection": {
        "body": "Pre-delivery review of a news item has been rejected.",
        "subject": "Notice of phase change for news item from BCAT (%{env}). (Pre-Delivery Review Rejection: %{name})"
      },
      "review_request": {
        "body": "Pre-delivery review of a news item has been requested.",
        "subject": "Notice of phase change for news item from BCAT (%{env}). (Pre-Delivery Review Request: %{name})"
      }
    }
  },
  "role_request_mailer": {
    "no_company": "(Empty)",
    "no_group": "(Empty)",
    "role_request_approval": {
      "body": "The role request has been approved.",
      "subject": "Notice of approval of a role request from BCAT (%{env}). (%{name})"
    },
    "role_request_cancel": {
      "body": "The role request has been canceled.",
      "subject": "Notice of cancellation of a role request from BCAT (%{env}). (%{name})"
    },
    "role_request_creation": {
      "body": "A role request has been issued.",
      "subject": "Notice of a new role request from BCAT (%{env}). (%{name})"
    },
    "role_request_rejection": {
      "body": "The role request has been rejected.",
      "subject": "Notice of rejection of a role request from BCAT (%{env}). (%{name})"
    }
  },
  "role_requests": {
    "confirm": {
      "approve": "Are you sure you want to approve the role request?",
      "cancel": "Are you sure you want to cancel the role request?",
      "reject": "Are you sure you want to reject the role request?"
    },
    "index": {
      "actions": "Action",
      "current_role": "Current Role",
      "judged_date": "Date of Determination",
      "judged_manger": "Determined By",
      "request_approved": "Approved By: %{approver} (%{approved_at})",
      "request_cancelled": "Canceled By: %{approver} (%{approved_at})",
      "request_rejected": "Rejected By: %{approver} (%{approved_at})",
      "requested_date": "Request Date",
      "requested_role": "Requested Role",
      "requesting": "Requested",
      "role_requests": "Role Request",
      "status": "Status",
      "title": "Role Requests",
      "user_roles": "User Role"
    },
    "show": {
      "title": "Role Request Details"
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "labels": {
      "log_request": {
        "end_at": "Logging End Time",
        "start_at": "Logging Start Time"
      }
    },
    "no": "No",
    "required": {
      "html": "<span title=\"required\" class=\"required_mark\">[Required] </span>",
      "mark": "[Required] ",
      "text": "Required"
    },
    "yes": "Yes"
  },
  "stats": {
    "daily_reports": {
      "index": {
        "title": "Daily Reports"
      },
      "title": "Daily Reports"
    },
    "index": {
      "title": "Statistics"
    },
    "log_requests": {
      "index": {
        "before_two_hours": "You can specify a logging end time up to two hours before the current time.",
        "expiration_at": "Expiration Date",
        "maximum_request_period": "Logging time can be specified for up to 31 days. Use separate requests to specify logging time for longer periods.",
        "request": "Log Requests",
        "status": "Request Status",
        "target_period": "Target Period",
        "title": "Log Requests",
        "url": "Download URL"
      },
      "nothing": {
        "description": "Currently not available.",
        "title": "Log Requests"
      },
      "title": "Log Requests"
    }
  },
  "subscribes": {
    "index": {
      "title": "Automatic Subscription Settings List"
    },
    "new": {
      "title": "Add Automatic Subscription Settings"
    }
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "swagger-ui": {
    "daily_reports": {
      "operation": {
        "show": {
          "description": "Up to 50 logs may be requested at one time.",
          "summary": "Get Data Delivery Daily Report"
        }
      },
      "parameter": {
        "application_id": "Application ID",
        "date": "For example, 2017-03-03",
        "offset": "Offset"
      }
    },
    "data_topics": {
      "operation": {
        "show": {
          "description": "Get Topic Details<br/>npns\\_topic\\_id is nx\\_data\\_{Application ID}.",
          "summary": "Get Topic Details"
        }
      },
      "parameter": {
        "npns_topic_id": "Topic ID"
      }
    },
    "datafiles": {
      "operation": {
        "approve": {
          "description": "Approve Delivery Data",
          "summary": "Approve Delivery Data"
        },
        "create": {
          "description": "Create Delivery Data<br/>Set parameters with JSON. Specify binary as Base64-encoded text. Base64 data must follow RFC 2045. The date and time is specified in ISO 8601 format (YYYY-MM-DDThh:mm:ss.sZ). The fraction of a second found after the decimal point is truncated by the server. The time zone may be specified (for example, YYYY-MM-DDThh:mm:ss.s-08:00 for Pacific Time), but the value in the response body is always returned in UTC (YYYY-MM-DDThh:mm:ss.sZ).",
          "summary": "Create Delivery Data"
        },
        "create_next": {
          "description": "Create Next Scheduled Delivery Data<br/>Set parameters with JSON. Specify binary as Base64-encoded text. Base64 data must follow RFC 2045. The date and time is specified in ISO 8601 format (YYYY-MM-DDThh:mm:ss.sZ). The fraction of a second found after the decimal point is truncated by the server. The time zone may be specified (for example, YYYY-MM-DDThh:mm:ss.s-08:00 for Pacific Time), but the value in the response body is always returned in UTC (YYYY-MM-DDThh:mm:ss.sZ).",
          "summary": "Create Next Scheduled Delivery Data"
        },
        "delete": {
          "description": "Delete Delivery Data",
          "summary": "Delete Delivery Data"
        },
        "expire": {
          "description": "Expire Delivery Data",
          "summary": "Expire Delivery Data"
        },
        "index": {
          "description": "Get Delivery Data List",
          "summary": "Get Delivery Data List"
        },
        "raw": {
          "description": "Gets data that was added to the management tool.",
          "summary": "Get Added Data"
        },
        "show": {
          "description": "Get Delivery Data Details",
          "summary": "Get Delivery Data Details"
        },
        "unapprove": {
          "description": "Unapprove Delivery Data",
          "summary": "Unapprove Delivery Data"
        },
        "update": {
          "description": "Update Delivery Data<br/>Set parameters with JSON. Specify binary as Base64-encoded text. Base64 data must follow RFC 2045. The date and time is specified in ISO 8601 format (YYYY-MM-DDThh:mm:ss.sZ). The fraction of a second found after the decimal point is truncated by the server. The time zone may be specified (for example, YYYY-MM-DDThh:mm:ss.s-08:00 for Pacific Time), but the value in the response body is always returned in UTC (YYYY-MM-DDThh:mm:ss.sZ).",
          "summary": "Update Delivery Data"
        }
      },
      "parameter": {
        "application_id": "Application ID",
        "datafile_id": "Delivery Data ID",
        "datafile_params": "Parameters",
        "directory_id": "Directory ID"
      }
    },
    "devices": {
      "operation": {
        "create": {
          "description": "Register Test Delivery Device",
          "summary": "Register Test Delivery Device"
        },
        "destroy": {
          "description": "Delete Test Delivery Device",
          "summary": "Delete Test Delivery Device"
        },
        "index": {
          "description": "Get Test Delivery Device List",
          "summary": "Get Test Delivery Device List"
        },
        "show": {
          "description": "Get Test Delivery Device Details",
          "summary": "Get Test Delivery Device Details"
        },
        "update": {
          "description": "Update Test Delivery Device",
          "summary": "Update Test Delivery Device"
        }
      },
      "parameter": {
        "application_id": "Application ID",
        "device_id": "Delivery Test Device ID",
        "post_parameters": "Parameters"
      }
    },
    "directories": {
      "operation": {
        "create": {
          "description": "Create Directory<br/>Set parameters with JSON.",
          "summary": "Create Directory"
        },
        "delete": {
          "description": "Delete Directory",
          "summary": "Delete Directory"
        },
        "index": {
          "description": "Get Directory List",
          "summary": "Get Directory List"
        },
        "show": {
          "description": "Get Directory Details",
          "summary": "Get Directory Details"
        },
        "update": {
          "description": "Update Directory<br/>Set parameters with JSON.",
          "summary": "Update Directory"
        }
      },
      "parameter": {
        "application_id": "Application ID",
        "directory_id": "Directory ID",
        "directory_params": "Parameters"
      }
    },
    "ip_restrictions": {
      "operation": {
        "create": {
          "description": "Register IP Address Restriction",
          "summary": "Register IP Address Restriction"
        },
        "destroy": {
          "description": "Delete IP Address Restriction",
          "summary": "Delete IP Address Restriction"
        },
        "index": {
          "description": "Get IP Address Restriction List",
          "summary": "Get IP Address Restriction List"
        },
        "show": {
          "description": "Get IP Address Restriction Details",
          "summary": "Get IP Address Restriction Details"
        },
        "update": {
          "description": "Update IP Address Restriction",
          "summary": "Update IP Address Restriction"
        }
      },
      "parameter": {
        "application_id": "Application ID",
        "ip_restriction_id": "IP Address Restriction ID",
        "post_parameters": "Parameters"
      }
    },
    "news": {
      "description": {
        "/news/{id}": {
          "delete": "Removes the news item with the specified ID.",
          "get": "Gets data for the news item with the specified ID.",
          "put": "Updates data for the news item with the specified ID."
        },
        "/titles/{application_id}/news": {
          "get": "Gets all news linked to applications with the specified application_id.",
          "post": "Creates a new news item linked to the application with the specified application_id."
        }
      },
      "parameter": {
        "age_limit": "News Rating",
        "application_id": "Application ID",
        "bashotorya": "Flag indicating whether the news is a placeholder notification. The news is a placeholder notification when this value is 1.",
        "country_group_id": "The target country group for news delivery. You must set the country_group_id when the application specified with the application_id has more than one country group.",
        "default_language": "News Default Language",
        "delivery_type": "Delivery Type",
        "display_type": "News Display Type",
        "expiration_time": "Delivery End Time",
        "format_version": "News Format Version",
        "id": "News ID",
        "more_type": "See More Types",
        "no_photography": "Flag indicating whether screenshots are allowed. Users are not allowed to take news screenshots when this value is 1.",
        "note": "News description that appears on the Management page.",
        "pickup_limit": "Ending time for news to fall within the pickup term.",
        "priority": "News Priority",
        "publishing_time": "Delivery Start Time",
        "related_products": "The application id array for displaying related products.",
        "searchable_application_ids": "The application id array. Used when users are searching for news for a specific application.",
        "semantics_version": "News semantics version. Used to determine whether the news can be handled by the client's firmware.",
        "summary": "News Summary",
        "surprise": "Flag indicating whether the news is a surprise notification. The news is a surprise notification when this value is 1."
      }
    },
    "news_topics": {
      "description": {
        "base64_encoded_contents": "Base64-Encoded Image Binary",
        "channel_desc": "Channel Description",
        "channel_lang": "Channel Language",
        "channel_name": "Channel Name",
        "channel_publisher": "Publisher Name",
        "company_uuid": "The NDID UUID of the company that applied for the channel",
        "countries": "Delivery Target Countries",
        "default_language": "Default Language",
        "file_name": "Filename",
        "file_size": "File Size",
        "first_party_publisher": "First Party Publisher or Not",
        "languages": "Delivery Languages",
        "manager": "Manager",
        "news_icon_base64": "Base64-Encoded Channel Icon",
        "news_icon_image": "Channel Icon Image",
        "news_type": "News Type",
        "official_news": "Official News or Not",
        "policy": "Policy Setup Parameters",
        "publishing_time": "Channel Release Date and Time",
        "user_email": "NDID Email Address",
        "user_loginid": "NDID Login ID",
        "user_name": "NDID Name",
        "user_uuid": "NDID UUID"
      },
      "operation": {
        "create": {
          "description": "Create News Topic<br/>Set parameters with JSON.",
          "summary": "Create News Topic"
        },
        "show": {
          "description": "Get News Topic Details",
          "summary": "Get News Topic Details"
        },
        "update": {
          "description": "Update News Topic",
          "summary": "Update News Topic"
        }
      },
      "parameter": {
        "news_topic_params": "Parameters",
        "npns_topic_id": "NPNS Topic ID"
      }
    },
    "promotions": {
      "operation": {
        "create": {
          "description": "Create Promotion<br/>Set parameters with JSON.",
          "summary": "Create Promotion"
        },
        "destroy": {
          "description": "Delete Promotion",
          "summary": "Delete Promotion"
        },
        "news_topics_index": {
          "description": "Get News Topic’s Promotion List",
          "summary": "Get News Topic’s Promotion List"
        },
        "show": {
          "description": "Get Promotion Details",
          "summary": "Get Promotion Details"
        },
        "titles_index": {
          "description": "Get Application's Promotion List",
          "summary": "Get Application's Promotion List"
        }
      },
      "parameter": {
        "application_id": "Application ID",
        "npns_topic_id": "NPNS Topic ID",
        "promotion_id": "Promotion ID",
        "promotion_params": "Parameters"
      }
    },
    "subscribes": {
      "operation": {
        "create": {
          "description": "Create Subscription<br/>Set parameters with JSON.",
          "summary": "Create Subscription"
        },
        "destroy": {
          "description": "Delete Subscription",
          "summary": "Delete Subscription"
        },
        "news_toics_index": {
          "description": "Get News Topic's Subscription List",
          "summary": "Get News Topic's Subscription List"
        },
        "news_topics_index": {
          "description": "Get News Topic's Subscription List",
          "summary": "Get News Topic's Subscription List"
        },
        "show": {
          "description": "Get Subscription Details",
          "summary": "Get Subscription Details"
        },
        "titles_index": {
          "description": "Get Application's Subscription List",
          "summary": "Get Application's Subscription List"
        }
      },
      "parameter": {
        "application_id": "Application ID",
        "npns_topic_id": "NPNS Topic ID",
        "subscribe_id": "Subscription ID",
        "subscribe_params": "Parameters"
      }
    },
    "test_distributions": {
      "operation": {
        "create": {
          "description": "Create Test Delivery<br>Set parameters with JSON. You can specify the information that you have previously registered in the management tool for the test delivery target Switch console and the Nintendo Account. An error occurs if the Switch console or Nintendo Account is not registered in the management tool. Specify the serial number of the test delivery target Switch console and the id array of the Nintendo Account. Specify the data to include in the test delivery using the datafile id array.",
          "summary": "Create New Test Delivery"
        },
        "destroy": {
          "description": "Delete Test Delivery",
          "summary": "Delete Test Delivery"
        },
        "index": {
          "description": "Get Test Delivery List",
          "summary": "Get Test Delivery List"
        },
        "notify": {
          "description": "Test Delivery Notification<br>The server automatically sends notifications when a test delivery is created or updated. You can use this function to send explicit notifications after creating or updating test delivery.",
          "summary": "Test Delivery Notification"
        },
        "show": {
          "description": "Get Test Delivery Details",
          "summary": "Get Test Delivery Details"
        },
        "update": {
          "description": "Update Test Delivery<br>Specify parameters with JSON. You can specify the information that you have previously registered in the management tool for the test delivery target Switch console and the Nintendo Account. An error occurs if the Switch console or Nintendo Account is not registered in the management tool. Specify the serial number of the test delivery target Switch console, and the ID array of the Nintendo Account (described in the management tool). Specify the data to include in the test delivery using the datafile ID array.",
          "summary": "Update Test Delivery"
        }
      },
      "parameter": {
        "application_id": "Application ID",
        "post_parameters": "Parameters",
        "test_distribution_id": "Test Delivery ID"
      }
    },
    "titles": {
      "operation": {
        "create": {
          "description": "Create Application<br/>Set parameters with JSON.",
          "summary": "Create Application"
        },
        "show": {
          "description": "Get Application Details",
          "summary": "Get Application Details"
        }
      },
      "parameter": {
        "application_id": "Application ID",
        "initial_code": "Initial Code",
        "name": "Name",
        "title_params": "Parameters"
      }
    }
  },
  "target_list_histories": {
    "index": {
      "title": "Recipient NSA List History"
    },
    "show": {
      "notification_histories": "Notification History",
      "title": "Recipient NSA List Details"
    }
  },
  "target_lists": {
    "action": {
      "replace": "Replace Recipient NSA List"
    },
    "edit": {
      "title": "Edit Recipient NSA List"
    },
    "new": {
      "title": "Set Recipient NSA List"
    },
    "show": {
      "latest_job_status": "Status of Last Notification",
      "no_jobs": "This news item has not sent a notification.",
      "notification_histories": "Notification History",
      "title": "Recipient NSA List Details"
    }
  },
  "tencent_censors": {
    "data": {
      "title": "Tencent Review Queue for Data List"
    },
    "news": {
      "title": "Tencent Review Queue for News List"
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "titles": {
    "edit": {
      "title": "Edit %{title_name}"
    },
    "hints": {
      "system_bcat_false": "For system titles, specify false unless otherwise specified.",
      "system_bcat_true": "For system titles, specify true unless otherwise specified."
    },
    "index": {
      "manage_topic": "Manage Topics",
      "new_application": "Create Application",
      "title": "Application List"
    },
    "manage_bcat_application": {
      "approved_and_latest_bcat_application": "Approved and re-sent BCAT usage requests.",
      "fetch_and_apply_bcat_application": "Get and Apply BCAT Usage Requests",
      "item_name": "Item Name",
      "item_value": "Item Value",
      "title": "Manage BCAT Usage Requests",
      "unable_to_find_available_bcat_application": "Cannot find an available BCAT usage request."
    },
    "manage_role": {
      "title": "User List"
    },
    "my_role": {
      "current_role": "Current Role",
      "role_request_desc": "To change your role, select the role type and send a request to a manager. When a manager accepts your request, you will be assigned to the role.",
      "title": "My Role"
    },
    "new": {
      "title": "Create Application"
    },
    "show": {
      "manage_data": "Manage Delivery Data",
      "manage_topic": "Manage Topics",
      "title": "Details for %{title_name}"
    },
    "subscribes": {
      "index_title": {
        "all_description": "You can add an application for subscription settings.",
        "title": "Create an application for subscription settings."
      }
    },
    "tooltips": {
      "data_size_limit": "4 MiB is allocated as the management region.",
      "promotions": {
        "title": "Select a title for subscription settings."
      },
      "subscribes": {
        "title": "Select a title for subscription settings."
      }
    }
  },
  "top": {
    "index": {
      "disabled_user": "Your account has been deactivated.",
      "recently_updated_items": "Recently Updated Items",
      "title": "Top Page"
    }
  },
  "users": {
    "_role_navi": {
      "general_news_roles": "General News Roles",
      "role_requests": "Role Request",
      "service_roles": "Service Roles",
      "title_roles": "Application Roles"
    },
    "channel_roles": {
      "name": "Name",
      "request_role": "Requested Role",
      "requesting": "Requested",
      "role": "Current Role",
      "roles": "Role"
    },
    "edit": {
      "title": "Edit Users"
    },
    "general_news_roles": {
      "title": "News Roles"
    },
    "index": {
      "actions": "Action",
      "detail": "Details",
      "edit": "Edit",
      "roles": "Role",
      "title": "User List"
    },
    "my_roles": "My Role",
    "new": {
      "title": "Create User"
    },
    "role_requests": {
      "actions": "Action",
      "company_name": "Company Name",
      "current_role": "Current Role",
      "group_name": "Group Name",
      "judged_date": "Date of Determination",
      "judged_user": "Determined By",
      "requested_date": "Request Date",
      "requested_role": "Requested Role",
      "role_requests": "Role Request",
      "status": "Status",
      "title": "Role Requests",
      "user_name": "User Name"
    },
    "roles": "Role",
    "service_roles": {
      "actions": "Action",
      "attach": "Assign",
      "attach_status": "Assigned Status",
      "description": "Description",
      "remove": "Delete",
      "roles": "Role",
      "service_roles": "Service Roles",
      "title": "Service Roles"
    },
    "show": {
      "enqueue_sync_permissions": "Request Permissions Synchronization With OMAS",
      "failed_to_recieve_sync_permissions": "The synchronization request failed. Please try again later.",
      "recieve_sync_permissions": "The synchronization request has been received. Synchronization may take some time to complete.",
      "title": "My Profile"
    },
    "title_roles": {
      "title": "Application Roles"
    }
  },
  "views": {
    "pagination": {
      "first": "&laquo;",
      "last": "&raquo;",
      "next": "&rsaquo;",
      "previous": "&lsaquo;",
      "truncate": "..."
    }
  },
  "virtual_files": {
    "action": {
      "add_down": "Add After",
      "add_up": "Add Before"
    },
    "confirm": {
      "destroy_warning": "Are you sure you want to delete this delivery data?",
      "destroy_warning_if_published": "We recommend that you keep delivery data that has been sent for analysis purposes. \nAre you sure you want to delete this delivery data?\n"
    },
    "edit": {
      "title": "Delivery Data"
    },
    "form": {
      "add_datafile": "Add Delivery Data",
      "include_expired": "Include Expired Items"
    },
    "index": {
      "title": "Delivery Data"
    },
    "new": {
      "title": "New Delivery Data"
    },
    "show": {
      "next": "Next",
      "previous": "Previous",
      "title": "Delivery Data Details"
    }
  }
});
I18n.translations["en-GB"] = I18n.extend((I18n.translations["en-GB"] || {}), {
  "nx": {
    "channel_discription": {
      "expiration_announcement": "This channel closed on %{day}/%{month}/%{year} (UTC). You can manually unfollow the channel at any time.",
      "expiration_pre_announcement": "This channel will close on %{day}/%{month}/%{year} (UTC). From that date onwards, you will not be able to view any articles from this channel. You can manually unfollow the channel at any time."
    },
    "default_link_text": {
      "browser": "",
      "eshop": {
        "aoc_list": "Check out the DLC now",
        "consumption": "",
        "membership_service": "",
        "membership_top": "Visit Nintendo Switch Online eShop page",
        "preticket_detail": "Visit Nintendo eShop ",
        "product_detail": "Visit Nintendo eShop ",
        "product_list": "",
        "sale": "See Current Offers ",
        "subscriptions": "",
        "top": "Visit Nintendo eShop "
      },
      "game": "Start software"
    },
    "description_of_contents_excuse": "",
    "description_of_distribution_area": {
      "au_nz": "This news is for Nintendo Accounts in Australia and New Zealand.",
      "eur": "This news is for Nintendo Accounts in Europe.",
      "eur_au": "This news is for Nintendo Accounts in Europe and Australia.",
      "eur_au_nz": "This news is for Nintendo Accounts in Europe, Australia and New Zealand.",
      "eur_nz": "This news is for Nintendo Accounts in Europe and New Zealand."
    },
    "description_of_nso_precaution": "Internet connection required during online play. To use online services, you must create a Nintendo Account and accept the related agreement. The Nintendo Account Privacy Policy applies. Some online services may not be available in all countries. Online play requires paid online membership: Nintendo Switch Online. Find out more about the paid membership service (URL:https://www.nintendo.co.uk/Nintendo-Switch/Nintendo-Switch-Online/Nintendo-Switch-Online-1183143.html).",
    "product": {
      "aoc": "Downloadable Content",
      "bundle": "Bundle",
      "new": "NEW",
      "pre_order": {
        "above_v8": "Pre-order",
        "below_v7": "Pre-order"
      },
      "pre_purchase": "pre-order",
      "title": "Software",
      "upgrade_pass": "UpgradePass"
    },
    "suggest_product_text": {
      "demo": "DEMO"
    }
  }
});
I18n.translations["en-US"] = I18n.extend((I18n.translations["en-US"] || {}), {
  "nx": {
    "channel_discription": {
      "expiration_announcement": "[Publisher] closed this channel on [%{month}/%{day}/%{year} (UTC)]. You can find the full list of news channels by selecting News from the HOME Menu.",
      "expiration_pre_announcement": "[Publisher] will be closing this news channel on [%{month}/%{day}/%{year} (UTC)] , and articles will no longer be available to view. Thank you for following this channel."
    },
    "default_link_text": {
      "browser": "",
      "eshop": {
        "aoc_list": "Learn more on Nintendo eShop",
        "consumption": "",
        "membership_service": "",
        "membership_top": "Learn more about Nintendo Switch Online",
        "preticket_detail": "Learn more on Nintendo eShop",
        "product_detail": "Learn more on Nintendo eShop",
        "product_list": "Learn more on Nintendo eShop",
        "sale": "",
        "subscriptions": "",
        "top": "Learn more on Nintendo eShop"
      },
      "game": ""
    },
    "description_of_contents_excuse": "",
    "description_of_distribution_area": {
      "publisher_usa": "This news is distributed by %{publisher} for our Nintendo Account holders in the Americas.",
      "usa": "This news is created for our Nintendo Account holders in the Americas."
    },
    "description_of_nso_precaution": "Nintendo Switch Online membership (sold separately) and Nintendo Account required for online features. Not available in all countries. Internet access required for online features. Terms apply. Learn more at https://www.nintendo.com/switch-online.",
    "product": {
      "aoc": "Downloadable Content",
      "bundle": "Bundle",
      "new": "NEW",
      "pre_order": {
        "above_v8": "Pre-order",
        "below_v7": "Pre-order"
      },
      "pre_purchase": "Pre-purchase",
      "title": "Software",
      "upgrade_pass": "UpgradePass"
    },
    "suggest_product_text": {
      "demo": "Demo"
    }
  }
});
I18n.translations["es"] = I18n.extend((I18n.translations["es"] || {}), {
  "nx": {
    "channel_discription": {
      "expiration_announcement": "Este canal se cerró el %{day}-%{month}-%{year} (UTC). Puedes dejar de seguir el canal en cualquier momento.",
      "expiration_pre_announcement": "Este canal se cerrará el %{day}-%{month}-%{year} (UTC). A partir de esa fecha, ya no se podrán ver sus noticias. Puedes dejar de seguir el canal en cualquier momento."
    },
    "default_link_text": {
      "browser": "",
      "eshop": {
        "aoc_list": "Ver el contenido descargable",
        "consumptions": "",
        "membership_service": "",
        "membership_top": "Ir a la página de Nintendo Switch Online en Nintendo eShop",
        "preticket_detail": "Visita Nintendo eShop ",
        "product_detail": "Visita Nintendo eShop ",
        "product_list": "",
        "sale": "Ver las ofertas actuales ",
        "subscriptions": "",
        "top": "Visita Nintendo eShop "
      },
      "game": "Iniciar aplicación"
    },
    "description_of_contents_excuse": "",
    "description_of_distribution_area": {
      "au_nz": "Noticia relativa a las cuentas Nintendo de Australia y Nueva Zelanda.",
      "eur": "Noticia relativa a las cuentas Nintendo europeas.",
      "eur_au": "Noticia relativa a las cuentas Nintendo de Europa y Australia.",
      "eur_au_nz": "Noticia relativa a las cuentas Nintendo de Europa, Australia y Nueva Zelanda.",
      "eur_nz": "Noticia relativa a las cuentas Nintendo de Europa y Nueva Zelanda."
    },
    "description_of_nso_precaution": "Para poder jugar en línea se requieren una cuenta Nintendo y una suscripción a Nintendo Switch Online (se vende por separado). No disponible en todos los países. Se requiere acceso a internet para acceder a los servicios en línea. Se aplican términos de uso. Para ver más información visita https://www.nintendo.com/switch-online.",
    "product": {
      "aoc": "Contenido descargable",
      "bundle": "Lote",
      "new": "Nuevo",
      "pre_order": {
        "above_v8": "Reserva",
        "below_v7": "Reserva"
      },
      "pre_purchase": "reserva",
      "title": "Programas",
      "upgrade_pass": "UpgradePass"
    },
    "suggest_product_text": {
      "demo": "DEMO"
    }
  }
});
I18n.translations["es-419"] = I18n.extend((I18n.translations["es-419"] || {}), {
  "nx": {
    "channel_discription": {
      "expiration_announcement": "[Publisher] cerró el canal el [%{day}/%{month}/%{year} (UTC)]. Podrás encontrar la lista completa de canales al seleccionar \"Noticias\" en el menú HOME.",
      "expiration_pre_announcement": "[Publisher] cerró el canal el [%{day}/%{month}/%{year} (UTC)]. Podrás encontrar la lista completa de canales al seleccionar \"Noticias\" en el menú HOME."
    },
    "default_link_text": {
      "browser": "",
      "eshop": {
        "aoc_list": "Más información en Nintendo eShop",
        "consumptions": "",
        "membership_service": "",
        "membership_top": "Conoce los detalles acerca de Nintendo Switch Online",
        "preticket_detail": "Más información en Nintendo eShop",
        "product_detail": "Más información en Nintendo eShop",
        "product_list": "Más información en Nintendo eShop",
        "sale": "",
        "subscriptions": "",
        "top": "Más información en Nintendo eShop"
      },
      "game": ""
    },
    "description_of_contents_excuse": "",
    "description_of_distribution_area": {
      "publisher_usa": "Estas noticias son distribuidas por %{publisher} para nuestros usuarios con cuentas Nintendo en el continente americano.",
      "usa": "Noticia relativa a las cuentas Nintendo del continente americano."
    },
    "description_of_nso_precaution": "Se requiere una suscripción a Nintendo Switch Online (se vende por separado) y una cuenta Nintendo para el juego en línea. No disponible en todos los países. Se requiere una conexión a internet para acceder a los servicios en línea. Aplican términos de uso. nintendo.com/switch-online.",
    "description_of_rating_excuse": "La clasificación ESRB es válida en EE. UU. y CANADÁ \nEl Sistema Mexicano de Equivalencias de Clasificación de Videojuegos es válido en MÉXICO.",
    "product": {
      "aoc": "Contenido descargable",
      "bundle": "Paquete",
      "new": "Nuevo",
      "pre_order": {
        "above_v8": "Reserva",
        "below_v7": "Reserva"
      },
      "pre_purchase": "Preventa",
      "title": "Programas",
      "upgrade_pass": "UpgradePass"
    },
    "suggest_product_text": {
      "demo": "Demo"
    }
  }
});
I18n.translations["fr"] = I18n.extend((I18n.translations["fr"] || {}), {
  "nx": {
    "channel_discription": {
      "expiration_announcement": "Cette chaîne est fermée depuis le %{day}/%{month}/%{year} (UTC). Vous pouvez cesser de suivre la chaîne à tout moment.",
      "expiration_pre_announcement": "Cette chaîne fermera le %{day}/%{month}/%{year} (UTC). Après cette date, vous ne pourrez plus consulter les nouvelles publiées sur cette chaîne. Vous pouvez cesser de suivre la chaîne à tout moment."
    },
    "default_link_text": {
      "browser": "",
      "eshop": {
        "aoc_list": "Voir le DLC maintenant",
        "consumption": "",
        "membership_service": "",
        "membership_top": "Visiter la page Nintendo Switch Online du Nintendo eShop",
        "preticket_detail": "Visitez le Nintendo eShop ",
        "product_detail": "Visitez le Nintendo eShop ",
        "product_list": "",
        "sale": "Voir les offres actuelles ",
        "subscriptions": "",
        "top": "Visitez le Nintendo eShop "
      },
      "game": "Démarrer le logiciel"
    },
    "description_of_contents_excuse": "",
    "description_of_distribution_area": {
      "au_nz": "Cette nouvelle concerne les comptes Nintendo australiens et néo-zélandais.",
      "eur": "Cette nouvelle concerne les comptes Nintendo européens.",
      "eur_au": "Cette nouvelle concerne les comptes Nintendo européens et australiens.",
      "eur_au_nz": "Cette nouvelle concerne les comptes Nintendo européens, australiens et néo-zélandais.",
      "eur_nz": "Cette nouvelle concerne les comptes Nintendo européens et néo-zélandais."
    },
    "description_of_nso_precaution": "Connexion Internet requise durant le jeu. Pour utiliser les services en ligne, vous devez avoir créé un compte Nintendo et avoir accepté les termes du contrat relatif au compte Nintendo. La politique de confidentialité du compte Nintendo s'applique. Certains services en ligne pourraient ne pas être accessibles dans tous les pays. Le jeu en ligne requiert une souscription payante : Nintendo Switch Online. En savoir plus sur la souscription payante du service en ligne (URL:https://www.nintendo.fr/Nintendo-Switch/Nintendo-Switch-Online/Nintendo-Switch-Online-1183143.html).",
    "product": {
      "aoc": "Contenu additionnel",
      "bundle": "Lot",
      "new": "Nouveau",
      "pre_order": {
        "above_v8": "Précommande",
        "below_v7": "Précommande"
      },
      "pre_purchase": "précommande",
      "title": "Logiciel",
      "upgrade_pass": "UpgradePass"
    },
    "suggest_product_text": {
      "demo": "Démo"
    }
  }
});
I18n.translations["fr-CA"] = I18n.extend((I18n.translations["fr-CA"] || {}), {
  "nx": {
    "channel_discription": {
      "expiration_announcement": "[Publisher] a fermé cette chaîne le [%{day}-%{month}-%{year} (UTC)]. Vous pouvez consulter la liste complète de chaînes de nouvelles en sélectionnant l'icône Nouvelles depuis le menu HOME.",
      "expiration_pre_announcement": "[Publisher] fermera cette chaîne le [%{day}-%{month}-%{year} (UTC)], et les articles qui la composent ne pourront plus être consultés. Nous vous remercions de votre fidélité."
    },
    "default_link_text": {
      "browser": "",
      "eshop": {
        "aoc_list": "Plus de détails dans Nintendo eShop",
        "consumption": "",
        "membership_service": "",
        "membership_top": "Plus de détails à propos de Nintendo Switch Online",
        "preticket_detail": "Plus de détails dans Nintendo eShop",
        "product_detail": "Plus de détails dans Nintendo eShop",
        "product_list": "Plus de détails dans Nintendo eShop",
        "sale": "",
        "subscriptions": "",
        "top": "Plus de détails dans Nintendo eShop"
      },
      "game": ""
    },
    "description_of_contents_excuse": "",
    "description_of_distribution_area": {
      "publisher_usa": "Ces nouvelles sont distribuées par %{publisher} à l'intention des titulaires de comptes Nintendo des Amériques.",
      "usa": "Ces nouvelles sont créées à l'intention des titulaires de comptes Nintendo des Amériques."
    },
    "description_of_nso_precaution": "Le jeu en ligne nécessite un abonnement au service Nintendo Switch Online (vendu séparément) et un compte Nintendo. Pas offert dans tous les pays. Une connexion Internet est nécessaire pour accéder aux fonctions en ligne. Des termes s'appliquent. Plus de détails au https://www.nintendo.com/fr_CA/switch/online-service/.",
    "product": {
      "aoc": "Contenu téléchargeable",
      "bundle": "Ensemble",
      "new": "NOUVEAU",
      "pre_order": {
        "above_v8": "Précommande",
        "below_v7": "Précommande"
      },
      "pre_purchase": "Préachat",
      "title": "Logiciel",
      "upgrade_pass": "UpgradePass"
    },
    "suggest_product_text": {
      "demo": "Démo"
    }
  }
});
I18n.translations["it"] = I18n.extend((I18n.translations["it"] || {}), {
  "nx": {
    "channel_discription": {
      "expiration_announcement": "Questo canale ha chiuso in data %{day}/%{month}/%{year} (UTC).Puoi smettere di seguire questo canale in qualsiasi momento.",
      "expiration_pre_announcement": "Questo canale chiuderà in data %{day}/%{month}/%{year} (UTC). Da quel giorno in poi, non sarà più possibile visualizzarne gli articoli. Puoi smettere di seguire questo canale in qualsiasi momento."
    },
    "default_link_text": {
      "browser": "",
      "eshop": {
        "aoc_list": "Guarda il contenuto scaricabile",
        "consumption": "",
        "membership_service": "",
        "membership_top": "Visita la pagina di Nintendo Switch Online nell'eShop",
        "preticket_detail": "Visita il Nintendo eShop ",
        "product_detail": "Visita il Nintendo eShop ",
        "product_list": "",
        "sale": "Offerte in corso ",
        "subscriptions": "",
        "top": "Visita il Nintendo eShop "
      },
      "game": "Avvia software"
    },
    "description_of_contents_excuse": "",
    "description_of_distribution_area": {
      "au_nz": "Queste sono notizie per gli account Nintendo in Australia e Nuova Zelanda.",
      "eur": "Queste sono notizie per gli account Nintendo europei.",
      "eur_au": "Queste sono notizie per gli account Nintendo in Europa e Australia.",
      "eur_au_nz": "Queste sono notizie per gli account Nintendo in Europa, Australia, Nuova Zelanda.",
      "eur_nz": "Queste sono notizie per gli account Nintendo in Europa e Nuova Zelanda."
    },
    "description_of_nso_precaution": "Il gioco online richiede una connessione a Internet. Per usufruire dei servizi online devi creare un account Nintendo e accettare il relativo Accordo. Si applica la Policy sulla privacy dell'account Nintendo. Alcuni servizi online potrebbero non essere disponibili in tutti i paesi. Il gioco online richiede un servizio con iscrizione a pagamento: Nintendo Switch Online. Scopri di più (URL:https://www.nintendo.it/Nintendo-Switch/Nintendo-Switch-Online/Nintendo-Switch-Online-1183143.html).",
    "product": {
      "aoc": "Contenuti scaricabili",
      "bundle": "Pacchetto",
      "new": "Novità",
      "pre_order": {
        "above_v8": "Preordine",
        "below_v7": "Preordine"
      },
      "pre_purchase": "preordine",
      "title": "Software",
      "upgrade_pass": "UpgradePass"
    },
    "suggest_product_text": {
      "demo": "DEMO"
    }
  }
});
I18n.translations["ja"] = I18n.extend((I18n.translations["ja"] || {}), {
  "ab_tests": {
    "edit": {
      "title": "ABテストニュースの編集"
    },
    "form": {
      "confirm": "配信中のニュースが紐付いているため、即座にユーザに反映される可能性があります。配信中のニュースとシリアルを紐付けた場合、今後変更はできません。本当によろしいですか？",
      "no_send": "このシリアルナンバーの本体にはニュースを送らない",
      "serial": "配信対象本体のシリアルナンバー: %{number}",
      "serial_description": "配信対象のシリアルナンバーはSwitch本体のシリアルナンバーの左から13桁目の値です。"
    },
    "index": {
      "title": "ABテストニュース一覧"
    },
    "new": {
      "title": "ABテストニュースの新規作成"
    },
    "show": {
      "title": "ABテストニュースの詳細"
    }
  },
  "active_flag": {
    "label_platform": {
      "nx": "NX",
      "ounce": "Ounce"
    },
    "platforms": {
      "nx": "NX"
    }
  },
  "activerecord": {
    "attributes": {
      "ab_test": {
        "id": "ABテストID",
        "messages": "紐付いているニュース",
        "serials": "シリアルによる振り分け",
        "summary": "概要"
      },
      "activity_log": {
        "action": "アクション",
        "actions": {
          "approve_censor": "任天堂レビューの承認",
          "approve_publishing": "配信の承認",
          "approve_review": "配信前レビュー承認",
          "approve_review_and_request_censor": "配信前レビューの承認と任天堂レビューの依頼",
          "cancel_censor": "任天堂レビューの取り下げ",
          "cancel_review": "配信前レビューの取り下げ",
          "create": "新規作成",
          "destroy": "削除",
          "duplicate": "複製",
          "expire": "配信の停止",
          "modify": "内容の修正",
          "publish": "配信を開始",
          "reject_censor": "任天堂レビューの却下",
          "reject_review": "配信前レビューの却下",
          "request_censor": "任天堂レビューの依頼",
          "request_review": "配信前レビューの依頼",
          "unapprove": "承認の取り消しと内容の修正",
          "update": "更新"
        },
        "channel": "チャンネル",
        "controller": "コントローラ",
        "created_at": "操作日時",
        "diff": "差分",
        "general_news": "ニュース",
        "link": "リンク",
        "resource": "リソース",
        "resource_name": "リソースの名前",
        "resource_type": "リソースの種類",
        "title": "アプリケーション",
        "user": "ユーザ"
      },
      "aging": {
        "base_deliverable_id": "複製元データの選択",
        "end_time": "終了時刻",
        "interval": "配信間隔(秒)",
        "start_time": "開始時刻"
      },
      "announcement": {
        "body": "本文",
        "body_en": "本文(英語)",
        "body_ja": "本文(日本語)",
        "expired": "公開終了？",
        "id": "お知らせID",
        "publishing_date": "公開日",
        "subject": "タイトル",
        "subject_en": "タイトル(英語)",
        "subject_ja": "タイトル(日本語)"
      },
      "api_account": {
        "created_at": "作成日",
        "name": "アカウント名"
      },
      "api_key": {
        "client_id": "クライアントID",
        "client_secret": "クライアントシークレット",
        "created_at": "作成日",
        "used_at": "最新の利用日"
      },
      "bcat_setting": {
        "contents_line_count_limit": "ターゲティングニュースの宛先件数の上限（設定可能最大件数：4800万）",
        "global_semantics_version": "グローバルセマンティックスバージョン",
        "ha_wait_range_max": "HAランダムウェイト最大値(sec)",
        "ha_wait_range_min": "HAランダムウェイト最小値(sec)",
        "latest_semantics_version": "レイテストセマンティックスバージョン",
        "main_image_required_form_level": "メイン画像を必須とするフォームレベル",
        "random_wait": "ランダムウェイト(sec)",
        "rate": "レート(req/sec)"
      },
      "channel_option": {
        "enable_censor": "任天堂レビューを実施する",
        "enable_review": "配信前レビューを実施する",
        "enable_tencent_censor": "Tencentレビューを実施する",
        "list_image_required": "ニュースのリスト画像を必須にする(デバッグ専用)",
        "news_form_level": "ニュースフォームの閲覧レベル",
        "news_passphrase_type": "ニュースの暗号化に使うパスフレーズ(SDKデバッグ専用)",
        "required_application_version": "要求アプリケーションバージョン",
        "semantics_version": "ニュースのセマンティックスバージョン"
      },
      "company": {
        "name": "会社名"
      },
      "country": {
        "region": "配信地域"
      },
      "country_group": {
        "countries": "所属する国",
        "name": "名前",
        "region": "配信地域"
      },
      "data_list": {
        "datafiles": "ファイル",
        "devices": "配信先本体",
        "id": "id",
        "messages": "ニュース",
        "name": "リストの名前",
        "nintendo_accounts": "配信先アカウント",
        "updated_at": "更新日時"
      },
      "data_topic": {
        "is_china": "中国向け",
        "na_required": "NA必須フラグ",
        "npns_topic_id": "NPNSトピックID",
        "pseudo": "擬似トピックフラグ",
        "service_status": "サービスステータス"
      },
      "datafile": {
        "binary": "バイナリー",
        "directory": "ディレクトリ",
        "expiration_time": "配信終了日時",
        "filename": "ファイル名",
        "id": "ID",
        "note": "備考",
        "publishing_time": "配信開始日時",
        "status": "ステータス",
        "summary": "概要"
      },
      "device": {
        "list": "取得できるテスト配信リスト",
        "name": "名前",
        "note": "備考",
        "serial": "シリアルナンバー"
      },
      "directory": {
        "by_country_group": "国グループ別配信をする",
        "by_location": "ロケーション別配信をする",
        "directory_type": "ディレクトリの用途",
        "id": "ID",
        "mode": "モード",
        "name": "名前",
        "note": "備考"
      },
      "general_news": {
        "created_at": "作成日",
        "name": "名前",
        "note": "備考"
      },
      "group": {
        "name": "グループ名"
      },
      "ip_restriction": {
        "ip_addr": "IPアドレス",
        "note": "備考"
      },
      "jedi_record": {
        "aggregated_date": "集計日",
        "average_scroll_percent": "平均スクロール率",
        "average_view_time": "平均閲覧時間",
        "bad": "うーん",
        "download": "配信デバイス数",
        "feedback": "いいね率",
        "good": "いいね",
        "movie_view": "動画視聴デバイス数",
        "notice": "※ 集計日までの累計値が表示されています",
        "read": "既読デバイス数",
        "shopjump": "ショップジャンプ数"
      },
      "job": {
        "arguments": "引数",
        "created_at": "キューイングされた時間",
        "finished_at": "完了時間",
        "id": "ID",
        "job_class": "Job Class",
        "job_id": "Job ID",
        "last_error_detail": "エラー詳細",
        "progress": "進捗",
        "queur_name": "キュー",
        "status": "ステータス",
        "tried_count": "試行回数",
        "updated_at": "最終更新時間"
      },
      "mail_setting": {
        "mail_enabled": "上記以外の通知を受信する"
      },
      "main_content": {
        "body": "本文",
        "main_image": "メイン画像",
        "main_movie": "メイン動画(eShop用動画連携ID)"
      },
      "message": {
        "age_limit": "年齢制限",
        "base": "ニュース",
        "bashotorya": "バショトリャフラグ",
        "caption": "リスト画像にニュースのタイトルを重ねて表示",
        "caption_items": {
          "display": "表示する",
          "hide": "表示しない"
        },
        "countries": "宛先の国",
        "country_group_id": "国グループ",
        "decoration_type": "ピックアップ特別表示",
        "decoration_type_item": {
          "disabled": "特別表示しない",
          "simple": "特別表示する"
        },
        "default_language": "代替言語",
        "default_language_items": {
          "not_specified": "代替言語なし"
        },
        "deletion_priority": "削除優先度",
        "discovery": "ディスカバリー記事の設定",
        "discovery_setting": {
          "disabled": "設定しない",
          "enabled": "設定する"
        },
        "display_limit": "配信済みニュースの表示期限",
        "display_type": "表示形式",
        "essential_pickup_disabled": "必須ピックアップしない",
        "essential_pickup_flag": "必須ピックアップ",
        "essential_pickup_limit": "必須ピックアップ期限",
        "expiration_time": "配信終了日時",
        "featured_products": "ランキング・特集商品",
        "fixed_pickup_disabled": "固定必須ピックアップしない",
        "fixed_pickup_enabled": "固定必須ピックアップする",
        "fixed_pickup_flag": "固定必須ピックアップ",
        "format_version": "フォーマットバージョン",
        "id": "ニュースID",
        "message_discovery": "ディスカバリー記事",
        "movie_delivery": "動画配信",
        "no_photography": "スクリーンショットの制限",
        "no_photography_items": {
          "allow": "撮影を許可する",
          "prohibit": "撮影を禁止する"
        },
        "note": "備考",
        "online_archives_and_movie_expired": "チャンネル詳細に表示中／プロモーション中、かつ配信期限切れ動画を含む記事を探す",
        "pickup_disabled": "ピックアップしない",
        "pickup_flag": "ピックアップ",
        "pickup_limit": "ピックアップ期限",
        "priority": "ピックアップ優先度",
        "publishing_time": "配信開始日時",
        "related_products": "関連商品",
        "related_topics": "関連チャンネル",
        "searchable_application_ids": "関連するアプリケーションのアプリケーションID",
        "semantics_version": "セマンティックスバージョン",
        "status": "ステータス",
        "summary": "概要",
        "surprise": "サプライズ通知フラグ",
        "total_number_of_countries": "計%{size}ヵ国",
        "updated_at": "最終更新日時",
        "user": "担当者",
        "visible_in_online_archives": "チャンネル詳細に表示中／プロモーション中"
      },
      "message_detail": {
        "allow_domains": "ドメインホワイトリスト",
        "application_arg": "ニュースからアプリケーションを起動するときのパラメータ",
        "auto_play": "リスト動画の一覧画面上での再生",
        "auto_play_items": {
          "not_play": "再生しない",
          "play": "再生する"
        },
        "body": "本文",
        "contents_descriptors": "コンテンツディスクリプタ",
        "decoration_text": "ピックアップ特別表示のテキスト",
        "featured_list_name": "ランキング・特集リストの名称",
        "footer_text": "フッターテキスト",
        "interactive_elements": "インタラクティブエレメント",
        "language": "言語",
        "list_image": "リスト画像",
        "list_movie": "リスト動画(eShop用動画連携ID)",
        "list_movie_relation": {
          "auto_play": "リスト動画の一覧画面上での再生",
          "start_seconds": "リスト動画の再生開始位置（秒数）"
        },
        "list_name": "リストの名前",
        "main_image": "メイン画像",
        "main_movie": "メイン動画",
        "more_application_arg": "アプリケーションを起動する時に渡す引数",
        "more_application_ids": "起動するアプリケーションID",
        "more_application_text": "リンクボタンのテキスト",
        "more_applications": "起動するアプリケーション",
        "more_link_text": "リンクボタンのテキスト",
        "more_link_url": "URL",
        "more_nso_relative_url": "システムアプリ「Nintendo Switch Online」で表示するページ",
        "more_nso_text": "リンクボタンのテキスト",
        "more_shop_query": "eShopで表示するページ",
        "more_shop_text": "リンクボタンのテキスト",
        "more_system_applet_text": "リンクボタンのテキスト",
        "more_system_applet_type": "起動するシステムアプレットの種類",
        "more_type": "リンクボタンの種類",
        "movie_name": "動画の名称",
        "ratings": "レーティング",
        "ratings_items": {
          "no_rating": "レーティングなし"
        },
        "related_movies": "関連動画",
        "related_movies_id": "関連動画(eShop用動画連携ID)",
        "search_type": "検索結果で表示するページ",
        "show_contents_excuse": "フッターテキスト中のニュースの内容に関する補足",
        "show_contents_excuse_items": {
          "include": "含める",
          "not_include": "含めない"
        },
        "show_distribution_area": "フッターテキスト中の配信地域の説明",
        "show_distribution_area_items": {
          "include": "含める",
          "not_include": "含めない"
        },
        "show_nso_precaution": "フッターテキスト中のNSOに関する注釈",
        "show_nso_precaution_items": {
          "include": "含める",
          "not_include": "含めない"
        },
        "start_seconds": "リスト動画の再生開始位置（秒数）",
        "subject": "タイトル"
      },
      "message_serial": {
        "published": "シリアルナンバーの配信状態",
        "serial": "配信対象本体のシリアルナンバー"
      },
      "movie": {
        "end_datetime": "配信終了日時",
        "name": "商品名",
        "start_datetime": "配信開始日時"
      },
      "news_description": {
        "description": "チャンネル説明文",
        "language": "言語",
        "name": "チャンネル名称",
        "publisher_name": "発行元",
        "search_string": "トピックの検索用文字列"
      },
      "news_topic": {
        "channel_start_time": "チャンネル運用開始日時",
        "countries": "ニュースの配信対象国",
        "default_language": "代替言語",
        "expiration_time": "公開終了日時",
        "is_china": "中国向け",
        "languages": "ニュースの配信言語",
        "na_required": "NA必須フラグ",
        "news_icon_image": "チャンネルアイコン",
        "news_icon_images": "チャンネルアイコン",
        "news_topic_id": "ニューストピック ID",
        "npns_topic_id": "NPNSトピックID",
        "official": "任天堂公式ニュース",
        "pseudo": "擬似トピックフラグ",
        "public": "チャンネル一覧に表示する",
        "publishing_time": "公開開始日時",
        "service_status": "サービスステータス"
      },
      "nintendo_account": {
        "name": "ニックネーム"
      },
      "one2one": {
        "id": "ID",
        "message": "ニュース",
        "note": "備考",
        "summary": "概要",
        "target_list": "配信先NSAリスト(NAのリストではありません)"
      },
      "parcel": {
        "contents_dimensions": "画像サイズ",
        "contents_file_name": "ファイル名",
        "contents_file_size": "ファイルサイズ",
        "contents_fingerprint": "ダイジェスト値(MD5)",
        "contents_line_count": "ファイルの行数"
      },
      "product": {
        "aocs": "AOC",
        "available_countries": "利用可能国(利用可能言語)",
        "consumables": "消費型アイテム",
        "country": "販売国",
        "have": "あり",
        "image": "商品画像",
        "initial_code": "イニシャルコード",
        "name": "商品名",
        "names": {
          "AOC": "AOC",
          "BUNDLE": "バンドル",
          "CONSUMABLE": "消費型アイテム",
          "PRETICKET": "ニンテンドーカタログチケット",
          "TICKET": "利用券",
          "TITLE": "ソフト",
          "TRIAL": "体験版"
        },
        "not": "なし",
        "oldest_release_at": "公開日",
        "platforms": "紐づけられるプラットフォーム",
        "product_type": "商品種別",
        "tickets": "利用券",
        "unsearchable_countries": "検索非公開国",
        "updated_at": "最終更新時間"
      },
      "promotion": {
        "news_topic_id": "ニューストピック ID"
      },
      "rating_system": {
        "name_items": {
          "CERO": "CERO",
          "CGSRR": "CGSRR",
          "CLASSIND": "CLASSIND",
          "COB": "COB",
          "ESRB": "ESRB",
          "GRB": "GRB",
          "HK": "HK",
          "IARC_COB": "IARC_COB",
          "IARC_GENERIC": "IARC_GENERIC",
          "IARC_OFLC": "IARC_OFLC",
          "IARC_PEGI": "IARC_PEGI",
          "IARC_PEGI_POR": "IARC_PEGI_POR",
          "IARC_USK": "IARC_USK",
          "NONE": "レーティングなし",
          "OFLC": "OFLC",
          "PEGI": "PEGI",
          "PEGI_POR": "PEGI_POR",
          "RAR": "RAR",
          "SMEC": "SMEC",
          "USK": "USK"
        }
      },
      "role": {
        "name": "ロール名",
        "service_roles": {
          "debugger": {
            "desc": "デバッグ専用、デバッグ機能にアクセス可能",
            "name": "Debugger"
          },
          "ncms": {
            "desc": "NCMS から BCAT の API を利用できる",
            "name": "NCMS"
          },
          "nintendo_data_observer": {
            "desc": "一度でも公開されたデータを確認できる",
            "name": "Nintendo Data Observer"
          },
          "nintendo_licensee_news_observer": {
            "desc": "ライセンシーの全てのニュースを確認できる",
            "name": "Nintendo Licensee News Observer"
          },
          "nintendo_news_developer": {
            "desc": "一度でも公開されたニュースを確認できる",
            "name": "Nintendo News Developer"
          },
          "nintendo_news_observer": {
            "desc": "全てのニュースを確認できる",
            "name": "Nintendo News Observer"
          },
          "nintendo_news_reviewer": {
            "desc": "ライセンシーのニュースを確認でき、任天堂レビューの承認を行う",
            "name": "Nintendo News Reviewer"
          },
          "nintendo_official_news_operator": {
            "desc": "任天堂からの公式お知らせを送る事ができる",
            "name": "Nintendo Official News Operator"
          },
          "nintendo_subscribes_operator": {
            "desc": "チャンネルに自動購読するタイトルを登録できる",
            "name": "Nintendo Subscribes Operator"
          },
          "server_admin": {
            "desc": "BCAT サーバの開発者",
            "name": "Server Admin"
          },
          "service_admin": {
            "desc": "BCAT サービス全体の管理者、全てのリソースにアクセス可能",
            "name": "Service Admin"
          },
          "tencent_data_reviewer": {
            "desc": "特定地域のデータを確認でき、Tencentレビューの承認を行う",
            "name": "Tencent Data Reviewer"
          },
          "tencent_news_reviewer": {
            "desc": "特定地域のニュースを確認でき、Tencentレビューの承認を行う",
            "name": "Tencent News Reviewer"
          }
        },
        "title_roles": {
          "editor": {
            "desc": "配信設定を作成・変更できる",
            "name": "Editor"
          },
          "manager": {
            "desc": "権限管理を行うことができる",
            "name": "Manager"
          },
          "none": {
            "desc": "操作権限がない",
            "name": "None"
          },
          "reviewer": {
            "desc": "配信前レビューを承認できる",
            "name": "Reviewer"
          },
          "viewer": {
            "desc": "配信内容を閲覧できる",
            "name": "Viewer"
          }
        }
      },
      "role_request": {
        "approved_at": "判断した日",
        "approver": "判断をした人",
        "channel": "チャンネル",
        "company": "会社",
        "created_at": "要求した日",
        "role": "要求するロール",
        "status": "ステータス",
        "user": "ユーザー"
      },
      "target_list": {
        "id": "ID",
        "note": "備考",
        "nsa_list": "配信先NSAリスト(NAのリストではありません)"
      },
      "title": {
        "application_id": "アプリケーションID",
        "data_size_limit": "データ配信キャッシュストレージのサイズ",
        "image": "管理ツールの表示に使う画像",
        "initial_code": "イニシャルコード",
        "name": "アプリケーション名",
        "note": "備考",
        "passphrase": "パスフレーズ",
        "title_role": "アプリケーションロール"
      },
      "topic_country": {
        "country": "国",
        "first_posted_at": "初回投稿日時",
        "last_posted_at": "最終投稿日時",
        "publishing_time": "公開開始日時"
      },
      "topic_stat": {
        "subscriber": "購読者"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "e-mail": "Eメールアドレス",
        "email": "Eメール",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locale": "言語ロケール",
        "locked_at": "ロック時刻",
        "loginid": "NDID",
        "name": "ユーザ名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認用）",
        "permission_synced_at": "最終権限同期時刻",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶する",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "sync_status": "権限同期ステータス",
        "timezone": "タイムゾーン",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日"
      },
      "virtual_file": {
        "datafiles": "配信データ",
        "directory": "ディレクトリ",
        "filename": "ファイル名",
        "note": "備考",
        "summary": "概要",
        "warn_blank_schedule": "データが配信されない期間を警告する"
      }
    },
    "error": null,
    "errors": {
      "ab_test": {
        "has_not_messages": "指定したニュースはこのABテストと紐付いていません(ニュースID: %{news_ids})"
      },
      "api_account": {
        "invalid_titles": "ApiKeyの所持者の中に、追加しようとするタイトルのManager権限を持っていない人がいます。 先にManager権限を付与してください"
      },
      "archive": {
        "message_pack_size": "配信メッセージデータは%{expected} byte以下にしてください。(アップロードされたサイズ: %{actual} byte)"
      },
      "country_group": {
        "invalid_countries": "不正な国が含まれています。",
        "invalid_exclusion_of_countries": "以下の国を複数の国グループに所属させようとしています: %{countries}",
        "multi_regions_are_detected": "ニュースに使う国グループには複数の配信地域にまたがる組み合わせでは指定できません。"
      },
      "data_list": {
        "device_has_platform_mismatch": "配信リストと配信先本体のプラットフォームが一致しません。",
        "exceeded_test_delivery_limit": "配信に含められるデータは %{limit} までです。(%{actual} 含まれています。)",
        "include_invalid_nintendo_accounts": "不正なアカウントが含まれています(%{names})。"
      },
      "datafile": {
        "cannot_destroy": "過去に配信した履歴があるので削除できません。",
        "cannot_expand_schedule": "スケジュールを拡張できません。",
        "country_group_id": "国グループのID",
        "delivery_type": "配信種別",
        "expiration_time_must_be_future_than_publishing_time": "配信終了日時は配信開始日時よりも未来に設定してください。",
        "message_directory_file_number": "ディレクトリに含めるファイル数は%{limit}以下にしてください。",
        "message_directory_size_limit": "ディレクトリ含めるファイルの総サイズは60MiB以下にしてください。",
        "message_file_number": "同時に配信するファイル数は2000以下にしてください。",
        "message_total_size_limit": "同時に配信するファイルの総サイズは%{limit}MiB以下にしてください。",
        "publishing_time_is_not_unique": "配信開始日時が同じファイルが存在します。",
        "publishing_time_is_overlapped": "配信開始日時が既存の配信期間と重複しています。",
        "too_future_expiration_time": "削除優先度を100よりも小さい値にした場合は、配信終了日時を6ヶ月以内にしてください。",
        "trailing_dot": "ファイル名の末尾に`.`をつけることはできません。"
      },
      "device": {
        "serial_check_digit_is_invalid": "チェックディジットが一致しません。シリアルナンバーが正しく入力されているか確認してください。",
        "serial_format_is_invalid": "シリアルナンバーの形式が正しくありません。シリアルナンバーが正しく入力されているか確認してください。"
      },
      "directory": {
        "cannot_destroy": "配信したことのあるデータが存在するディレクトリは削除できません。",
        "cannot_update_unless_empty": "ディクトリ内にデータ存在する場合はこの属性を変更できません。",
        "directory_number_exceeded": "ディレクトリ数が上限に達しました。"
      },
      "general_news": {
        "message_has_platform_mismatch": "チャンネルに紐づくニュースに%{platforms}以外のプラットフォームが指定されているため変更できません。",
        "platforms": "を選択してください",
        "promotion_has_platform_mismatch": "チャンネルに紐づくプロモーションに%{platforms}以外のプラットフォームが指定されているため変更できません。",
        "subscribe_has_platform_mismatch": "チャンネルに紐づく自動購読設定に%{platforms}以外のプラットフォームが指定されているため変更できません。",
        "test_device_has_platform_mismatch": "チャンネルに紐づくテスト配信の配信先本体に%{platforms}以外のプラットフォームが指定されているため変更できません。"
      },
      "invalid_title_and_news_platform": "自動購読設定するアプリケーションのプラットフォームに、ニュースチャンネルのプラットフォームが対応していません。",
      "ip_restriction": {
        "invalid_ip_address": "IPアドレスが不正です。",
        "invalid_ipv4_format": "IPv4アドレスを指定してください。",
        "not_allowed_fullopen": "0.0.0.0/0は指定できません。",
        "not_allowed_private_ip": "プライベートアドレスは指定できません。"
      },
      "list_movie_relation": {
        "start_seconds": {
          "input_integer": "「リスト動画の再生開始位置（秒数）」は整数で入力してください。",
          "length": "「リスト動画の再生開始位置（秒数）」は半角数字9桁以内で入力してください。"
        }
      },
      "log_request": {
        "maximum_request_period": "集計期間は31日分以内にしてください。",
        "start_time_larger_topic_created_at": "集計開始時間は%{time}より後を指定してください。",
        "start_time_less_than_end_time": "集計開始時間は集計終了時間より過去を指定してください。",
        "two_hours_before": "集計終了時間は現在より2時以上過去を指定してください。"
      },
      "lushan": {
        "linked": "既に %{name} によって連携されているため追加できません。"
      },
      "main_content": {
        "too_many_headers": "1つの本文で指定できるheaderは%{maximum}までです。",
        "total_body_length": "本文は合計で%{expected_max}文字以下にしてください。 （本文の合計文字数: %{actual}文字）",
        "total_main_image_height": "画像の高さは合計で%{expected_max}px以下にしてください。 （アップロードされた画像の合計の高さ: %{actual} px）",
        "total_main_image_size": "画像のサイズは合計で%{expected_max} byte以下にしてください。 （アップロードされた画像の合計のサイズ: %{actual} byte）"
      },
      "message": {
        "at_least_publishing_at": "商品の公開時刻以降に配信するため、ニュースの配信時刻を少なくとも%{release_at}より未来を指定して下さい。",
        "cant_publish_before_channel_publishing_time": "チャンネルの公開開始日時 %{topic_publishing_time} よりも未来に設定してください。",
        "cant_publish_before_related_channel_publishing_time": "関連チャンネルの公開開始日時 %{topic_publishing_time} よりも未来に設定してください。",
        "display_limit_must_be_future_than_publishing_time": "表示期限は配信開始日時より未来でないといけません。",
        "display_limit_must_be_past_than_end_date_of_video": "表示期限は動画の配信終了日時より過去でないといけません。表示期限を少なくとも%{end_datetime}より前を指定してください。",
        "essential_pickup_limit_must_be_future_than_publishing_time": "必須ピックアップ期限は配信開始日時より未来でないといけません。",
        "essential_pickup_limit_must_be_past_than_display_limit": "必須ピックアップ期限は表示期限より過去でないといけません。",
        "essential_pickup_limit_must_be_past_than_pickup_limit": "必須ピックアップ期限はピックアップする期限より過去でないといけません。",
        "essential_pickup_limit_must_be_up_to_2_week_from_publishing_time": "必須ピックアップ期限は、最大で配信開始日時から2週間までに指定してください。",
        "essential_priority_is_invalid": "不正な優先度",
        "featured_products": {
          "featured_comments": {
            "invalid_characters": "%{invalid_characters} はSwitchで表示できない文字です。"
          },
          "invalid_characters": "%{invalid_characters} はSwitchで表示できない文字です。",
          "invalid_platforms": "リスト/ランキング商品%{ns_uid}はプラットフォーム「%{platforms}」に非対応です。",
          "invalid_products": "%{lang}の言語のリスト/ランキング商品%{ns_uid}がありません",
          "invalid_products_for_country": "商品の販売国の変更もしくは販売停止があった可能性があります。(%{ns_uid})",
          "too_many_products": "%{maximum}個以下しか紐付けられません"
        },
        "featured_products_have_errors": "リスト/ランキング商品の設定に不正な内容があります。",
        "include_invalid_application_id": "不正なフォーマットのアプリケーションIDが含まれています。",
        "include_out_of_application_id_range": "アプリケーションIDの範囲を外れた値が含まれています。",
        "include_same_application_ids": "同じ値が複数含まれています。",
        "invalid_china": {
          "country": "このトピックでは中国以外に配信できません",
          "topic": "このトピックは中国向けのトピックではありません"
        },
        "invalid_default_language": "代替言語に指定された言語のニュースがありません。",
        "message_details_have_errors": "言語ごとの設定に不正な内容があります。",
        "multiple_identical_languages_of_news": "ニュースに同一言語を複数含めることはできません。",
        "pickup_limit_must_be_future_than_publishing_time": "ピックアップ期限は配信開始日時より未来でないといけません。",
        "platforms": "を選択してください",
        "platforms_set_for_the_channel": "チャンネルに登録されたプラットフォームを指定してください。",
        "publishing_time_must_be_future_than_channel_publishing_time": "チャンネルの公開開始日時より(%{channel_publishing_time})も早くニュースを配信することはできません。",
        "related_products": {
          "invalid_platforms": "関連商品%{ns_uid}はプラットフォーム「%{platforms}」に非対応です。",
          "invalid_products": "%{lang}の言語の関連商品%{ns_uid}がありません",
          "invalid_products_for_country": "商品の販売国の変更もしくは販売停止があった可能性があります。(%{ns_uid})",
          "too_many_products": "は%{actual}個以下しか紐付けられません",
          "unlinkable_products": "紐付け不可能な商品(%{ns_uids})が含まれています"
        },
        "related_topics": {
          "cannot_set": "このニュースでは指定できません。",
          "include_invalid": "不正なチャンネルが指定されています。",
          "include_invalid_with_message": "不正なチャンネルが指定されています。 %{message}",
          "invalid_platforms": "関連チャンネル%{npns_topic_id}はプラットフォーム「%{platforms}」に非対応です。",
          "too_many_topics": "は%{maximum_size}個までしか指定できません"
        },
        "require_at_a_least_one_message_detail": "ニュースはすくなくとも1言語での入力が必要です。",
        "require_default_language": "代替言語の指定は必須です。",
        "too_many_application_ids": "5つまでしか指定できません。"
      },
      "message_detail": {
        "application_id_out_of_application_id_range": "アプリケーションIDの値が範囲外です。",
        "duplicate_more_application_ids": "アプリケーションIDの重複があります。",
        "includes_invalid_allow_domain": "不正なフォーマットのドメインが含まれています。(%{invalid_domain_index}行目)",
        "invalid_allow_url": "不正なフォーマットのURLが含まれています。",
        "invalid_asia_news": "チャンネルのバージョン設定が不正です",
        "invalid_characters": "%{invalid_characters} はSwitchで表示できない文字です。",
        "invalid_contents_descriptors": "不正なフォーマットか、意図しない値がふくまれています。",
        "invalid_interactive_elements": "不正なフォーマットか、意図しない値がふくまれています。",
        "invalid_more_application_query": "は不正なフォーマットです。",
        "invalid_more_shop_query": "パラメータが不正です。",
        "invalid_more_shop_query_platforms": "商品%{ns_uid}はプラットフォーム「%{platforms}」に非対応です。",
        "invalid_movie_url": "入力された値が不正です。",
        "invalid_movie_url_domain": "指定されたドメインは未対応です。",
        "invalid_products_for_country": "商品の販売国の変更もしくは販売停止があった可能性があります。(%{ns_uid})",
        "invalid_ratings": "不正なフォーマットか、意図しない値がふくまれています。",
        "list_image_is_required": "リスト画像は必須です。",
        "list_image_or_list_movie_is_required": "「リスト動画」と「リスト画像」のうち少なくとも1つは設定が必須です。",
        "main_contents_exceed_maximum_size": "メインコンテンツの最大サイズを超えています。",
        "main_contents_have_errors": "「メイン動画」、「メイン画像」もしくは本文にエラーがあります。例えば、「メイン動画」と「メイン画像」の両方が未設定の場合にこのエラーが発生します。",
        "main_image_is_required_if_movie_url": "メイン動画を指定する場合、メイン画像は必須です。",
        "more_application_id_must_be_16_character_hexadecimal": "起動できるアプリケーションのアプリケーションIDは16文字の16進表記でないといけません。",
        "more_application_ids_are_over_maximum_size": "最大で%{maximum_size} タイトルを指定してください。",
        "ncms_internal_server_error": "NCMSの情報取得に失敗しました。しばらく待ってもう一度お試しください。改善しない場合は窓口にお問い合わせ下さい。",
        "no_ratings": "レーティングの設定は必須です。言及している商品にレーティングがない場合は、いずれかのレーティングシステムで「レーティングなし」を選択してください。",
        "only_one_record_if_no_rating": "「レーティングなし」を選択した場合は、「レーティングなし」のみにしてください。",
        "presence_more_application_query": "eShopの情報取得に失敗しました。リンクの設定をやめるか、しばらく待ってもう一度お試しください。改善しない場合は窓口にお問い合わせ下さい。",
        "ratings": {
          "no_supported_countries": "%{rating_system}をサポートている国が宛先に含まれていません。",
          "specify_esrb_or_classind": "レーティングシステムはESRBかCLASSINDのどちらか一方しか指定できません。",
          "too_many_ratings": "レーティングシステムは最大%{maximum_size}個までです。"
        },
        "related_movies": {
          "invalid_names": "動画の名称は全て入力するか、全て空欄にして下さい。",
          "too_many_movies": "関連動画は%{maximum}個までしか指定できません。"
        },
        "related_movies_have_errors": "関連動画にエラーがあります。",
        "required_ratings": "コンテンツディスクリプタやインタラクティブエレメンツが指定されている場合は必須です。",
        "too_many_contents_descriptors": "指定されたコンテンツディスクリプタが多すぎます。",
        "too_many_interactive_elements": "指定されたインタラクティブエレメンツが多すぎます。"
      },
      "message_serial": {
        "cannot_modify_because_published": "一度でも配信されたシリアルナンバーは別のニュースを紐付けることができません。"
      },
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        },
        "taken": "%{attribute}はすでに存在します"
      },
      "movie": {
        "duplicate_movie_url": "関連動画のeShop用動画連携IDが重複しています。",
        "limit_movie_number": "これ以上指定することができません。",
        "not_found": "動画が見つかりません。"
      },
      "news_description": {
        "cant_destroy_saved_language": "すでにニュースが登録されている言語のニュースアイコン詳細は削除できません。"
      },
      "news_topic": {
        "default_language_not_found_in_news_descriptions": "代替言語に指定したニュースの説明が必要です。",
        "includes_unsupported_countries": "サポートしていない国が含まれています。",
        "includes_unsupported_languages": "サポートしていない言語が含まれています。",
        "npns_topic_id_news_format": "nx_news_で始まる英数文字列である必要があります。"
      },
      "nintendo_account": {
        "linked": "既に %{name} によって連携されているため追加できません。"
      },
      "parcel": {
        "icon_image_width": "画像の幅は 70px または 136px にしてください。(アップロードされた画像の幅: %{actual} px)",
        "invalid_baseline_jpeg": "ニュースの画像はベースラインJPEGを指定してください。",
        "invalid_data_format": "不正なデータフォーマットです。",
        "invalid_image_cmyk": "CMYKカラーモードの画像は使用できません。RGBカラーモードの画像を指定してください。",
        "invalid_target_list": "不正な宛先ファイルです。(%{invalid_index}行目: \"%{invalid_record}\")",
        "list_image_height": "画像の高さは%{expected_max} px 以下にしてください。(アップロードされた画像の高さ: %{actual} px)",
        "main_image_aspect_ratio": "画像の高さは%{expected_max}px以下にしてください。(%{actual_width}:%{actual_height}) px'",
        "message_application_arg_size": "起動引数は%{expected} byte以下にしてください。(アップロードされた起動引数のサイズ: %{actual} byte)",
        "message_contents_empty": "宛先件数が0件です。",
        "message_contents_line_count": "宛先件数は %{limit} 件以下にしてください。(アップロードされた宛先件数: %{actual} 件)",
        "message_convert_image_fomat": "画像はベースラインJPEG、プログレッシブJPEG、PNGのみを受け付けています。",
        "message_convert_image_width": "画像の幅は %{expected_max} px 以下にしてください。(アップロードされた画像の幅: %{actual} px)",
        "message_datafile_size": "配信データのサイズは%{expected}MiB以下にしてください。(アップロードされた配信データのサイズ: %{actual}MiB)",
        "message_image_aspect_ratio": "画像のアスペクト比は%{expected_width}:%{expected_height}にしてください。(アップロードされた画像のアスペクト比: %{actual})",
        "message_image_format": "画像はベースラインJPEGのみを受け付けています。",
        "message_image_height": "画像の高さは%{expected} px にしてください。(アップロードされた画像の高さ: %{actual} px)",
        "message_image_height_range": "画像の高さは%{expected_min} px 以上 %{expected_max} px 以下にしてください。(アップロードされた画像の高さ: %{actual} px)",
        "message_image_size": "画像サイズは%{expected} byte以下にしてください。(アップロードされた画像のサイズ: %{actual} byte)",
        "message_image_width": "画像の幅は%{expected} px にしてください。(アップロードされた画像の幅: %{actual} px)",
        "message_image_width_range": "画像の幅は%{expected_min} px 以上 %{expected_max} px 以下にしてください。(アップロードされた画像の幅: %{actual} px)"
      },
      "product": {
        "limit_product_number": "これ以上指定することができません。",
        "ns_uid_format_invalid": "ns uidのフォーマットが不正です。"
      },
      "title": {
        "application_id_cannot_be_set": "Application ID は設定できません。",
        "application_id_format_invalid": "Application ID のフォーマットが不正です。",
        "application_id_is_required": "Application ID は必須です。",
        "application_id_out_of_application_id_range": "アプリケーションIDの値が範囲外です。",
        "platforms": "を選択してください。"
      },
      "virtual_file": {
        "blank_time": "データ配信が配信されない期間があります。これが意図した設定である場合は、この警告は無視するか、「データが配信されない期間を警告する」をfalseにしてください",
        "cannot_change": "配信予定あるいは配信中のデータがある場合は変更できません",
        "existing_multi_platforms_invalid_filename": "既存の配信データに複数プラットフォーム対応のものがある場合、配信先が異なっても、そのファイルとファイル名が重複してはいけません。",
        "infinite_overlap_time": "配信終了日時を入力してください",
        "new_multi_platforms_invalid_filename": "複数プラットフォーム対応の配信データを作成・更新する場合、配信先が異なっても既存ファイルとファイル名が重複してはいけません。",
        "overlap_time": "データを配信する期間が重複しています",
        "platforms": "を選択してください",
        "virtual_file_platforms": "アプリケーションのプラットフォームに存在しないプラットフォームは登録できません。"
      }
    },
    "hints": {
      "ab_test": {
        "lock": "このシリアルはニュースを配信済みなので変更できません"
      },
      "country_group": {
        "countries_for_data": "データ配信向けのトピックでは1つの国は1つの国グループにしか所属できません。",
        "countries_for_news": "ニュース配信向けのトピックでは1つの国は複数の国グループに所属できます。"
      },
      "device": {
        "csv_format": "名前,シリアルナンバー,備考 を1行1レコードで記述してください。文字コードはBOM無しのUTF-8としてください。改行コードの種類（CR、LF、CRLF）は問いません。"
      },
      "message": {
        "searchable_application_ids": "1行に1つずつ最大５つまでapplication_idを記載できます。application_id は16進数の16文字で記載してください。"
      },
      "message_detail": {
        "allow_domains": "1行に1ドメインずつ記述します。",
        "list_image": "幅358px固定、高さ201px固定、容量270KiB以内のベースラインJPEGを指定して下さい。",
        "list_image_v10_or_later": "幅7680px以内、高さ4320px以内、入力アスペクト比16:9、容量80000KiB以内のベースラインJPEGを指定して下さい。",
        "main_image": "幅800px固定、高さ1800px以内、容量2500KiB以内のベースラインJPEGが指定できます。",
        "main_image_v10_or_later": "幅7680px以内、高さ4320px以内、容量80MiB以内のベースラインJPEG、プログレッシブJPEG、PNGを指定できます。",
        "main_image_with_movie": "メイン動画のeShop用動画連携IDが指定された場合、システムはNCMSに登録されたサムネイル画像を自動的にセットします。明示的に画像を指定することも可能です。 指定する場合は幅800px固定、高さ450px固定、容量2500KiB以内のベースラインJPEGを指定して下さい。",
        "main_image_with_movie_v10_or_later": "メイン動画のeShop用動画連携IDが指定された場合、システムはNCMSに登録されたサムネイル画像を自動的にセットします。明示的に画像を指定することも可能です。 指定する場合は幅1200px固定、高さ675px固定、容量5625KiB以内のベースラインJPEGを指定して下さい。",
        "more_nso_relative_url": {
          "home": "ホーム",
          "mission_rewards": "ミッション＆ギフト"
        },
        "more_shop_query": {
          "aocDetail": "AOCの詳細ページ",
          "aocList": "ソフトが持つAOCの一覧ページ",
          "aocs": "ソフトが持つAOCの一覧ページ",
          "bundleDetail": "バンドルの詳細ページ",
          "consumption": "消費型アイテム一覧ページ",
          "list": "指定した商品のリストページ",
          "membership_service": "Nintendo Switch Onlineの権利購入ページ",
          "membership_top": "Nintendo Switch Onlineのトップページ",
          "new": "eShop newページ",
          "pickup": "eShopピックアップページ",
          "preticketDetail": "ニンテンドーカタログチケットの詳細ページ",
          "product_detail": "商品の詳細ページ",
          "sale": "eShopセールページ",
          "search": "検索ページ",
          "search_type": {
            "having_trial": "体験版一覧ページ"
          },
          "softDetail": "ソフトの詳細ページ",
          "subscriptions": "利用券一覧ページ"
        }
      },
      "news_topic": {
        "require_news_descriptions": "配信予定のある全ての言語のニュースの説明を設定してください。設定がない言語のニュースは配信できません。"
      },
      "title": {
        "application_id": "ex. 0100000abcdef000"
      }
    },
    "models": {
      "ab_test": "ニュースグループ",
      "activity_log": "操作ログ",
      "aging": "エージング用データの複製",
      "announcement": "管理者からのお知らせ",
      "channel_option": "設定",
      "company": "会社",
      "copy": "コピー",
      "country": "国",
      "country_group": "国グループ",
      "data_list": "テスト配信リスト",
      "data_topic": "トピック",
      "datafile": "バイナリー",
      "device": "配信先本体",
      "directory": "ディレクトリ",
      "general_news": "チャンネル",
      "group": "グループ",
      "ip_restriction": "IP制限",
      "jedi_record": "統計レコード",
      "job": "ジョブ",
      "log_request": "ログ",
      "mail_setting": "メール設定",
      "message": "ニュース",
      "message_detail": "ニュースの詳細",
      "message_serial": "配信対象本体のシリアルナンバー",
      "movie": "動画",
      "news_description": "ニュースの説明",
      "news_topic": "トピック",
      "nintendo_account": "配信先アカウント",
      "one2one": "ターゲティングニュース",
      "parcel": "アップロードされたファイル",
      "product": "eShopの商品",
      "promotion": "プロモーション",
      "role": "ロール",
      "role_request": "ロールリクエスト",
      "subscribe": "購読",
      "target_list": "配信先NSAリスト",
      "title": "アプリケーション",
      "topic_country": "配信対象国",
      "topic_stat": "トピック統計",
      "user": "ユーザ",
      "virtual_file": "配信データ"
    }
  },
  "activity_logs": {
    "index": {
      "action": "アクション",
      "api_key": "APIキー",
      "controller": "コントローラ",
      "difference": "差分",
      "resource": "リソース",
      "resource_name": "リソース名",
      "timestamp": "日時",
      "title": "操作ログ",
      "user": "ユーザ",
      "user_or_api_key": "ユーザもしくはAPIキー"
    },
    "show": {
      "resource_name": "リソース名",
      "title": "操作ログ",
      "user_or_api_key": "ユーザもしくはAPIキー"
    }
  },
  "announcements": {
    "edit": {
      "title": "%{announcement_subject}の編集"
    },
    "empty": "現在お知らせはありません。",
    "index": {
      "title": "管理者からのお知らせ"
    },
    "new": {
      "title": "管理者からのお知らせの新規作成"
    },
    "show": {
      "title": "%{announcement_subject}の詳細"
    }
  },
  "api_accounts": {
    "edit": {
      "title": "アカウントの権限の編集"
    },
    "index": {
      "detail": "権限の詳細",
      "link_keys": "ApiKeyの管理",
      "swagger": {
        "v2": "V2 Swagger"
      },
      "swagger_hint": "BCATのWebApi(V2以降)で利用するアカウントを発行できます。",
      "title": "WebApiアカウントの管理"
    },
    "new": {
      "title": "新しいアカウントの作成"
    },
    "show": {
      "title": "の詳細"
    }
  },
  "api_keys": {
    "hints": {
      "can_create": "ApiKeyは、一つ以上のアプリケーションが紐付いていれば作成できます。",
      "can_know_secret": "認証情報は、クライアントIDとクライアントシークレットをコロンでつないだ組み合わせになっています。",
      "may_copy": "クライアントシークレットを閲覧できるのは、ApiKeyの作成者のみです。"
    },
    "index": {
      "authorization": "認証情報",
      "title": "ApiKey一覧"
    }
  },
  "application_controller": {
    "update_user_permission": {
      "alert": "access token invalid, you must logout and login."
    }
  },
  "attributes": {
    "label_platform": "ラベルプラットフォーム",
    "platforms": "プラットフォーム"
  },
  "bcat_settings": {
    "edit": {
      "title": "BCAT Setting"
    },
    "show": {
      "title": "BCAT Setting"
    }
  },
  "calendars": {
    "show": {
      "title": "カレンダー"
    }
  },
  "censors": {
    "index": {
      "title": "任天堂確認待ちニュース一覧"
    }
  },
  "censorship_mailer": {
    "data": {
      "tencent_censor_approval": {
        "body": "データがTencentレビューの結果承認されました。",
        "subject": "BCAT (%{env}) よりデータのフェーズ変更のお知らせ (Tencentレビュー承認: %{name})"
      },
      "tencent_censor_cancel": {
        "body": "データのTencentレビューが取り下げられました。",
        "subject": "BCAT (%{env}) よりデータのフェーズ変更のお知らせ (Tencentレビュー取り下げ: %{name})"
      },
      "tencent_censor_rejection": {
        "body": "データがTencentレビューの結果却下されました。",
        "subject": "BCAT (%{env}) よりデータのフェーズ変更のお知らせ (Tencentレビュー却下: %{name})"
      },
      "tencent_censor_request": {
        "body": "データがTencentレビューに出されました。",
        "subject": "BCAT (%{env}) よりデータのフェーズ変更のお知らせ (Tencentレビュー依頼: %{name})"
      }
    },
    "directory_name": "ディレクトリ名",
    "news": {
      "censor_approval": {
        "body": "ニュースが任天堂レビューの結果承認されました。",
        "subject": "BCAT (%{env}) よりニュースのフェーズ変更のお知らせ (任天堂レビュー承認: %{name})"
      },
      "censor_cancel": {
        "body": "ニュースの任天堂レビューが取り下げられました。",
        "subject": "BCAT (%{env}) よりニュースのフェーズ変更のお知らせ (任天堂レビュー取り下げ: %{name})"
      },
      "censor_rejection": {
        "body": "ニュースが任天堂レビューの結果却下されました。",
        "subject": "BCAT (%{env}) よりニュースのフェーズ変更のお知らせ (任天堂レビュー却下: %{name})"
      },
      "censor_request": {
        "body": "ニュースが任天堂レビューに出されました。",
        "subject": "BCAT (%{env}) よりニュースのフェーズ変更のお知らせ (任天堂レビュー依頼: %{name})"
      },
      "publishing_unapproval": {
        "body": "ニュースの配信承認が解除されました。",
        "subject": "BCAT (%{env}) よりニュースのフェーズ変更のお知らせ (配信承認の解除: %{name})"
      },
      "tencent_censor_approval": {
        "body": "ニュースがTencentレビューの結果承認されました。",
        "subject": "BCAT (%{env}) よりニュースのフェーズ変更のお知らせ (Tencentレビュー承認: %{name})"
      },
      "tencent_censor_cancel": {
        "body": "ニュースのTencentレビューが取り下げられました。",
        "subject": "BCAT (%{env}) よりニュースのフェーズ変更のお知らせ (Tencentレビュー取り下げ: %{name})"
      },
      "tencent_censor_rejection": {
        "body": "ニュースがTencentレビューの結果却下されました。",
        "subject": "BCAT (%{env}) よりニュースのフェーズ変更のお知らせ (Tencentレビュー却下: %{name})"
      },
      "tencent_censor_request": {
        "body": "ニュースがTencentレビューに出されました。",
        "subject": "BCAT (%{env}) よりニュースのフェーズ変更のお知らせ (Tencentレビュー依頼: %{name})"
      }
    },
    "news_name": "ニュース名"
  },
  "channel_options": {
    "manage_semantics_version": {
      "except_system_news": "システム系ニュースは除く",
      "system_news": "システム系ニュース",
      "title": "チャンネルのセマンティックスバージョン更新",
      "update_semantics_version": "チャンネルのセマンティックスバージョンを表示されているグローバルセマンティックスバージョンで更新します。"
    },
    "tooltips": {
      "system_news": "システム系ニュースは、%{npns_topic_ids}が該当します。"
    }
  },
  "cloudwatch_logs": {
    "test_logs": {
      "accessed_at": "アクセス時間",
      "aws_incident": "現在ログ集計サーバに障害が発生しており、データが表示できません。",
      "ip_address": "IPアドレス",
      "notice": "直近6時間にテスト配信設定の端末からアクセスされたログを表示しています。",
      "resource": "リソース",
      "serial": "本体シリアル",
      "status": "ステータス",
      "title": "テスト配信 アクセスログ",
      "type": "タイプ"
    }
  },
  "comment_mailer": {
    "new_comment_for_message": {
      "body": "新規コメントが投稿されました。",
      "comment": {
        "body": "本文",
        "user": "投稿者"
      },
      "subject": "BCAT (%{env}) よりコメント投稿のお知らせ (%{name}%{region})"
    },
    "new_comment_for_virtualfile": {
      "body": "新規コメントが投稿されました。",
      "comment": {
        "body": "本文",
        "user": "投稿者"
      },
      "dir_name": "ディレクトリ名",
      "subject": "BCAT (%{env}) よりコメント投稿のお知らせ (%{name})"
    }
  },
  "comments": {
    "create": {
      "comment_posted": "コメントが投稿されました",
      "failed_to_post_comment": "コメントの投稿に失敗しました。コメントの本文は必須です。"
    }
  },
  "common": {
    "action": {
      "add": "追加",
      "approve": "承認",
      "cancel": "取り下げ",
      "create": "登録する",
      "destroy": "削除",
      "destroy_uploaded": "アップロード済みのファイルを削除",
      "disable": "無効化",
      "duplicate": "複製",
      "edit": "編集",
      "enable": "有効化",
      "error_occured_on_process_please_retry_later": "処理中にエラーが起きました、しばらく経ってから再度試してください",
      "import": "インポート",
      "new": "新規作成",
      "post_comment": "コメントの投稿",
      "reject": "却下",
      "request": "リクエスト",
      "reset_retrieving_conditions": "条件をリセット",
      "sending": "送信中",
      "show": "詳細",
      "stats": "統計",
      "sync": "同期",
      "unable_to_find_available_bcat_application": "有効な BCAT 利用申請が見つかりませんでした",
      "update": "更新"
    },
    "alert": {
      "access_token_invalid_please_relogin": "アクセストークンが不正です、ログアウトされてから再ログインしてください",
      "unable_to_fetch_data_wait_while": "NDID からのデータ取得に失敗しました。しばらく待ってから再度お試しください。"
    },
    "back": "戻る",
    "browser_warning": "現時点ではChromeのみサポートしています。Chrome以外のブラウザでは一部のページが正常に動作しませんのでChromeをご使用ください。",
    "channel_side_nav": {
      "activity_logs": "操作ログ",
      "add_user": "ユーザの追加",
      "back_to_general_news_index": "チャンネル一覧に戻る",
      "back_to_titles": "アプリケーション一覧に戻る",
      "datafile": "データ",
      "general_news_detail": "チャンネルの詳細",
      "manage_bcat_application": "BCAT 利用申請の管理",
      "manage_country_groups": "国グループの管理",
      "manage_data": "データの配信管理",
      "manage_device": "配信先本体の管理",
      "manage_na": "配信先アカウントの管理",
      "manage_news": "ニュースの配信管理",
      "manage_test_delivery": "テスト配信の管理",
      "my_role": "マイロール",
      "news": "ニュース",
      "no_ip_restrictions_for_delivery": "IPアドレス制限を設定してください。設定がない場合、本配信及びテスト配信したデータは受け取れません。",
      "no_ip_restrictions_for_test_delivery": "IPアドレス制限を設定してください。設定がない場合、テスト配信したデータは受け取れません。",
      "role_management": "ロールの管理",
      "role_requests": "ロールリクエスト",
      "test_distribution": "テスト配信",
      "test_logs": "テスト配信ログ",
      "title_detail": "アプリケーションの詳細",
      "user_list": "ユーザリスト"
    },
    "confirm": {
      "destroy": "削除してもよろしいですか？",
      "leave": "登録・保存していない入力内容は破棄されますがよろしいですか？",
      "update": "更新してもよろしいですか？"
    },
    "error": {
      "containing_invalid_characters": "使用できない文字がふくまれています",
      "failed_to_approve": "承認に失敗しました",
      "failed_to_be_under_editing": "編集中への遷移に失敗しました",
      "failed_to_cancel": "取り下げに失敗しました",
      "failed_to_create": "登録に失敗しました",
      "failed_to_destroy": "削除に失敗しました",
      "failed_to_duplicate": "複製に失敗しました",
      "failed_to_expire": "配信終了に失敗しました",
      "failed_to_publish": "配信開始に失敗しました",
      "failed_to_reject": "却下に失敗しました",
      "failed_to_request": "リクエストに失敗しました",
      "failed_to_unapprove": "承認の取り下げに失敗しました",
      "failed_to_update": "更新に失敗しました",
      "mismatched_related_product": "不正な関連商品です"
    },
    "global_side_nav": {
      "data_distribution": "データ配信",
      "general_news_roles": "ニュースロール",
      "my_role": "マイロール",
      "ndp_bcat_usage_document": "ツールの取扱説明書",
      "ndp_news_guide_document": "ゲームニュース配信ガイド",
      "news_distribution": "ニュース配信",
      "role_requests": "ロールリクエスト",
      "service_roles": "サービスロール",
      "subscribe": "自動購読",
      "subscribes": "自動購読設定",
      "title_roles": "アプリケーションロール",
      "titles_subscribes": "アプリケーション登録",
      "user_settings": "ユーザー設定"
    },
    "help": "ヘルプ",
    "locale_select": "言語",
    "logout": "ログアウト",
    "mailer": {
      "check_url_below": "詳細は以下のURLへアクセスしてご確認ください。",
      "no_reply_notice": "本メールは自動配信です。このメールには返信しないでください。",
      "operator": "操作者"
    },
    "notice": {
      "approved": "承認しました",
      "been_under_editing": "編集中にしました",
      "cancelled": "取り下げました",
      "created": "登録しました",
      "destroyed": "削除しました",
      "disabled": "無効化しました",
      "duplicated": "複製しました",
      "enabled": "有効化しました",
      "expired": "配信終了しました",
      "notified": "通知しました",
      "published": "配信開始されました",
      "rejected": "却下しました",
      "requested": "リクエストしました",
      "unapproved": "承認を取り下げました",
      "updated": "更新しました"
    },
    "rating_system": "レーティングシステム",
    "role": {
      "editor_description": "Viewer の権限に加えて、配信設定の新規作成/更新ができます。",
      "manager_description": "Reviewer の権限に加えてロールの管理ができます。",
      "reviewer_description": "Editor の権限に加えて、配信前レビューの承認ができます。",
      "roles_description": "ロール毎の権限",
      "viewer_description": "配信設定の閲覧のみできます。"
    },
    "timezone_select": "タイムゾーン"
  },
  "country_groups": {
    "common": {
      "countries_summary": "... 計%{size}ヵ国"
    },
    "edit": {
      "title": "%{country_group_name}の編集"
    },
    "index": {
      "title": "国グループ"
    },
    "index_with_no_country_group": {
      "information": {
        "manual": {
          "body_html": "国グループの作成をシステムに任せず、手動で設定することができます。<br/>国グループの設定に慣れていない場合は、システムが推奨する設定で国グループを作成してください。<br/>システムが推奨する設定で国グループを作成した場合でも、後から手動で国グループを作成することはできます。",
          "title": "手動で国グループを作成する"
        },
        "recommended": {
          "body_html": "システムが推奨する設定で国グループを設定します。特別な理由がなければシステムが推奨する設定で国グループを作成してください。<br/>作成された国グループは、後から編集、削除ができます。また、手動で国グループを追加することもできます。<br/>システムが推奨する設定とは、NCMSで申請した対象国を配信地域ごとにまとめた国グループです。<br/>配信地域とは日本のみが含まれる日本、主にアメリカ大陸の国々が含まれる米州、<br/>主にヨーロッパ地域の国々が含まれる欧州、オーストラリアとニュージランドが含まれる豪州の4地域です。<br/>NCMSで申請した対象国が1つも含まれない配信地域がある場合、その配信地域向けの国グループは作成されません。",
          "title": "システムが推奨する設定で国グループを作成する"
        }
      },
      "not_allowed": "操作する権限がありません",
      "title": "国グループ"
    },
    "new": {
      "title": "国グループの新規作成"
    },
    "selected_countries": "選択中の国",
    "show": {
      "title": "%{country_group_name}の詳細"
    }
  },
  "data_agings_controller": {
    "create": {
      "notice": "受け付けました。しばらくお待ち下さい"
    },
    "destroy": {
      "notice": "受け付けました。しばらくお待ち下さい"
    }
  },
  "data_lists": {
    "edit": {
      "sub_title": "テスト配信するデータを選択してください。",
      "title": "テスト配信リストの編集"
    },
    "form": {
      "available_data_size": "データ配信キャッシュストレージの残容量 :",
      "confirm": "保存と同時に起動中の本体に配信します。スリープ状態の本体には配信されません。本体が起動中であることを確認してOKを押してください。",
      "current_data_size": "リストに含まれている総データサイズ :",
      "devices": "アクセス許可設定",
      "exclude_expired": "配信終了済みのデータを含めない",
      "include_expired": "配信終了済みのデータも含める",
      "invalid": "配信リストの名前が指定されていないか、データ配信キャッシュストレージを超過しています。",
      "maximum_data_size": "データ配信キャッシュストレージのサイズ :",
      "name": "テスト配信リストの名前",
      "nintendo_accounts": "配信先設定",
      "notify": "再度配信する",
      "save_and_notify": "保存して配信",
      "selected_data": "選択中データ"
    },
    "index": {
      "title": "テスト配信"
    },
    "new": {
      "not_found": "登録されたデータがありません。",
      "sub_title": "テスト配信するデータを選択してください。",
      "title": "テスト配信リストの新規作成"
    },
    "show": {
      "confirm": "起動中の本体に配信します。スリープ状態の本体には配信されません。本体が起動中であることを確認してOKを押してください。",
      "hint": {
        "description": "お使いの本体がスリープ状態でないことを確認してください。<br>\n配信先本体のシリアルナンバー・配信先アカウント・IPアドレス が管理ツール上に設定されているか確認してください。<br>\nまた配信先本体がテスト配信環境に接続する設定になっているか確認してください。<br>\nテスト配信ログから、お使いのデバイスからアクセスがあったかどうか確認することができます。<br>\nここで挙げた以外にもテスト配信できない要因は存在します。詳細は以下のドキュメントページからもご確認いただけます。\n",
        "subtitle": "テスト配信ができないとき"
      },
      "notified": "テスト配信アカウントを連携しているNintendo Switchに配信しました",
      "notify": "配信する",
      "title": "テスト配信詳細"
    },
    "tooltips": {
      "devices": "アクセスを許可するNXのシリアルを選択します。ここで選択されたNX以外からデータやニュースを受け取ることはできません。",
      "name": "テスト配信のリストに名前をつけます。配信処理には関係のない管理上の名前です。",
      "nintendo_accounts": "通知を送信するNintendo Accountを指定します。通知を受け取るには選択したNintendo AccountでNXにログインしている必要があります。"
    }
  },
  "data_size_chart_params": {
    "now": "現在",
    "yaxis": "データ配信キャッシュストレージ使用率 [%]"
  },
  "data_tables": {
    "language": {
      "oAria": {
        "sSortAscending": ": 列を昇順に並べ替えるにはアクティブにする",
        "sSortDescending": ": 列を降順に並べ替えるにはアクティブにする"
      },
      "oPaginate": {
        "sFirst": "先頭",
        "sLast": "最終",
        "sNext": "次",
        "sPrevious": "前"
      },
      "sEmptyTable": "過去6時間にアクセスログがありません",
      "sInfo": " _TOTAL_ 件中 _START_ から _END_ まで表示",
      "sInfoEmpty": " 0 件中 0 から 0 まで表示",
      "sInfoFiltered": "（全 _MAX_ 件より抽出）",
      "sInfoPostFix": " ",
      "sInfoThousands": ",",
      "sLengthMenu": "_MENU_ 件表示",
      "sLoadingRecords": "読み込み中...",
      "sProcessing": "処理中...",
      "sSearch": "検索:",
      "sZeroRecords": "一致するレコードがありません"
    }
  },
  "data_topics": {
    "edit": {
      "title": "%{npns_topic_id}の編集"
    },
    "index": {
      "title": "データ配信の管理"
    },
    "show": {
      "title": "%{npns_topic_id}の詳細"
    }
  },
  "data_topics_controller": {
    "notify": {
      "notice": "Notified"
    }
  },
  "datafiles": {
    "confirm": {
      "destroy_warning": "1度でも配信したことのある配信データは、分析等の目的で残しておくことを推奨しています。\nこの配信データを本当に削除しますか？\n"
    },
    "duplicate": {
      "from": "複製元",
      "title": "配信データの複製",
      "to": "複製先"
    },
    "edit": {
      "title": "配信データ"
    },
    "hints": {
      "filename": "ファイル名は31文字以下で英数大文字小文字、`_`、`.`のみが使えます。"
    },
    "index": {
      "title": "配信データ"
    },
    "new": {
      "title": "新規配信データ"
    },
    "publishings": {
      "index": {
        "publishing_at": "この日時に公開中",
        "title": "公開中の配信データ"
      },
      "show": {
        "title": "公開中の配信データ詳細"
      }
    },
    "show": {
      "title": "配信データの詳細"
    }
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "debugs": {
    "index": {
      "disable_becase_of_inconsistent_status": "国グループに不整合が発生しています。開発者に連絡してください。",
      "disable_becase_of_officla_channel": "公式チャンネルは「国グループの管理」から変更してください"
    }
  },
  "deliverables": {
    "action": {
      "approve_censor": "任天堂レビューの承認",
      "approve_publishing": "配信の承認",
      "approve_review": "配信前レビュー承認",
      "approve_review_and_request_censor": "配信前レビューの承認と任天堂レビューの依頼",
      "approve_review_and_request_tencent_censor": "配信前レビューの承認とTencentレビューの依頼",
      "approve_tencent_censor": "Tencentレビューの承認",
      "cancel": "キャンセル",
      "cancel_censor": "任天堂レビューの取り下げ",
      "cancel_review": "配信前レビューの取り下げ",
      "cancel_tencent_censor": "Tencentレビューの取り下げ",
      "duplicate": "複製",
      "emergency_force_modify": "ニュースの緊急停止",
      "expire": "配信終了",
      "expire_and_modify": "配信停止",
      "force_modify": "内容の強制修正",
      "modify": "内容の修正",
      "notify": "通知を送る",
      "preview": "プレビュー",
      "publish": "配信を開始",
      "reject_censor": "任天堂レビューの却下",
      "reject_review": "配信前レビューの却下",
      "reject_tencent_censor": "Tencentレビューの却下",
      "request_censor": "任天堂レビューの依頼",
      "request_review": "配信前レビューの依頼",
      "request_tencent_censor": "Tencentレビューの依頼",
      "show_censor": "詳細(検閲用)",
      "stop_by_dirty_word_detection": "ダーティワードが見つかったため配信を停止",
      "unapprove": "承認の取り消しと内容の修正"
    },
    "confirm": {
      "cannot_approve_discovery": "配信開始日時が未来のニュースがディスカバリーに配信中／承認済みのため、このニュースは配信できません。",
      "emergency_force_modify": "ニュースの状態に関わらず、編集中に変更します。（業務部向けの機能です。）",
      "error": "エラー",
      "expire": "配信終了日時を待つことなく、ニュースの状態を配信終了状態にします。よろしいですか？（この操作はデバッグ用です）",
      "expire_and_modify": "配信を停止して編集中に変更します。チャンネル詳細の配信中のニュースからも見えなくなります。よろしいですか？",
      "force_modify": "ニュースの状態に関わらず、編集中に変更します。よろしいでしょうか？（この操作はデバッグ用です）",
      "modify": "内容を修正してもよろしいですか？",
      "note": "注意",
      "note_details": "各国につき1つのニュースのみ配信可能です。重複する場合、最新の配信開始日時のものが表示されます。",
      "note_tips": "配信開始日時を過ぎたニュースは配信終了となります。国と配信開始日時の設定にご注意ください。",
      "publish": "このニュースをディスカバリーに配信しますか？",
      "publish_tips": "配信開始日時以降、このニュースがディスカバリーに掲載されるようになります。"
    },
    "status": {
      "censor_requesting": "任天堂レビュー依頼中",
      "discovery": "ディスカバリー記事",
      "discovery_visible_in_online_archives": "ディスカバリー記事\n（プロモーション中）\n",
      "draft": "編集中",
      "expired": "配信終了",
      "movie_expired": "期限切れ",
      "normal": "通常記事",
      "passed_display_limit": "表示期限切れ",
      "publishing": "配信中",
      "publishing_approved": "配信承認済",
      "review_requesting": "配信前レビュー依頼中",
      "tencent_censor_requesting": "Tencentレビュー依頼中",
      "visible_in_online_archives": "通常記事\n（チャンネル詳細に表示中）\n"
    }
  },
  "detecting_dirty_word_mailer": {
    "withdraw": {
      "body": "Dirty Wordが検出されましたため、編集ステータスをdraftに変更しました。\nこのニュースが配信中だった場合は、一旦配信が停止されていますので、\n配信を再開するためにDirty Wordを修正して、再度配信フローをすすめてください。\n",
      "subject": "BCAT (%{env}) よりニュースのフェーズ変更のお知らせ (Dirty Word 検出: %{name})"
    }
  },
  "devices": {
    "edit": {
      "title": "配信先本体の編集"
    },
    "errors": {
      "invalid_csv": "csvのフォーマットが正しくありません。"
    },
    "index": {
      "title": "配信先本体の管理"
    },
    "new": {
      "empty_csv": "csvファイルが指定されていません。",
      "import_csv": "csvファイルから一括インポート",
      "import_size_overflow": "csvのレコードが多すぎます。%{maximum}以内にしてください。",
      "invalid_csv": "csvファイルのフォーマットが不正です。",
      "title": "配信先本体の追加"
    },
    "show": {
      "title": "配信先本体の詳細"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "メールアドレスの確認",
        "greeting": "%{recipient}様",
        "instruction": "以下のリンクをクリックし、メールアドレスの確認手続を完了させてください。",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "%{recipient}様",
        "instruction": "パスワード再設定の依頼を受けたため、メールを送信しています。下のリンクからパスワードの再設定ができます。",
        "instruction_2": "パスワード再設定の依頼をしていない場合、このメールを無視してください。",
        "instruction_3": "パスワードの再設定は、上のリンクから新しいパスワードを登録するまで完了しません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "%{recipient}様",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか？",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当によろしいですか？",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか？",
        "didn_t_receive_unlock_instructions": "アカウントのロック解除方法のメールを受け取っていませんか？",
        "forgot_your_password": "パスワードを忘れましたか？",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントのロック解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "directories": {
    "alert": {
      "too_many_messages": "現在 %{country_group} 向けに%{count}件のニュースが配信中です。\n1つの国グループに配信しているニュースが%{max_count}件以下になるように調整してください。\n"
    },
    "confirm": {
      "destroy_warning": "ディレクトリを削除すると、所属する配信データも削除されます。\n1度でも配信したことのあるデータは、分析等の目的で残しておくことを推奨しています。\nこのディレクトリを本当に削除しますか？\n"
    },
    "edit": {
      "title": "%{directory_name}の編集"
    },
    "hints": {
      "filename": "ディレクトリ名は31文字以下で英数大文字小文字、`_`のみが使えます。"
    },
    "index": {
      "data_size_chart": "データ配信キャッシュストレージの利用状況",
      "document_url": "https://developer.nintendo.com/html/online-docs/g1kr9vj6-ja/document.html?doc=Packages/Docs/Guides/News_Delivery_Guide/NintendoSwitch-News_Delivery_Guide.html",
      "new_directory": "ディレクトリの新規作成",
      "title": "ディレクトリ一覧",
      "warning_text_html": "日本向けにゲームニュースを配信する場合、民法第548条の2から第548条の4に基づき、ニュースチャンネルの機能のご利用には<a href=\"%{url}\" target=\"_blank\" rel=\"noopener noreferrer\">ゲームニュース配信ガイド</a>が適用されます。必ず事前にお読みいただき、同意・遵守いただける場合のみ、ご利用ください。"
    },
    "new": {
      "title": "ディレクトリの新規作成"
    },
    "show": {
      "confirm_expire_deliverables": "すべて配信停止に変更しますか？",
      "confirm_modify_deliverables": "すべて編集中に変更しますか？",
      "expire_deliverables": "配信アイテムをすべて配信停止に変更",
      "modify_deliverables": "配信アイテムをすべて編集中に変更",
      "title": "%{directory_name}の詳細"
    }
  },
  "distribution_sizes": {
    "datafiles": {
      "title": "データ配信量一覧"
    },
    "message_details": {
      "title": "ニュース配信量一覧"
    }
  },
  "documents": {
    "index": {
      "download_link": "[ダウンロード]",
      "external_link": "[外部リンク]",
      "management_tool_usage_manual": "BCAT管理ツール取扱説明書",
      "news_delivery_guide": "ゲームニュース配信ガイド",
      "private_management_tool_usage_manual": "BCAT管理ツール取扱説明書 社内限定版",
      "private_news_delivery_guide": "ゲームニュース配信ガイド プライベート版",
      "title": "ドキュメント"
    }
  },
  "documents_controller": {
    "update": {
      "notice": "Uploaded"
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "featured_products": {
    "action": {
      "fetch": "価格情報を取得",
      "fetch_all": "一括取得",
      "fetching": "取得中"
    },
    "additional_labels": {
      "label": "商品のラベル",
      "tooltip": "商品に「NEW」や「あらかじめダウンロード」などのラベルをつけることができます。"
    },
    "comment": {
      "label": "商品の紹介コメント",
      "tooltip": "商品の紹介をするテキストです。各言語用のテキストを入力することができます。"
    },
    "country_price": {
      "label": "価格の国設定",
      "tooltip": "指定した国の価格とフォーマットを取得できます。"
    },
    "demo_info": {
      "label": "体験版情報",
      "tooltip": "「体験版あり」といった体験版の情報を補足します。各言語用のテキストを入力することができます。"
    },
    "discount": {
      "label": "セール"
    },
    "discount_price": {
      "label": "割引後の価格",
      "tooltip": "割引後の価格を入力します。国、言語によらず同じ価格が表示されます。"
    },
    "regular_price": {
      "label": "通常価格",
      "tooltip": "商品の通常価格のを入力します。国、言語によらず同じ価格が表示されます。"
    },
    "sale_info": {
      "label": "セール情報",
      "tooltip": "「SALE」「20% OFF」のような見出しとなるセール情報です。各言語用のテキストを入力することができます。"
    },
    "selector": {
      "label": "ランキング・特集ニュースの商品",
      "tooltip": "ランキング・特集ニュースでとりあげる商品を選択します。%{maximum}個まで選択することができます。"
    },
    "supplement": {
      "label": "セール情報補足",
      "tooltip": "セール期間の表示といったセール情報の補足をします。各言語用のテキストを入力することができます。"
    }
  },
  "general_news": {
    "confirm": {
      "please_destroy_news_before_destroy_channel": "チャンネルにニュースが残っていると、不整合が生じてしまう可能性があります。"
    },
    "edit": {
      "title": "%{general_news_name}の編集"
    },
    "index": {
      "manage_topic": "トピックの管理",
      "search_field": {
        "name_not_cont": "名前が含まれない"
      },
      "title": "チャンネル一覧"
    },
    "manage_role": {
      "general_news_role": "ニュースロール",
      "request_role": "ロールのリクエスト",
      "title": "ユーザリスト"
    },
    "my_role": {
      "current_role": "現在のロール",
      "requesting": "要求中",
      "role_request_desc": "ロールを変更したい場合は、ロールの種類を選択してリクエストしてください。リクエストがManagerに承認されると、変更が完了します。",
      "title": "マイロール"
    },
    "new": {
      "title": "チャンネルの新規作成"
    },
    "show": {
      "manage_data": "配信データの管理",
      "manage_topic": "トピックの管理",
      "title": "%{general_news_name}の詳細",
      "toggle_display_countries": "詳細の表示切り替え"
    },
    "tooltips": {
      "countries": "ニュースが対応する国を選択します。",
      "expiration_time": "チャンネルを終了する日時を指定します。",
      "name": "管理ツール上の名前です。ユーザには表示されません。",
      "news_icon_image": "ニュース送信元として表示されるアイコンを設定します。",
      "publisher_name": "ニュース送信元として表示される名前を設定します。",
      "publishing_time": "チャンネルを公開する日時を指定します。",
      "service_status": "ニュースの運用状態を指定します。"
    }
  },
  "general_news_controller": {
    "update_role": {
      "alert": "User is required."
    }
  },
  "helpers": {
    "page_entries_info": {
      "more_pages": {
        "display_entries": "%{total} 件中 %{first} ~ %{last}件目のデータを表示中"
      },
      "one_page": {
        "display_entries": {
          "one": "1 件中 1 ~ 1件目のデータを表示中",
          "other": "%{count} 件中 1 ~ %{count}件目のデータを表示中",
          "zero": "0 件中 0 ~ 0件目のデータを表示中"
        }
      }
    },
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "i18n": {
  },
  "ip_restrictions": {
    "edit": {
      "title": "IPアドレス制限の編集"
    },
    "index": {
      "no_ip_restrictions": "IPアドレスによる制限が設定されていません。",
      "restrictions_for_list": "設定したIPアドレス制限は本番配信にのみ適用されます。テスト配信には適用されません。",
      "restrictions_for_list_and_test_list": "設定したIPアドレス制限は本番配信とテスト配信両方に適用されます。",
      "restrictions_for_test_list": "設定したIPアドレス制限はテスト配信にのみ適用されます。本番配信には適用されません。",
      "title": "IPアドレス制限"
    },
    "new": {
      "title": "IPアドレス制限の作成"
    },
    "show": {
      "title": "IPアドレス制限の詳細"
    }
  },
  "jedi_records": {
    "message": {
      "title": "%{summary}の統計"
    },
    "virtual_file": {
      "title": "%{filename}の統計"
    }
  },
  "jobs_controller": {
    "retry": {
      "notice": "Enqueued."
    },
    "stop": {
      "notice": "Updated."
    }
  },
  "log_request_mailer": {
    "notify": {
      "created_at": "リクエストされた時間",
      "end_at": "集計終了時間",
      "expiration_at": "有効期限",
      "follow_url": "以下のURLからログのダウンロードが可能です。",
      "request_log_information": "リクエストされたログの情報",
      "start_at": "集計開始時間",
      "subject": "BCAT (%{env}) よりログリクエスト準備完了のお知らせ"
    }
  },
  "log_requests": {
    "index": {
      "title": "ログリクエスト"
    }
  },
  "lushan": {
    "action": {
      "refresh": "中国向けアカウント: %{name} の更新に成功しました",
      "success": "中国向けアカウントの連携に成功しました"
    },
    "confirm": {
      "destroy": "中国向けアカウントの連携を解除してもよろしいですか？"
    },
    "index": {
      "add": "中国向けアカウント追加",
      "destroy": "紐付け解除",
      "follow_logout/html": "中国向けアカウントのセッションが残っていると、意図した配信先アカウントが追加できない場合があります。<br />その場合は、中国向けアカウントサイトでログアウトを行い、再度アカウントの追加を行って下さい。",
      "logout_html": "中国向けアカウントページへ",
      "name": "ニックネーム",
      "title": "配信先中国向けアカウントの管理"
    }
  },
  "mail_settings": {
    "edit": {
      "all_description": "BCAT管理ツールが送信するメールの受信設定を変更できます。",
      "title": "メール設定"
    },
    "form": {
      "another": "上記以外",
      "hint": {
        "mail_enabled_html": "例えば、以下が該当します。\n- ロールの変更\n- データ配信に関する操作\n",
        "mail_enabled_html_for_nintendo": "- 任天堂向け：特別なチャンネル（国グループが設定されていない「Nintendo Switchインフォメーションセンター」など）のニュースの操作\n"
      },
      "news": "ニュースのメール通知",
      "regions": {
        "AUS": "豪州宛て/for Austrarian and New Zealand の通知を受信する",
        "CHN": "中国宛て/for China の通知を受信する",
        "EUR": "欧州宛て/for European の通知を受信する",
        "JPN": "日本宛て/for Japan の通知を受信する",
        "KOR": "韓国宛て/for Korea の通知を受信する",
        "TWN": "香港台湾宛て/for Hong Kong and Taiwan の通知を受信する",
        "USA": "米州宛て/for American の通知を受信する",
        "hint": "チャンネルに一括で適用されます。\nすべてをoffにするとニュースのメール通知が届かなくなります。\n",
        "label": "受信したい国グループを選択してください"
      }
    }
  },
  "main_contents": {
    "action": {
      "add_movie_url": "メイン動画%{index}に追加"
    },
    "form": {
      "body": "本文%{index}",
      "main_image": "メイン画像%{index}",
      "main_movie": "メイン動画(eShop用動画連携ID)%{index}"
    },
    "tooltips": {
      "body": "ニュース本文は強調表現(<strong></strong>)が利用できます。\n強調表現の入れ子(例えば強調表現のなかで強調表現を使う)をサポートしていません。\n",
      "body_v6": "ニュース本文は強調表現(<strong></strong>)と見出し表現(<header></header>)が利用できます。\n強調表現と見出し表現は入れ子(例えば強調表現のなかで見出し表現を使う)をサポートしていません。\n例外的に見出し表現の中で強調表現を使うことができます。\nサポートしていない入れ子を利用した場合、プレビューと本体での表示が異なる場合があります。\n見出し表現は1つの本文中に10回までしか使うことができません。\n1つの見出し表現は2行までしか表示しません。\n3行以上の見出し表現になる場合、3行目以降は非表示になります。\n",
      "body_v7_or_later": "ニュース本文は強調表現(<strong></strong>)、斜体文字(<ita></ita>)と見出し表現(<header></header>)が利用できます。\nサポートしていない順序で装飾を行った場合、プレビューと本体での表示が異なる場合があります。\n強調表現のなかで斜体文字を使うと強調表現かつ斜体文字を表現できます。\n斜体文字のなかで強調表現を使った場合も同様です。\n見出し表現の中で強調表現と斜体文字を使うことはできますが、\n強調表現と斜体文字の中で見出し表現を使うことはできません。\n見出し表現は1つの本文中に10回までしか使うことができません。\n1つの見出し表現は2行までしか表示しません。\n3行以上の見出し表現になる場合、3行目以降は非表示になります。\n",
      "main_movie": "ニュース詳細画面に表示する動画のeShop用動画連携IDです。NCMSで申請したeShop用動画連携IDを指定して下さい。"
    }
  },
  "message_details": {
    "error": {
      "failed_to_copy_message_details": "代替言語からのコピーして保存に失敗しました。フッターなどの一部の項目は言語によって文字数制限が異なるため、コピー元の言語で文字数制限を満たしていても、コピー先の言語でエラーになる場合があります。コピー先の言語でも文字数制限を満たすようにコピー元の言語の文字数を調整して、もう一度代替言語のニュースをコピーして更新を実行してください。"
    },
    "form": {
      "add_main_content": "メイン動画、メイン画像、本文を追加",
      "copy_featured_products_to_shop_list_link": "ランキング・特集ニュースの商品をコピー",
      "copy_rating": "他の言語タブからコピーする",
      "remove_main_content": "メイン動画、メイン画像、本文の削除",
      "set_default_text": "標準テキストを設定する",
      "suggest_video_list": {
        "check_all": "全てチェック",
        "remove_all": "全て外す",
        "title": "選択したソフトから動画を検索",
        "video_list_title": "関連動画リスト"
      },
      "view_on_ncms": "NCMSで確認する"
    },
    "hints": {
      "filter": "絞り込みたい文字を入力して下さい。",
      "strong_and_header_tags_are_available": "このフォームでは強調表現<strong></strong>と見出し表現<header></header>が使えます。見た目はプレビューで確認してください。",
      "strong_and_ita_tags_are_available": "このフォームでは強調表現<strong></strong>と斜体文字<ita></ita>を使った強調表示が使えます。見た目はプレビューで確認してください。",
      "strong_header_and_ita_tags_are_available": "このフォームでは強調表現<strong></strong>と斜体文字<ita></ita>と見出し表現<header></header>が使えます。見た目はプレビューで確認してください。"
    },
    "tooltips": {
      "allow_domains": "ブラウザへ遷移させた後にアクセスを許可するドメインの一覧を指定します。この項目で指定しなかったドメインへのアクセスはブロックされます。",
      "application_arg": "アプリを起動する際に渡すパラメータを最大4KiBのバイナリで指定できます。",
      "application_arg_from_v4_on": "アプリを起動する際に渡すパラメータを最大16KiBのバイナリで指定できます。",
      "auto_play": "一覧画面でリスト動画を再生するかどうかを指定します。※リスト動画が登録されている場合に有効です。",
      "body": "ニュースの本文です。",
      "copy_rating": "指定した言語のESRBのレーティング情報をコピーします。コピーした後にコピー元を変更した場合、コピー先に自動で反映されることはありません。必要に応じて再度コピーボタンを押して下さい。",
      "decoration_text": "ピックアップ特別表示の吹き出しで表示する文字列です。",
      "featured_list_name": "ランキング・特集商品リストをeShopで表示するときにリストの名称として表示されます。",
      "filter": "入力された文字が含まれるものに絞り込まれます。",
      "footer_text": "ニュースの末尾に表示するテキストです。本文よりも抑えた見た目で表示されます。",
      "list_image": "エントランスやニュース一覧画面に表示する画像です。",
      "list_image_v10": "エントランスやニュース一覧画面に表示する画像です。「リスト動画」と「リスト画像」のうち少なくとも1つは設定が必須です。",
      "list_movie": "エントランスやニュース一覧画面に表示する動画のeShop用動画連携IDです。NCMSで申請したeShop用動画連携IDを指定して下さい。「リスト動画」と「リスト画像」のうち少なくとも1つは設定が必須です。",
      "main_image": "ニュース詳細画面に表示する画像です。「メイン動画」と「メイン画像」のうち少なくとも1つは設定が必須です。",
      "main_movie": "ニュース詳細画面に表示する動画のeShop用動画連携IDです。NCMSで申請したeShop用動画連携IDを指定して下さい。「メイン動画」と「メイン画像」のうち少なくとも1つは設定が必須です。",
      "more_application_ids": "ニュースから起動するアプリのApplication IDを指定してください。最大5個まで指定できます。",
      "more_application_text": "リンクボタンに表示するテキストです。",
      "more_applications": "ニュースから起動するアプリのApplicationを指定してください。最大5個まで指定できます。",
      "more_link_text": "リンクボタンに表示するテキストです。",
      "more_link_url": "リンクボタンからブラウザで表示するコンテンツのURLです。",
      "more_nso_relative_url": "リンクボタンからシステムアプリ「Nintendo Switch Online」で表示するページを指定することができます。",
      "more_nso_text": "リンクボタンに表示するテキストです。",
      "more_shop_query": "リンクボタンからeShopで表示するページを指定することができます。",
      "more_shop_text": "リンクボタンに表示するテキストです。",
      "more_system_applet_text": "リンクボタンに表示するテキストです。",
      "more_system_applet_type": "リンクボタンから起動する本体機能を指定します。",
      "more_type": "リンクの種類を指定します。",
      "movie_name": "動画の名称を指定します。名称は全ての動画に指定する or 全ての動画に指定しない、のどちらかになります。",
      "ratings": "ニュースレーティングを指定します。基本的にはアプリケーションと同じレーティングを指定してください。",
      "related_movies": "最大9個まで指定できます。NCMSで申請したeShop用動画連携IDを貼り付けて追加を押して下さい。",
      "show_contents_excuse": "「%{description}」という文言をフッターの末尾に含めるかどうかを指定します。特別な理由がない限り原則「含める」を指定してください。",
      "show_distribution_area": "「このニュースは〇〇地域のアカウント宛です。」という文言をフッターの末尾に含めるかどうかを指定します。特別な理由がない限り原則「含める」を指定してください。",
      "show_list_movie": "エントランスやニュース一覧画面に表示する動画のeShop用動画連携IDです。",
      "show_nso_precaution": "オンラインプレイにはNintendo Switch Onlineへの加入が必要な旨をフッターの末尾に含めるかどうかを指定します。",
      "start_seconds": "一覧画面上でリスト動画を再生する開始秒数を指定します。※リスト動画が登録されている場合に有効です。",
      "subject": "ニュースのタイトルです。「リスト画像にニュースのタイトルを重ねて表示」を「表示する」にした場合は、エントランスでリスト画像に重ねて表示されます。",
      "suggest_video_list": {
        "failed_notify": "%{language} リストの反映に失敗しました。",
        "main": "ソフトに紐づく関連動画をサジェストします。",
        "notify": "%{language} リストを反映しました。",
        "video_list": "ソフトに紐づく関連動画の概要一覧です。"
      }
    }
  },
  "message_serials": {
    "tooltips": {
      "published": "紐付いているニュースが公開状態になると自動的に編集できなくなります。",
      "serial": "Switch本体のシリアルナンバーの左から13桁目の値の数値です。"
    }
  },
  "messages": {
    "action": {
      "add_list_movie_url": "リスト動画に追加",
      "add_movie_url": "メイン動画に追加",
      "add_related_movies": "関連動画に追加"
    },
    "alert": {
      "warning_update_publishing_time": "配信開始日時を変更する場合は、ニュースの内容が更新後の配信開始日時時点で正しい内容になっているか確認してください。"
    },
    "confirm": {
      "copy_commit": "代替言語のニュースで他の言語のニュースを上書きします。よろしいですか？",
      "destroy_warning": "1度でも配信したことのあるニュースは、分析等の目的で残しておくことを推奨しています。\nこのニュースを本当に削除しますか？\n"
    },
    "duplicate": {
      "cannot_duplicate_message": "このニュースは複製できません。新しいニュースを作り直してください",
      "copy_products": "関連商品情報をコピーします",
      "copy_rating": "レーティング情報をコピーします",
      "description_html": "ニュースをディレクトリをまたいで複製することができます。<br />\n各言語のニュース文言をどの言語として複製するか選択することができます。<br/>\n言語別配信をしないディレクトリから言語別配信をするディレクトリにコピーすると、自動で国グループが指定されます(修正予定)。\n",
      "from": "複製元",
      "message_detail": "ニュースの文言",
      "title": "ニュースの複製",
      "to": "複製先"
    },
    "edit": {
      "copy_commit": "代替言語のニュースをコピーして更新",
      "discovery_title": "ディスカバリー記事の編集",
      "title": "ニュースの編集"
    },
    "error": {
      "failed_to_fetch_products": "商品情報の読み込みに失敗しました。ページをリロードしてやりなおしてください。"
    },
    "form": {
      "decoration_on": "する",
      "destroy_message_detail": "この言語の設定を削除する",
      "enable_to_access_outer_domains": "指定したURLのドメインとは異なるドメインへのアクセスを許可する",
      "language_specific_settings": "言語毎の設定",
      "manage_country_groups": "国グループの設定",
      "manage_news_icon": "ニュースアイコンの設定",
      "must_be_selected_at_least_one_country": "1つ以上の国を宛先に指定してください。",
      "pickup_function_on": "する",
      "require_country_groups": "配信する国グループの設定をしてください。",
      "require_news_icon_settings": "ニュースアイコンの設定をしてください。",
      "select_all_countries": "すべての国を選択",
      "too_long_header": "%{header}はNintendo Switch上で3行以上になるので正しく表示できません。",
      "unselect_all_countries": "すべての国の選択解除"
    },
    "hints": {
      "essential_pickup_limit_should_be_up_to_1week": "必須ピックアップ期限は配信開始日時から1週間程度が推奨されています。",
      "line_feed_position_may_not_be_accurate": "改行位置は正しくない場合があります。正確な改行位置は本体で確認してください。",
      "nested_tags_are_unsupported": "headerタグの中のstrongタグ以外のネストはプレビューでもNintendo Switch 本体でもサポートされていません。タグがネストしている場合、プレビューの表示は必ずしもNintendo Switch本体での見た目と一致していません。",
      "only_support_chrome": "プレビューはChrome以外のブラウザでは正しく表示できない場合があります。",
      "preview_area_emulate_nx_screen_size": "プレビューの表示領域は本体のディスプレイサイズとほぼ同じです。見え方の参考にしてください。",
      "preview_area_is_scrollable": "表示エリアはスクロールできます。"
    },
    "index": {
      "message_detail_subject": "ニュースのタイトル",
      "title": "ニュース一覧"
    },
    "new": {
      "copy_commit": "代替言語のニュースをコピーして登録",
      "discovery_title": "ディスカバリー記事の新規作成",
      "title": "ニュースの新規作成"
    },
    "preview": {
      "entrance": "エントランスの表示",
      "news_list_item": "一覧での表示",
      "news_list_item_searched": "絞り込みしたときの表示",
      "news_list_item_unread": "未読のときの表示",
      "pickup": "ピックアップ表示",
      "print_preview": "印刷用ページ",
      "related_channel_subscribe": "ためし読み"
    },
    "show": {
      "back_to_censor": "ニンテンドーレビュー中ニュース一覧に戻る",
      "back_to_tencent_censor": "Tencentレビュー中ニュース一覧に戻る",
      "bookmark": "ブックマーク",
      "debug_button_column": "デバッグ用機能",
      "discovery_title": "ディスカバリー記事の詳細",
      "display_expiration_warning": "表示期限を迎えたニュースを更新しても、Nintendo Switchに保存されているニュースの内容を更新することはできないので注意してください。",
      "download_msgpack": "[デバッグ用] msgpackのダウンロード",
      "has_dirty_words": "不適切な文言が含まれています。配信オペレーションを進めるために内容を修正してください。",
      "in_progress": "不適切な文言が含まれていないかチェック中です。チェックが完了するまで配信オペレーションを進められません。",
      "like": "いいね！",
      "message_is_invalid": "このニュースは何らかの理由でデータに不整合が発生しているため、複製やステータスの変更ができません。",
      "no_nsa_list": "宛先リストが指定されていません。承認フローをすすめる前に宛先リストを指定してください。",
      "old_semantics_version_alert": "このニュースはバージョンが古いため、一部機能が使えない可能性があります。 さらにこのニュースを複製すると、バージョンは古いまま引き継がれるため、 複製先のニュースでも最新の機能が使えないバージョンのニュースになります。 最新の機能を利用するためには、このニュースを複製せず新規作成してください。",
      "online_archives": "記事種別",
      "title": "ニュースの詳細",
      "unlike": "う〜ん..."
    },
    "status": {
      "online_archives": "記事種別"
    },
    "tooltips": {
      "caption": "エントランスでリスト画像にタイトルを重ねて表示するかどうかを指定します。",
      "country_group": "配信したい国グループを指定します。宛先の国はニンテンドーアカウントの国で判別します。また、国のチェックを外すことで特定の国を配信対象から除外することもできます。",
      "decoration_type": "エントランスでニュースを強調表示します。",
      "default_language": "本体設定言語の文言がない場合に、代わりに表示する言語を指定できます。",
      "deletion_priority": "ニュースの保存件数を超えた時にMIDDLEが指定されているものから削除されます。",
      "display_limit": "受信済みのニュースが表示できなくなる時刻です。版権などの理由で厳密に表示期間を制限したい場合に指定してください。",
      "display_type": "ニュースの表示方法です。NORMALは通常のニュース、LISTは特集ニュース、RANKINGはランキングニュースが作成できます。",
      "essential_pickup_limit": "指定した期間はかならずピックアップされます。必須ピックアップするニュースが同時に3件以上ある場合は、ピックアップされない場合もあります。固定必須ピックアップ「する」の場合は、固定必須ピックアップする期間になります。",
      "essential_priority": "必須ピックアップに加えて既読になっても期間中はピックアップされつづけます。",
      "expiration_time": "配信終了日時を指定します。この時刻になると、ニュースは取得できなくなります。",
      "invalid_product": "不正な商品のため、保存するとニュースから紐付きが解除されます。",
      "no_photography": "ニュースのスクリーンショットを禁止とするかどうかを指定します。",
      "note": "概要の補足説明がある場合は入力してください。この項目は配信されません。",
      "online_archives": "記事種別とゲーム機で閲覧可能かどうかを表します。",
      "pickup_limit": "エントランスやニュース一覧画面でピックアップされる期限です。デフォルトでは配信開始日時+1週間を自動で設定します。",
      "priority": "ピックアップするに設定すると、エントランスやピックアップ欄にニュースが表示されることがあります。",
      "products": "関連商品欄に表示する商品を指定します。",
      "publishing_time": "配信開始日時を指定します。この時刻になると、ニュースが自動的に配信されます。",
      "related_topics": "関連チャンネルに表示するチャンネルを指定します。",
      "searchable_application_ids": "関連するアプリケーションのアプリケーションIDを追加してください。ここで指定したアプリケーションを遊んでいるときにエントランスに表示されやすくなります。",
      "summary": "ニュース管理上の説明です。この項目は配信されません。"
    }
  },
  "messages_survey": {
    "index": {
      "title": "ニュースの一覧"
    }
  },
  "msgpacks": {
    "index": {
      "disable_with": "続けてダウンロードする場合は、ダウンロード完了後にリロードしてください。",
      "no_message_details": "ニュースがありません",
      "select_help": "チャンネル/言語を選択しない場合は、全チャンネル全言語から最新%{max_msgpack_count}件のニュースを取得します。",
      "title": "動作確認用MessagePack"
    }
  },
  "ndids": {
    "index": {
      "current_role": "現在のロール",
      "desc": "あなたが参照できる NDID ユーザをリストアップしています。これらのユーザにロールを付与することが出来ます。",
      "title": "ユーザの追加",
      "user_not_exist": "ユーザリストに居ません"
    }
  },
  "news_api_keys": {
    "hints": {
      "can_know_secret": "認証情報は、クライアントIDとクライアントシークレットをコロンでつないだ組み合わせになっています。"
    },
    "index": {
      "authorization": "認証情報",
      "title": "NewsApiKey一覧"
    },
    "menu_title": "NewsApiKeyの管理"
  },
  "news_topics": {
    "edit": {
      "title": "%{npns_topic_id}の編集"
    },
    "index": {
      "title": "ニュース配信の管理"
    },
    "show": {
      "title": "%{npns_topic_id}の詳細"
    },
    "tooltips": {
      "countries": "ニュースが対応する国を選択します。",
      "description": "ニュースの趣旨を設定します。",
      "name": "ニュース送信元として表示される名前を設定します。",
      "news_icon_image": "ニュース送信元として表示されるアイコンを設定します。",
      "npns_topic_id": "通知に使うNPNSトピックIDとチャンネル名を選択します。",
      "publishing_time": "ニュースを公開する日時を指定します。",
      "search_string": "FIXME",
      "service_status": "ニュースの運用状態を指定します。"
    }
  },
  "news_topics_controller": {
    "notify": {
      "notice": "Notified"
    }
  },
  "nintendo_accounts": {
    "action": {
      "refresh": "ニンテンドーアカウント: %{name} の更新に成功しました",
      "success": "ニンテンドーアカウントの連携に成功しました"
    },
    "confirm": {
      "destroy": "ニンテンドーアカウントの連携を解除してもよろしいですか？"
    },
    "index": {
      "add": "アカウント追加",
      "destroy": "紐付け解除",
      "follow_logout/html": "Nintendo Account のセッションが残っていると、意図した配信先アカウントが追加できない場合があります。<br />その場合は、Nintendo Accountサイトでログアウトを行い、再度アカウントの追加を行って下さい。",
      "logout_html": "ニンテンドーアカウントページへ",
      "name": "ニックネーム",
      "title": "配信先ニンテンドーアカウントの管理"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%n%u",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "nx": {
    "channel_discription": {
      "expiration_announcement": "本チャンネルは%{year}/%{month}/%{day} (UTC)をもちまして、終了いたしました。",
      "expiration_pre_announcement": "本チャンネルは%{year}/%{month}/%{day} (UTC)をもちまして、終了いたします。%{year}/%{month}/%{day} (UTC)以降は本チャンネルのニュースが閲覧できなくなりますので、ご注意ください。"
    },
    "default_link_text": {
      "browser": "",
      "eshop": {
        "aoc_list": "追加コンテンツ一覧をみる",
        "consumption": "",
        "membership_service": "",
        "membership_top": "ニンテンドーeショップ「Nintendo Switch Online」ページへ",
        "preticket_detail": "ニンテンドーeショップ 「%{product_name}」へ",
        "product_detail": "ニンテンドーeショップ 『%{product_name}』へ",
        "product_list": "",
        "sale": "",
        "subscriptions": "",
        "top": "ニンテンドーeショップへ"
      },
      "game": ""
    },
    "description_of_contents_excuse": "※本ニュースに記載している内容は、ニュース配信開始時点の情報です。",
    "description_of_distribution_area": {
      "jpn": "※このニュースは日本のニンテンドーアカウントに向けて配信されています。"
    },
    "description_of_nso_precaution": "※このソフトにはオンラインプレイに対応したモードがあります。\n　オンラインプレイであそぶにはインターネットに接続できる環境と、\n　Nintendo Switch Onlineへの加入（有料）が必要です。",
    "product": {
      "aoc": "追加コンテンツ",
      "bundle": "セット商品",
      "new": "NEW",
      "pre_order": {
        "above_v8": "予約",
        "below_v7": "予約"
      },
      "pre_purchase": "あらかじめDL",
      "title": "ソフト",
      "upgrade_pass": "アップグレードパス"
    },
    "suggest_product_text": {
      "demo": "体験版あり"
    }
  },
  "one2ones": {
    "edit": {
      "title": "ターゲティングニュースの編集"
    },
    "index": {
      "news_summary": "ニュースの概要",
      "title": "ターゲティングニュースの一覧"
    },
    "new": {
      "title": "ターゲティングニュースの新規作成"
    },
    "show": {
      "title": "ターゲティングニュースの詳細"
    }
  },
  "pickup_aggregations": {
    "action": {
      "notify_china": "China本体へ通知",
      "notify_ounce": "Ounce本体へ通知",
      "notify_ww": "WW本体へ通知",
      "update_uuid": "UUIDの更新"
    },
    "index": {
      "title": "Pickup Aggregation"
    },
    "show": {
      "title": "Pickup Aggregation"
    }
  },
  "pickup_stats": {
    "index": {
      "title": "%{date}の集計"
    }
  },
  "products": {
    "form": {
      "accepted": "処理を受け付けました。処理には数分ほどかかることがあります。",
      "new_product_from_ns_uid": "ns uidを入力し、商品(ソフト、バンドル、AOC、ニンテンドーカタログチケット)を追加します。",
      "not_found": "指定された商品を見つけることができませんでした。"
    },
    "index": {
      "invalid_product": "商品のデータが不正です。",
      "linked_messages": "関連するニュース一覧",
      "refresh": "更新",
      "title": "eShopの商品一覧"
    },
    "messages": {
      "index": {
        "title": "%{product} が関連するニュース一覧"
      }
    },
    "notice": {
      "down_pms_or_ncms": "ニュースが正常に更新できない可能性があります",
      "maintenance_body/html": "ショップサーバがメンテナンス中あるいは障害の可能性があります。<br />動画を含むニュースが更新ができなくなり、eShop商品情報が同期されません。",
      "maintenance_subject": "ショップサーバメンテナンス/障害"
    },
    "tooltips": {
      "platforms": "商品が関連商品等に設定できるニュースが所属しているチャンネルのプラットフォームです。"
    }
  },
  "promotions": {
    "index": {
      "title": "プロモーション設定リスト"
    },
    "new": {
      "title": "プロモーション設定の追加"
    }
  },
  "publishing_mailer": {
    "datafile": {
      "publishing_approval": {
        "body": "データが配信承認されました。\nデータの配信開始時刻になると、配信が開始されます。\n",
        "subject": "BCAT (%{env}) よりデータのフェーズ変更のお知らせ (配信承認: %{name})"
      },
      "publishing_unapproval": {
        "body": "データの配信承認が取り消されました。\nデータを配信するためには、データが再度配信承認される必要があります。\n",
        "subject": "BCAT (%{env}) よりデータのフェーズ変更のお知らせ (配信承認の取り消し: %{name})"
      }
    },
    "message": {
      "publishing_approval": {
        "body": "ニュースが配信承認されました。\nニュースの配信開始時刻になると、配信が開始されます。\n",
        "subject": "BCAT (%{env}) よりニュースのフェーズ変更のお知らせ (配信承認: %{name})"
      },
      "publishing_unapproval": {
        "body": "ニュースの配信承認が取り消されました。\nニュースを配信するためには、ニュースが再度配信承認される必要があります。\n",
        "subject": "BCAT (%{env}) よりニュースのフェーズ変更のお知らせ (配信承認の取り消し: %{name})"
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "rating_systems": {
    "form": {
      "suggest": {
        "apply": "推奨のレーティングシステム設定を反映",
        "label": "配信先の国からレーティングシステムを設定"
      }
    },
    "show": {
      "rating_icon_cache_cannot_be_found": "レーティング画像が見つかりません。レーティングシステムの更新をして下さい。"
    },
    "tooltip": {
      "suggest": "配信先の国から各言語に推奨のレーティングシステムを設定します。"
    }
  },
  "review_mailer": {
    "datafile": {
      "review_approval": {
        "body": "データが配信前レビューの結果承認されました。",
        "subject": "BCAT (%{env}) よりデータのフェーズ変更のお知らせ (配信前レビュー承認: %{name})"
      },
      "review_cancel": {
        "body": "データの配信前レビューが取り下げられました。",
        "subject": "BCAT (%{env}) よりデータのフェーズ変更のお知らせ (配信前レビュー取り下げ: %{name})"
      },
      "review_rejection": {
        "body": "データが配信前レビューの結果却下されました。",
        "subject": "BCAT (%{env}) よりデータのフェーズ変更のお知らせ (配信前レビュー却下: %{name})"
      },
      "review_request": {
        "body": "データが配信前レビューに出されました。",
        "subject": "BCAT (%{env}) よりデータのフェーズ変更のお知らせ (配信前レビュー依頼: %{name})"
      }
    },
    "message": {
      "review_approval": {
        "body": "ニュースが配信前レビューの結果承認されました。",
        "subject": "BCAT (%{env}) よりニュースのフェーズ変更のお知らせ (配信前レビュー承認: %{name})"
      },
      "review_cancel": {
        "body": "ニュースの配信前レビューが取り下げられました。",
        "subject": "BCAT (%{env}) よりニュースのフェーズ変更のお知らせ (配信前レビュー取り下げ: %{name})"
      },
      "review_rejection": {
        "body": "ニュースが配信前レビューの結果却下されました。",
        "subject": "BCAT (%{env}) よりニュースのフェーズ変更のお知らせ (配信前レビュー却下: %{name})"
      },
      "review_request": {
        "body": "ニュースが配信前レビューに出されました。",
        "subject": "BCAT (%{env}) よりニュースのフェーズ変更のお知らせ (配信前レビュー依頼: %{name})"
      }
    }
  },
  "role_request_mailer": {
    "no_company": "情報なし",
    "no_group": "情報なし",
    "role_request_approval": {
      "body": "ロールリクエストが承認されました。",
      "subject": "BCAT (%{env}) よりロールリクエスト承認のお知らせ (%{name})"
    },
    "role_request_cancel": {
      "body": "ロールリクエストが取り下げられました。",
      "subject": "BCAT (%{env}) よりロールリクエスト取り下げのお知らせ (%{name})"
    },
    "role_request_creation": {
      "body": "ロールリクエストが出されました。",
      "subject": "BCAT (%{env}) より新着ロールリクエストのお知らせ (%{name})"
    },
    "role_request_rejection": {
      "body": "ロールリクエストが却下されました。",
      "subject": "BCAT (%{env}) よりロールリクエスト却下のお知らせ (%{name})"
    }
  },
  "role_requests": {
    "confirm": {
      "approve": "ロールリクエストを承認してよろしいですか？",
      "cancel": "ロールリクエストを取り下げてもよろしいですか？",
      "reject": "ロールリクエストを却下してもよろしいですか？"
    },
    "index": {
      "actions": "アクション",
      "current_role": "現在のロール",
      "judged_date": "判断日",
      "judged_manger": "判断した管理者",
      "request_approved": "承認: %{approver}(%{approved_at})",
      "request_cancelled": "取り下げ: %{approver}(%{approved_at})",
      "request_rejected": "却下: %{approver}(%{approved_at})",
      "requested_date": "要求日",
      "requested_role": "要求するロール",
      "requesting": "要求中",
      "role_requests": "ロールリクエスト",
      "status": "状態",
      "title": "ロールリクエスト",
      "user_roles": "ユーザロール"
    },
    "show": {
      "title": "ロールリクエストの詳細"
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "入力内容が不正です。内容を確認してください。"
    },
    "labels": {
      "log_request": {
        "end_at": "集計終了時刻",
        "start_at": "集計開始時刻"
      }
    },
    "no": "No",
    "required": {
      "html": "<span title=\"required\" class=\"required_mark\">【必須項目】</span>",
      "mark": "【必須項目】",
      "text": "必須項目"
    },
    "yes": "Yes"
  },
  "stats": {
    "daily_reports": {
      "index": {
        "title": "デイリーレポート"
      },
      "title": "デイリーレポート"
    },
    "index": {
      "title": "統計情報"
    },
    "log_requests": {
      "index": {
        "before_two_hours": "集計終了時刻は現在より2時間前から指定可能です。",
        "expiration_at": "有効期限",
        "maximum_request_period": "集計期間は計31日分まで指定可能です。それ以上の期間を指定する場合はリクエストを分けて下さい。",
        "request": "ログリクエスト",
        "status": "リクエスト状態",
        "target_period": "対象期間",
        "title": "ログリクエスト",
        "url": "ダウンロードURL"
      },
      "nothing": {
        "description": "現在利用できません",
        "title": "ログリクエスト"
      },
      "title": "ログリクエスト"
    },
    "log_requests_controller": {
      "create": {
        "alert": "403 forbidden"
      }
    }
  },
  "subscribes": {
    "index": {
      "title": "自動購読設定リスト"
    },
    "new": {
      "title": "自動購読設定の追加"
    }
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "swagger-ui": {
    "daily_reports": {
      "operation": {
        "show": {
          "description": "1回のリクエストで最大50件の取得が可能です。",
          "summary": "データ配信のデイリーレポートを取得"
        }
      },
      "parameter": {
        "application_id": "アプリケーションID",
        "date": "ex. 2017-03-03",
        "offset": "オフセット"
      }
    },
    "data_topics": {
      "operation": {
        "show": {
          "description": "トピックの詳細の取得<br/>npns\\_topic\\_idはnx\\_data\\_{アプリケーションID}です。",
          "summary": "トピックの詳細の取得"
        }
      },
      "parameter": {
        "npns_topic_id": "トピックID"
      }
    },
    "datafiles": {
      "operation": {
        "approve": {
          "description": "配信データを承認",
          "summary": "配信データを承認"
        },
        "create": {
          "description": "配信データを新規作成<br/>パラメータはjsonで指定します。<br/>バイナリはBase64エンコードしたテキストとして指定してください。<br/>Base64はRFC2045に基づいたフォーマットをサポートしています。<br/>時間の値は値は ISO 8601（YYYY-MM-DDThh:mm:ss.sZ）形式で指定します。小数点以下の秒数はサーバで切り捨てられます。<br/>YYYY-MM-DDThh:mm:ss.s+09:00のように指定すると、JSTで指定できますが、レスポンスに含まれる時間は常にUTC(YYYY-MM-DDThh:mm:ss.sZ)で表記されます。",
          "summary": "配信データの新規作成"
        },
        "create_next": {
          "description": "連続する次の配信データを新規作成<br/>パラメータはjsonで指定します。<br/>バイナリはBase64エンコードしたテキストとして指定してください。<br/>Base64はRFC2045に基づいたフォーマットをサポートしています。<br/>時間の値は値は ISO 8601（YYYY-MM-DDThh:mm:ss.sZ）形式で指定します。小数点以下の秒数はサーバで切り捨てられます。<br/>YYYY-MM-DDThh:mm:ss.s+09:00のように指定すると、JSTで指定できますが、レスポンスに含まれる時間は常にUTC(YYYY-MM-DDThh:mm:ss.sZ)で表記されます。",
          "summary": "連続する次の配信データを新規作成"
        },
        "delete": {
          "description": "配信データを削除",
          "summary": "配信データを削除"
        },
        "expire": {
          "description": "配信データを配信終了",
          "summary": "配信データを配信終了"
        },
        "index": {
          "description": "配信データの一覧を取得",
          "summary": "配信データの一覧を取得"
        },
        "raw": {
          "description": "管理ツールに登録したデータを取得します",
          "summary": "登録データの取得"
        },
        "show": {
          "description": "配信データの詳細を取得",
          "summary": "配信データの詳細を取得"
        },
        "unapprove": {
          "description": "配信データの配信承認を取り消す",
          "summary": "配信データの配信承認を取り消す"
        },
        "update": {
          "description": "配信データを更新<br/>パラメータはjsonで指定します。<br/>バイナリはBase64エンコードしたテキストとして指定してください。<br/>Base64はRFC2045に基づいたフォーマットをサポートしています。<br/>時間の値は値は ISO 8601（YYYY-MM-DDThh:mm:ss.sZ）形式で指定します。小数点以下の秒数はサーバで切り捨てられます。<br/>YYYY-MM-DDThh:mm:ss.s+09:00のように指定すると、JSTで指定できますが、レスポンスに含まれる時間は常にUTC(YYYY-MM-DDThh:mm:ss.sZ)で表記されます。",
          "summary": "配信データを更新"
        }
      },
      "parameter": {
        "application_id": "アプリケーションID",
        "datafile_id": "配信データID",
        "datafile_params": "パラメータ",
        "directory_id": "ディレクトリID"
      }
    },
    "devices": {
      "operation": {
        "create": {
          "description": "テスト配信本体の新規登録",
          "summary": "テスト配信本体の新規登録"
        },
        "destroy": {
          "description": "テスト配信本体の登録解除",
          "summary": "テスト配信本体の登録解除"
        },
        "index": {
          "description": "テスト配信本体の一覧を取得",
          "summary": "テスト配信本体の一覧を取得"
        },
        "show": {
          "description": "テスト配信本体の詳細を取得",
          "summary": "テスト配信本体の詳細を取得"
        },
        "update": {
          "description": "テスト配信本体の更新",
          "summary": "テスト配信本体の更新"
        }
      },
      "parameter": {
        "application_id": "アプリケーションID",
        "device_id": "テスト配信本体ID",
        "post_parameters": "パラメータ"
      }
    },
    "directories": {
      "operation": {
        "create": {
          "description": "ディレクトリの新規作成<br/>ディレクトリのパラメータはjsonで指定します。",
          "summary": "ディレクトリの新規作成"
        },
        "delete": {
          "description": "ディレクトリの削除",
          "summary": "ディレクトリの削除"
        },
        "index": {
          "description": "ディレクトリの一覧を取得",
          "summary": "ディレクトリの一覧を取得"
        },
        "show": {
          "description": "ディレクトリの詳細を取得",
          "summary": "ディレクトリの詳細を取得"
        },
        "update": {
          "description": "ディレクトリの更新<br/>ディレクトリのパラメータはjsonで指定します。",
          "summary": "ディレクトリを更新"
        }
      },
      "parameter": {
        "application_id": "アプリケーションID",
        "directory_id": "ディレクトリID",
        "directory_params": "パラメータ"
      }
    },
    "ip_restrictions": {
      "operation": {
        "create": {
          "description": "IPアドレス制限の新規登録",
          "summary": "IPアドレス制限の新規登録"
        },
        "destroy": {
          "description": "IPアドレス制限の登録解除",
          "summary": "IPアドレス制限の登録解除"
        },
        "index": {
          "description": "IPアドレス制限の一覧を取得",
          "summary": "IPアドレス制限の一覧を取得"
        },
        "show": {
          "description": "IPアドレス制限の詳細を取得",
          "summary": "IPアドレス制限の詳細を取得"
        },
        "update": {
          "description": "IPアドレス制限の更新",
          "summary": "IPアドレス制限の更新"
        }
      },
      "parameter": {
        "application_id": "アプリケーションID",
        "ip_restriction_id": "IPアドレス制限ID",
        "post_parameters": "パラメータ"
      }
    },
    "news": {
      "description": {
        "/news/{id}": {
          "delete": "id で指定されたニュースを削除します。",
          "get": "id で指定されたニュースの情報を取得します。",
          "put": "id で指定されたニュースの情報を更新します。"
        },
        "/titles/{application_id}/news": {
          "get": "application_id で指定されたアプリケーションに紐付くニュースを全て取得します。",
          "post": "application_id で指定されたアプリケーションに紐付くニュースを新規作成します。"
        }
      },
      "parameter": {
        "age_limit": "ニュースの年齢制限",
        "application_id": "アプリケーション id",
        "bashotorya": "バショトリャー通知であるかどうかのフラグです。1 が設定されていると、ニュースがバショトリャー通知されます。",
        "country_group_id": "ニュースの配信対象国グループです。application_id で指定されたアプリケーションが1つ以上の国グループを持つならば、country_group_id の指定は必須です。",
        "default_language": "ニュースのデフォルト言語",
        "delivery_type": "配信タイプ",
        "display_type": "ニュースの表示タイプ",
        "expiration_time": "配信終了時刻",
        "format_version": "ニュースのフォーマットバージョン",
        "id": "ニュースの id",
        "more_type": "もっと見るの種類",
        "no_photography": "スクリーンショットを許可するかどうかのフラグです。1 に設定されていると、ユーザがニュースのスクリーンショットをとることを許可しません。",
        "note": "管理画面上で表示される、ニュースの説明",
        "pickup_limit": "ニュースがピックアップに入る期間の終了時刻",
        "priority": "ニュースの優先度",
        "publishing_time": "配信開始時刻",
        "related_products": "関連商品を示す、アプリケーション id の配列です。",
        "searchable_application_ids": "アプリケーション id の配列です。ユーザがアプリケーションを指定してニュースを検索する際に利用されます。",
        "semantics_version": "ニュースのセマンティックスバージョン。クライアントのファームウェアがこのニュースを扱うことができるかの判断に使用されます。",
        "summary": "ニュースの概要",
        "surprise": "サプライズ通知であるかどうかのフラグです。1 が設定されていると、ニュースがサプライズ通知されます。"
      }
    },
    "news_topics": {
      "description": {
        "base64_encoded_contents": "Base64 エンコードされた画像のバイナリ",
        "channel_desc": "チャンネルの説明",
        "channel_lang": "チャンネルの言語",
        "channel_name": "チャンネルの名称",
        "channel_publisher": "発行元の名称",
        "company_uuid": "チャンネルを申請した会社の NDID 上の uuid",
        "countries": "配信対象国",
        "default_language": "代替言語",
        "file_name": "ファイル名",
        "file_size": "ファイルサイズ",
        "first_party_publisher": "ファーストパーティのパブリッシャーかどうか",
        "languages": "配信対象言語",
        "manager": "管理ユーザ",
        "news_icon_base64": "Base64 エンコードされたチャンネルのアイコンデータ",
        "news_icon_image": "チャンネルのアイコン",
        "news_type": "ニュースの種類",
        "official_news": "公式ニュースかどうか",
        "policy": "ポリシー設定用のパラメータ",
        "publishing_time": "チャンネルの公開開始日時",
        "user_email": "NDID の E-Mail アドレス",
        "user_loginid": "NDID のログインID",
        "user_name": "NDID の名前",
        "user_uuid": "NDID の uuid"
      },
      "operation": {
        "create": {
          "description": "ニューストピックの作成<br/>パラメータは JSON で指定します。",
          "summary": "ニューストピックの作成"
        },
        "show": {
          "description": "ニューストピックの詳細を取得",
          "summary": "ニューストピックの詳細を取得"
        },
        "update": {
          "description": "ニューストピックの更新",
          "summary": "ニューストピックの更新"
        }
      },
      "parameter": {
        "news_topic_params": "パラメータ",
        "npns_topic_id": "Npns トピック ID"
      }
    },
    "promotions": {
      "operation": {
        "create": {
          "description": "プロモーションを作成<br/>パラメータは JSON で指定します。",
          "summary": "プロモーションの作成"
        },
        "destroy": {
          "description": "プロモーションの削除",
          "summary": "プロモーションの削除"
        },
        "news_topics_index": {
          "description": "ニューストピックのプロモーションリストを取得",
          "summary": "ニューストピックのプロモーションリストを取得"
        },
        "show": {
          "description": "プロモーションの詳細を取得",
          "summary": "プロモーションの詳細を取得"
        },
        "titles_index": {
          "description": "アプリケーションのプロモーションリストを取得",
          "summary": "アプリケーションのプロモーションリストを取得"
        }
      },
      "parameter": {
        "application_id": "アプリケーション ID",
        "npns_topic_id": "NPNS トピック ID",
        "promotion_id": "プロモーション ID",
        "promotion_params": "パラメータ"
      }
    },
    "subscribes": {
      "operation": {
        "create": {
          "description": "購読を作成<br/>パラメータは JSON で指定します。",
          "summary": "購読の作成"
        },
        "destroy": {
          "description": "購読の削除",
          "summary": "購読の削除"
        },
        "news_toics_index": {
          "description": "Get NewsTopic's Subscribers List",
          "summary": "Get NewsTopic's Subscribers List"
        },
        "news_topics_index": {
          "description": "ニューストピックの購読リストを取得",
          "summary": "ニューストピックの購読リストを取得"
        },
        "show": {
          "description": "購読の詳細を取得",
          "summary": "購読の詳細を取得"
        },
        "titles_index": {
          "description": "アプリケーションの購読リストを取得",
          "summary": "アプリケーションの購読リストを取得"
        }
      },
      "parameter": {
        "application_id": "アプリケーション ID",
        "npns_topic_id": "NPNS トピック ID",
        "subscribe_id": "購読 ID",
        "subscribe_params": "パラメータ"
      }
    },
    "test_distributions": {
      "operation": {
        "create": {
          "description": "テスト配信を新規作成<br>パラメータはjsonで指定します。<br>テスト配信の宛先となるSwitchの情報とNintendo Accountはあらかじめ管理ツールで登録したものが指定できます。<br>管理ツールに登録されていないSwitchやNintendo Accountを指定した場合はエラーになります。<br>テスト配信の宛先となるSwitchは本体のシリアルナンバー、Nintendo Accountはidの配列で指定します。<br>テスト配信に含めるデータはdatafileのidを配列で指定します。",
          "summary": "テスト配信の新規作成"
        },
        "destroy": {
          "description": "テスト配信の削除",
          "summary": "テスト配信の削除"
        },
        "index": {
          "description": "テスト配信の一覧を取得",
          "summary": "テスト配信の一覧を取得"
        },
        "notify": {
          "description": "テスト配信の通知<br>テスト配信を新規作成、更新した時にサーバが自動で通知を送信します。<br>新規作成後、もしくは更新後に明示的に通知を送りたい場合にこのapiで通知できます。",
          "summary": "テスト配信の通知"
        },
        "show": {
          "description": "テスト配信の詳細を取得",
          "summary": "テスト配信の詳細を取得"
        },
        "update": {
          "description": "テスト配信の更新<br>パラメータはjsonで指定します。<br>テスト配信の宛先となるSwitchの情報とNintendo Accountはあらかじめ管理ツールで登録したものが指定できます。<br>管理ツールに登録されていないSwitchやNintendo Accountを指定した場合はエラーになります。<br>テスト配信の宛先となるSwitchは本体のシリアルナンバー、Nintendo Accountは管理ツール上で記載されているidの配列で指定します。<br>テスト配信に含めるデータはdatafileのidを配列で指定します。",
          "summary": "テスト配信の更新"
        }
      },
      "parameter": {
        "application_id": "アプリケーションID",
        "post_parameters": "パラメータ",
        "test_distribution_id": "テスト配信ID"
      }
    },
    "titles": {
      "operation": {
        "create": {
          "description": "アプリケーションの新規作成<br/>アプリケーションのパラメータはjsonで指定します。",
          "summary": "アプリケーションの新規作成"
        },
        "show": {
          "description": "アプリケーションの詳細を取得",
          "summary": "アプリケーションの詳細を取得"
        }
      },
      "parameter": {
        "application_id": "アプリケーションID",
        "initial_code": "イニシャルコード",
        "name": "名称",
        "title_params": "パラメータ"
      }
    }
  },
  "target_list_histories": {
    "index": {
      "title": "配信先NSAリストの履歴"
    },
    "show": {
      "notification_histories": "通知の履歴",
      "title": "配信先NSAリストの詳細"
    }
  },
  "target_lists": {
    "action": {
      "replace": "配信先NSAリストの差し替え"
    },
    "edit": {
      "title": "配信先NSAリストの編集"
    },
    "new": {
      "title": "配信先NSAリストの指定"
    },
    "show": {
      "latest_job_status": "最後の通知の結果",
      "no_jobs": "まだ通知されていません",
      "notification_histories": "通知の履歴",
      "title": "配信先NSAリストの詳細"
    }
  },
  "tencent_censors": {
    "data": {
      "title": "Tencent確認待ちデータ一覧"
    },
    "news": {
      "title": "Tencent確認待ちニュース一覧"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M"
    },
    "pm": "午後"
  },
  "titles": {
    "edit": {
      "title": "%{title_name}の編集"
    },
    "hints": {
      "system_bcat_false": "システムタイトルの場合、特別指定がなければfalseを指定してください。",
      "system_bcat_true": "システムタイトルの場合、特別指定がなければtrueを指定してください。"
    },
    "index": {
      "manage_topic": "トピックの管理",
      "new_application": "アプリケーションの新規作成",
      "title": "アプリケーション一覧"
    },
    "manage_bcat_application": {
      "approved_and_latest_bcat_application": "承認済みで最新のBCAT利用申請",
      "fetch_and_apply_bcat_application": "BCAT 利用申請を取得して反映する",
      "item_name": "項目名",
      "item_value": "項目の値",
      "title": "BCAT 利用申請の管理",
      "unable_to_find_available_bcat_application": "有効な BCAT 利用申請が見つかりませんでした"
    },
    "manage_role": {
      "title": "ユーザリスト"
    },
    "my_role": {
      "current_role": "現在のロール",
      "role_request_desc": "ロールを変更したい場合は、ロールの種類を選択してリクエストしてください。リクエストがManagerに承認されると、変更が完了します。",
      "title": "マイロール"
    },
    "new": {
      "title": "アプリケーションの新規作成"
    },
    "show": {
      "manage_data": "配信データの管理",
      "manage_topic": "トピックの管理",
      "title": "%{title_name}の詳細"
    },
    "subscribes": {
      "index_title": {
        "all_description": "自動購読設定用にアプリケーションの登録ができます。",
        "title": "自動購読設定向けアプリケーション登録"
      }
    },
    "tooltips": {
      "data_size_limit": "4 MiBが管理領域に割り当てられます。",
      "promotions": {
        "title": "自動購読設定をするタイトルを選択します。"
      },
      "subscribes": {
        "title": "自動購読設定をするタイトルを選択します。"
      }
    }
  },
  "titles_controller": {
    "update_role": {
      "alert": "User is required."
    }
  },
  "top": {
    "index": {
      "disabled_user": "アカウントが無効化されています。",
      "recently_updated_items": "最近更新された項目",
      "title": "トップページ"
    }
  },
  "users": {
    "_role_navi": {
      "general_news_roles": "総合ニュースロール",
      "role_requests": "ロールリクエスト",
      "service_roles": "サービスロール",
      "title_roles": "アプリケーションロール"
    },
    "channel_roles": {
      "name": "名前",
      "request_role": "ロールのリクエスト",
      "requesting": "要求中",
      "role": "現在のロール",
      "roles": "ロール"
    },
    "edit": {
      "title": "ユーザの編集"
    },
    "general_news_roles": {
      "title": "ニュースロール"
    },
    "index": {
      "actions": "アクション",
      "detail": "詳細",
      "edit": "編集",
      "roles": "ロール",
      "title": "ユーザの一覧"
    },
    "my_roles": "マイロール",
    "new": {
      "title": "ユーザの新規作成"
    },
    "role_requests": {
      "actions": "アクション",
      "company_name": "会社名",
      "current_role": "現在のロール",
      "group_name": "グループ名",
      "judged_date": "判断日",
      "judged_user": "判断者",
      "requested_date": "要求日",
      "requested_role": "要求するロール",
      "role_requests": "ロールリクエスト",
      "status": "状態",
      "title": "ロールリクエスト",
      "user_name": "ユーザ名"
    },
    "roles": "ロール",
    "service_roles": {
      "actions": "アクション",
      "attach": "付与",
      "attach_status": "付与状況",
      "description": "説明",
      "remove": "削除",
      "roles": "ロール",
      "service_roles": "サービスロール",
      "title": "サービスロール"
    },
    "show": {
      "enqueue_sync_permissions": "OMASと権限同期をリクエスト",
      "failed_to_recieve_sync_permissions": "権限同期リクエストに失敗しました。しばらく時間を開けて試してください。",
      "recieve_sync_permissions": "権限同期リクエストを受け付けました。同期には時間がかかる場合があります。",
      "title": "マイプロフィール"
    },
    "title_roles": {
      "title": "アプリケーションロール"
    }
  },
  "users_controller": {
    "attach_service_role": {
      "attach_role": {
        "alert": "Can't attach role"
      },
      "user_nil": {
        "alert": "User not found."
      }
    },
    "remove_service_role": {
      "role_include": {
        "alert": "Role not found."
      },
      "user_nil": {
        "alert": "User not found."
      }
    }
  },
  "views": {
    "pagination": {
      "first": "&laquo;",
      "last": "&raquo;",
      "next": "&rsaquo;",
      "previous": "&lsaquo;",
      "truncate": "..."
    }
  },
  "virtual_files": {
    "action": {
      "add_down": "下に追加",
      "add_up": "上に追加"
    },
    "confirm": {
      "destroy_warning": "この配信データを本当に削除しますか？",
      "destroy_warning_if_published": "1度でも配信したことのある配信データは、分析等の目的で残しておくことを推奨しています。\nこの配信データを本当に削除しますか？\n"
    },
    "edit": {
      "title": "配信データ"
    },
    "form": {
      "add_datafile": "配信データを追加する",
      "include_expired": "配信終了済みのデータも含める"
    },
    "index": {
      "title": "配信データ"
    },
    "new": {
      "title": "新規配信データ"
    },
    "show": {
      "next": "次へ",
      "previous": "前へ",
      "title": "配信データの詳細"
    }
  }
});
I18n.translations["ko"] = I18n.extend((I18n.translations["ko"] || {}), {
  "nx": {
    "channel_discription": {
      "expiration_announcement": "본 채널은 %{year}/%{month}/%{day} (UTC)부로 종료됩니다.",
      "expiration_pre_announcement": "본 채널은 %{year}/%{month}/%{day} (UTC)부로 종료됩니다. %{year}/%{month}/%{day} (UTC)이후 본 채널의 뉴스는 볼 수 없게 되므로 유의하시기 바랍니다. "
    },
    "default_link_text": {
      "browser": "",
      "eshop": {
        "aoc_list": "",
        "consumption": "",
        "membership_service": "",
        "membership_top": "",
        "preticket_detail": "",
        "product_detail": "",
        "product_list": "",
        "sale": "",
        "subscriptions": "",
        "top": ""
      },
      "game": ""
    },
    "description_of_contents_excuse": "",
    "description_of_distribution_area": {
      "kor": "이 뉴스는 한국의 닌텐도 어카운트 사용자에게 배포하고 있습니다."
    },
    "description_of_nso_precaution": "※온라인 플레이를 이용하기 위해서는 Nintendo Switch Online(유료)에 가입해야 합니다.",
    "product": {
      "aoc": "추가 콘텐츠",
      "bundle": "세트 상품",
      "new": "NEW",
      "pre_order": {
        "above_v8": "예약",
        "below_v7": "예약"
      },
      "pre_purchase": "미리 다운로드",
      "title": "소프트웨어",
      "upgrade_pass": "UpgradePass"
    },
    "suggest_product_text": {
      "demo": ""
    }
  }
});
I18n.translations["nl"] = I18n.extend((I18n.translations["nl"] || {}), {
  "nx": {
    "channel_discription": {
      "expiration_announcement": "Dit kanaal is op %{day}-%{month}-%{year} (UTC) beëindigd. Je kunt op elk moment stoppen met het volgen van dit kanaal.",
      "expiration_pre_announcement": "Dit kanaal wordt op %{day}-%{month}-%{year} (UTC) beëindigd. Vanaf die datum zul je geen artikelen in dit kanaal meer kunnen bekijken. Je kunt op elk moment stoppen met het volgen van dit kanaal."
    },
    "default_link_text": {
      "browser": "",
      "eshop": {
        "aoc_list": "DLC bekijken",
        "consumption": "",
        "membership_service": "",
        "membership_top": "Nintendo eShop-pagina van Nintendo Switch Online bezoeken",
        "preticket_detail": "Ga naar de Nintendo eShop ",
        "product_detail": "Ga naar de Nintendo eShop ",
        "product_list": "",
        "sale": "Aanbiedingen bekijken ",
        "subscriptions": "",
        "top": "Ga naar de Nintendo eShop "
      },
      "game": "Software starten"
    },
    "description_of_contents_excuse": "",
    "description_of_distribution_area": {
      "au_nz": "Dit nieuws is voor Australische en Nieuw-Zeelandse Nintendo-accounts.",
      "eur": "Dit nieuws is voor Europese Nintendo-accounts.",
      "eur_au": "Dit nieuws is voor Europese en Australische Nintendo-accounts.",
      "eur_au_nz": "Dit nieuws is voor Europese, Australische en Nieuw-Zeelandse Nintendo-accounts.",
      "eur_nz": "Dit nieuws is voor Europese en Nieuw-Zeelandse Nintendo-accounts."
    },
    "description_of_nso_precaution": "Om online te spelen heb je een internetverbinding nodig. Om onlinediensten te gebruiken moet je een Nintendo-account aanmaken en akkoord gaan met de bijbehorende overeenkomst. Het Nintendo-account-privacybeleid is van toepassing. Sommige onlinediensten zijn mogelijk niet in alle landen beschikbaar. Om online te spelen heb je een betaald online lidmaatschap nodig: Nintendo Switch Online. Lees meer over betaalde online abonnementen (URL:https://www.nintendo.nl/Nintendo-Switch/Nintendo-Switch-Online/Nintendo-Switch-Online-1183143.html).",
    "product": {
      "aoc": "Downloadbare content",
      "bundle": "Pakket",
      "new": "Nieuw",
      "pre_order": {
        "above_v8": "Voorbestelling",
        "below_v7": "Pre-order"
      },
      "pre_purchase": "voorbestellen",
      "title": "Software",
      "upgrade_pass": "UpgradePass"
    },
    "suggest_product_text": {
      "demo": "DEMO"
    }
  }
});
I18n.translations["pt"] = I18n.extend((I18n.translations["pt"] || {}), {
  "nx": {
    "channel_discription": {
      "expiration_announcement": "Este canal foi encerrado a %{day}/%{month}/%{year} (UTC). Podes deixar de seguir este canal sempre que quiseres.",
      "expiration_pre_announcement": "Este canal será encerrado a %{day}/%{month}/%{year} (UTC). A partir desta data, deixará de ser possível ler os seus artigos. Podes deixar de seguir este canal sempre que quiseres."
    },
    "default_link_text": {
      "browser": "",
      "eshop": {
        "aoc_list": "Ver os DLC",
        "consumption": "",
        "membership_service": "",
        "membership_top": "Visitar a página do Nintendo Switch Online na Nintendo eShop",
        "preticket_detail": "Visita a Nintendo eShop ",
        "product_detail": "Visita a Nintendo eShop ",
        "product_list": "",
        "sale": "Ver as promoções atuais ",
        "subscriptions": "",
        "top": "Visita a Nintendo eShop "
      },
      "game": "Iniciar aplicação"
    },
    "description_of_contents_excuse": "",
    "description_of_distribution_area": {
      "eur": "Esta notícia é exclusiva para Contas Nintendo europeias.",
      "eur_au": "Esta notícia é exclusiva para Contas Nintendo na Europa e Austrália.",
      "eur_au_nz": "Esta notícia é exclusiva para Contas Nintendo na Europa, Austrália ou Nova Zelândia.",
      "eur_nz": "Esta notícia é exclusiva para Contas Nintendo na Europa e Nova Zelândia.",
      "publisher_usa": "%{publisher} oferece estas novidades aos nossos usuários com uma conta Nintendo nas Américas.",
      "usa": "Nintendo oferece estas novidades aos nossos usuários com uma conta Nintendo nas Américas."
    },
    "description_of_nso_precaution": "Requer acesso à Internet. Para utilizar os serviços online, é necessário criar uma Conta Nintendo e aceitar o respetivo contrato de utilização. Aplica-se a política de privacidade da Conta Nintendo. Alguns serviços online poderão não estar disponíveis em todos os países. Para jogar online é necessário subscrever o serviço online: Nintendo Switch Online. Lê aqui mais informações sobre a assinatura do serviço online pago (URL:https://www.nintendo.pt/Nintendo-Switch/Nintendo-Switch-Online/Nintendo-Switch-Online-1183143.html).",
    "product": {
      "aoc": "Conteúdo descarregável",
      "bundle": "Conjunto",
      "new": "Novo",
      "pre_order": {
        "above_v8": "Pré-encomenda",
        "below_v7": "Pré-encomenda"
      },
      "pre_purchase": "pré-encomenda",
      "title": "Aplicações",
      "upgrade_pass": "UpgradePass"
    },
    "suggest_product_text": {
      "demo": "DEMO"
    }
  }
});
I18n.translations["pt-BR"] = I18n.extend((I18n.translations["pt-BR"] || {}), {
  "nx": {
    "channel_discription": {
      "expiration_announcement": "Este canal foi encerrado em %{day}/%{month}/%{year} (UTC). Para a lista completa de canais, acesse \"Novidades\" no menu HOME.",
      "expiration_pre_announcement": "Este canal será encerrado em %{day}/%{month}/%{year} (UTC) e não será mais possível visualizar artigos a partir dessa data. Obrigado por seguir o canal."
    },
    "default_link_text": {
      "browser": "",
      "eshop": {
        "aoc_list": "Ver conteúdo extra",
        "consumption": "",
        "membership_service": "",
        "membership_top": "Visite a página do Nintendo Switch Online no Nintendo eShop",
        "preticket_detail": "Visite o Nintendo eShop",
        "product_detail": "Visite o Nintendo eShop",
        "product_list": "",
        "sale": "Ver ofertas",
        "subscriptions": "",
        "top": "Visite o Nintendo eShop"
      },
      "game": "Iniciar software"
    },
    "description_of_contents_excuse": "",
    "description_of_distribution_area": {
      "eur": "Estas novidades são distribuídas pela Nintendo para usuários com uma conta Nintendo na Europa.",
      "eur_au": "Estas novidades são distribuídas pela Nintendo para usuários com uma conta Nintendo na Austrália.",
      "eur_au_nz": "Estas novidades são distribuídas pela Nintendo para usuários com uma conta Nintendo na Europa, Austrália e Nova Zelândia.",
      "eur_nz": "Estas novidades são distribuídas pela Nintendo para usuários com uma conta Nintendo na Nova Zelândia.",
      "publisher_usa": "Estas novidades são distribuídas por %{publisher} para usuários com uma conta Nintendo nas Américas.",
      "usa": "Estas novidades são distribuídas pela Nintendo para usuários com uma conta Nintendo nas Américas."
    },
    "description_of_nso_precaution": "Uma assinatura do Nintendo Switch Online (vendida separadamente) e uma conta Nintendo são necessárias para funcionalidades online. Não disponível em todos os países. Acesso à internet é necessário para funcionalidades online. Termos se aplicam. https://www.nintendo.com/switch-online.",
    "description_of_rating_excuse": "Verifique a classificação indicativa.",
    "product": {
      "aoc": "Conteúdo extra",
      "bundle": "Pacote",
      "new": "Novo",
      "pre_order": {
        "above_v8": "Reserva",
        "below_v7": "Reserva"
      },
      "pre_purchase": "pré-encomenda",
      "title": "Softwares",
      "upgrade_pass": "UpgradePass"
    },
    "suggest_product_text": {
      "demo": "Demo disponível"
    }
  }
});
I18n.translations["ru"] = I18n.extend((I18n.translations["ru"] || {}), {
  "nx": {
    "channel_discription": {
      "expiration_announcement": "Канал был закрыт %{day}.%{month}.%{year} (UTC). Вы в любое время можете вручную отказаться от подписки на этот канал.",
      "expiration_pre_announcement": "Канал будет закрыт %{day}.%{month}.%{year} (UTC). После этой даты вы не сможете читать статьи на этом канале. Вы в любое время можете вручную отказаться от подписки на этот канал."
    },
    "default_link_text": {
      "browser": "",
      "eshop": {
        "aoc_list": "Посмотреть DLC",
        "consumption": "",
        "membership_service": "",
        "membership_top": "На страницу Nintendo Switch Online в Nintendo eShop",
        "preticket_detail": "Посетить Nintendo eShop ",
        "product_detail": "Посетить Nintendo eShop ",
        "product_list": "",
        "sale": "Посмотреть текущие предложения ",
        "subscriptions": "",
        "top": "Посетить Nintendo eShop "
      },
      "game": "Запустить приложение"
    },
    "description_of_contents_excuse": "",
    "description_of_distribution_area": {
      "au_nz": "Новость для учетных записей Nintendo из Австралии и Новой Зеландии.",
      "eur": "Новость для европейских учетных записей Nintendo.",
      "eur_au": "Новость для учетных записей Nintendo из Европы и Австралии.",
      "eur_au_nz": "Новость для учетных записей Nintendo из Европы, Австралии и Новой Зеландии.",
      "eur_nz": "Новость для учетных записей Nintendo из Европы и Новой Зеландии."
    },
    "description_of_nso_precaution": "Для игры онлайн требуется подключение к Интернету. Для пользования онлайн-сервисами необходимо создать учетную запись Nintendo и принять соответствующее соглашение. Действует политика конфиденциальности учетной записи Nintendo. Некоторые онлайн-сервисы могут быть доступны не во всех странах. Для игры онлайн требуется платная подписка: Nintendo Switch Online. Подробнее о платной подписке на онлайн-сервисы (URL:https://www.nintendo.ru/Nintendo-Switch/Nintendo-Switch-Online/Nintendo-Switch-Online-1183143.html).",
    "product": {
      "aoc": "Загружаемый контент",
      "bundle": "Набор",
      "new": "Новое",
      "pre_order": {
        "above_v8": "Предзаказ",
        "below_v7": "Предзаказ"
      },
      "pre_purchase": "предзаказ",
      "title": "Программы",
      "upgrade_pass": "UpgradePass"
    },
    "suggest_product_text": {
      "demo": "Демо-версия"
    }
  }
});
I18n.translations["zh-Hans"] = I18n.extend((I18n.translations["zh-Hans"] || {}), {
  "nx": {
    "channel_discription": {
      "expiration_announcement": "本頻道已於%{year}/%{month}/%{day} (UTC)結束服務。",
      "expiration_pre_announcement": "本頻道將於%{year}/%{month}/%{day} (UTC)結束服務。%{year}/%{month}/%{day}  (UTC)以後將無法看到本頻道的新聞，敬請留意。"
    },
    "default_link_text": {
      "browser": "",
      "eshop": {
        "aoc_list": "浏览该游戏的所有新增内容",
        "consumption": "",
        "membership_service": "",
        "membership_top": "",
        "preticket_detail": "访问Nintendo e商店的商店页面",
        "product_detail": "访问Nintendo e商店的商店页面",
        "product_list": "",
        "sale": "了解更多折扣信息",
        "subscriptions": "",
        "top": "访问Nintendo e商店"
      },
      "game": "启动游戏"
    },
    "description_of_contents_excuse": "",
    "description_of_distribution_area": {
      "chn": ""
    },
    "description_of_nso_precaution": "",
    "product": {
      "aoc": "新增内容",
      "bundle": "套装商品",
      "new": "新",
      "pre_order": {
        "above_v8": "预购",
        "below_v7": "预购"
      },
      "pre_purchase": "预下载",
      "title": "软件",
      "upgrade_pass": "UpgradePass"
    },
    "suggest_product_text": {
      "demo": ""
    }
  }
});
I18n.translations["zh-Hant"] = I18n.extend((I18n.translations["zh-Hant"] || {}), {
  "nx": {
    "channel_discription": {
      "expiration_announcement": "本频道将在%{year}/%{month}/%{day} (UTC)终止运营。",
      "expiration_pre_announcement": "本频道将在%{year}/%{month}/%{day}(UTC)终止运营。\n %{year}/%{month}/%{day} (UTC)以后，您将无法浏览本频道的新闻。感谢您一直以来的关注与支持。"
    },
    "default_link_text": {
      "browser": "",
      "eshop": {
        "aoc_list": "",
        "consumption": "",
        "membership_service": "",
        "membership_top": "",
        "preticket_detail": "",
        "product_detail": "",
        "product_list": "",
        "sale": "",
        "subscriptions": "",
        "top": ""
      },
      "game": ""
    },
    "description_of_contents_excuse": "",
    "description_of_distribution_area": {
      "twn": "本資訊是向香港Nintendo Account的用戶發布。"
    },
    "description_of_nso_precaution": "※使用線上遊玩時，需要加入Nintendo Switch Online (付費)。",
    "description_of_rating_excuse": "WARNING:THIS ARTICLE CONTAINS MATERIAL WHICH MAY OFFEND AND MAY NOT BE DISTRIBUTED, CIRCULATED, SOLD, HIRED, GIVEN, LENT, SHOWN, PLAYED OR PROJECTED TO A PERSON UNDER THE AGE OF 18 YEARS.\n警告：本物品內容可能令人反感；不可將本物品派發、傳閱、出售、出租、交給或出借予年齡未滿18歲的人士或將本物品向該等人士出示、播放或放映。",
    "product": {
      "aoc": "新增內容",
      "bundle": "組合商品",
      "new": "NEW",
      "pre_order": {
        "above_v8": "預購",
        "below_v7": "預購"
      },
      "pre_purchase": "預先下載",
      "title": "軟體",
      "upgrade_pass": "UpgradePass"
    },
    "suggest_product_text": {
      "demo": ""
    }
  }
});
if (typeof document !== 'undefined') {
  I18n.locale = document.querySelector('html').lang
}
export default I18n
