import { List, OrderedMap, Record } from 'immutable';

import I18n from '../lib/i18n';
import Constants, { IShopCountry } from './Constants';
import NewsDescription, { INewsDescription } from './NewsDescription';
import NewsSetting, { INewsSetting } from './NewsSetting';
import TopicCountry, { ITopicCountry } from './TopicCountry';

export interface ISelectOptions {
  newsPassphraseTypes: Array<[string, string]>;
  newsFormLevels: Array<[string, number]>;
  semanticsVersions: Array<[string, number]>;
  serviceStatuses: Array<[string, string]>;
}
export interface IDescriptionLength {
  description: number;
  name: number;
  publisher_name: number;
}
const defaultValue: {
  setting: NewsSetting,
  descriptions: OrderedMap<string, NewsDescription>,
  topicCountries: OrderedMap<string, TopicCountry>,
  editableLanguages: List<string>,
  descriptionLength: IDescriptionLength;
  selectOptions: ISelectOptions,
  constants: Constants,
  i18nPlatforms: { [key: string]: string },
} = {
  setting: new NewsSetting(),
  descriptions: OrderedMap(),
  topicCountries: OrderedMap(),
  editableLanguages: List(),
  descriptionLength: {
    description: 0,
    name: 0,
    publisher_name: 0,
  },
  selectOptions: {
    newsPassphraseTypes: [],
    newsFormLevels: [],
    semanticsVersions: [],
    serviceStatuses: [],
  },
  constants: new Constants(),
  i18nPlatforms: {},
};

export default class NewsSettingFormState extends Record(defaultValue) {
  constructor(props?: any) {
    if (props === undefined) {
      super();
      return this;
    }
    const setting = new NewsSetting(props.news_setting);
    const constants = new Constants(props.constants);
    const descriptions = OrderedMap<string, NewsDescription>(props.news_descriptions.map((description: INewsDescription) =>
      [description.language, new NewsDescription(description).set('persisted', true)]
    ));
    const topicCountries = OrderedMap<string, TopicCountry>(props.topic_countries.map((topicCountry: ITopicCountry) =>
      [topicCountry.country, new TopicCountry(topicCountry)]
    ));
    const { descriptionLength, selectOptions, i18nPlatforms } = props;
    super({
      setting,
      descriptions,
      topicCountries,
      descriptionLength,
      selectOptions,
      constants,
      i18nPlatforms,
    });
    if (!topicCountries.isEmpty()) { return this.updateEditableLanguages(); }
    return this;
  }
  // mutations
  public updateSetting({ key, value }: { key: keyof INewsSetting, value: any }): NewsSettingFormState {
    return this.update('setting', setting => setting.updateSetting({ key, value }));
  }
  public updatePlatforms({ key, value }: { key: keyof INewsSetting, value: string }): NewsSettingFormState {
    return this.update('setting', setting => setting.updatePlatforms({ key, value }));
  }
  public updateDescription({ language, key, value }: { language: string, key: keyof INewsDescription, value: any }): NewsSettingFormState {
    return this.updateIn(['descriptions', language], (description: NewsDescription) => description.updateDescription({ key, value }));
  }
  public addCountry(country: string): NewsSettingFormState {
    const newState = this.getIn(['topicCountries', country]) ?
        this.setIn(
          ['topicCountries', country, 'destroy'],
          false,
        ) :
        this.setIn(
          ['topicCountries', country],
          new TopicCountry({country}),
        );

    return newState.updateEditableLanguages().addBlankDescription();
  }
  public removeCountry(country: string): NewsSettingFormState {
    const newState = this.setIn(['topicCountries', country, 'destroy'], true);
    return newState.updateEditableLanguages();
  }
  public updateEditableLanguages(): NewsSettingFormState {
    const languages = this.getIn(['constants', 'shopCountries']).filter((object: IShopCountry) =>
      this.get('topicCountries').filter(e => !e.destroy).keySeq().toArray().includes(object.iso2)
    ).reduce(
      (memo: string[], cur: IShopCountry) => memo.concat(cur.languages),
      []
    );
    return this.set('editableLanguages', List(languages));
  }
  public addBlankDescription(): NewsSettingFormState {
    const nextLanguages = this.editableLanguages.toSet();
    const prevLanguages = this.descriptions.map(desc => desc.language).toSet();
    // 足りない言語だけを新規作成し、既存のものは勝手に変更しない
    const addedLanguages = nextLanguages.subtract(prevLanguages);
    const newDescriptions = addedLanguages.map(
      (language): [string, NewsDescription] => [language, new NewsDescription({ language, length: this.descriptionLength })]
    );
    return this.update('descriptions', descriptions => descriptions.merge(newDescriptions));
  }

  public isValid(): boolean {
    return this.setting.isValid() &&
      this.descriptions.reduce((memo, desc) => memo && desc.isValid(), true) &&
      this.topicCountries.reduce((memo, topicCountry) => memo && topicCountry.isValid(), true);
  }
  public submitButtonText(): string {
    return this.setting.news_topic_id > 0 ? I18n.t('common.action.update') : I18n.t('common.action.create');
  }
}
