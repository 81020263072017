import React from 'react';
import ReactOnRails from 'react-on-rails';
import { Provider } from 'react-redux';

import SubscribeForm from '../components/SubscribeForm';
import createStore from '../store/Subscribe';

const SubscribesApp = (props) => {
  const store = createStore(props);
  return (
    <Provider {...{ store }}>
      <SubscribeForm {...props} />
    </Provider>
  );
};

// This is how react_on_rails can see the SubscribesApp in the browser.
ReactOnRails.register({ SubscribesApp });
